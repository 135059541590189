import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';











function PostItemSmall(props) {

  let date = props.data;
  let day, month, year;
  if (date) {
    day = date.slice(0, 2);
    month = date.slice(3, 5);
    year = date.slice(6, Infinity);
  }
  const event = new Date(Date.UTC(year, month - 1, day));
  // const options = { year: "numeric", month: "short", day: "numeric" };
  // let dateformet = event.toLocaleDateString("en-US", options);
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const normalDate = new Date(date).toLocaleDateString(undefined, options)
  let title = props.title;
  let postTitle = title.slice(0, 150);

  let description = props.description;
  let des;
  if (description === null) {
    des = " ";
  } else {
    des = description.slice(0, 250);
  }
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const [role, setRole] = useState("");

  useEffect(() => {
    if (profile.length !== 0) {
      setRole(profile[0].role_value);
    }
  }, [profile]);

  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };








  return (
    <div className="row mt-2">
      <div className="col-xxl-3 col-lg-4 col-md-5 col-sm-6 col-12">
        {
          props.type === 2 || props.type === "2" ? (
            <a
              href={props.link}
              target="_blank"
              data-bs-toggle="tooltip"
              data-bs-placement="down"
              title={title}
              rel="noreferrer"
            >
              <div className="trending-boxs">
                {props.featured === 1 && (
                  <span className="featured_txt">Featured</span>
                )}
                <img src={props.medium} alt="" className="img-fluid"></img>
                <div className="play_">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    className="bi bi-play-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                  </svg>
                </div>
              </div>
            </a>
          ) : (
            <Link
              to={`/details/${props.id}/${props.sessionId}`}
              state={{ id: props.id, sessionId: props.sessionId }}
              data-bs-toggle="tooltip"
              data-bs-placement="down"
              title={title}
            >
           
              <div className="trending-boxs">
                {props.featured === 1 && (
                  <span className="featured_txt">Featured</span>
                )}
                <img src={props.medium} alt="" className="img-fluid"></img>
                <div className="play_">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    className="bi bi-play-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                  </svg>
                </div>
                {
          props.duration ?
            <div className="time_trend">
              <p className="s">{props.duration}</p>
            </div> : (null)
        }

              </div>
            </Link>
          )
        }

        {/* {
          props.duration ?
            <div className="time_trend">
              <p className="s">{props.duration}</p>
            </div> : (null)
        } */}

      </div>
      <div className="col-xxl-9 col-lg-8 col-md-7 col-sm-6 col-12">
        <div className="vid_title upcoming-head">
          {props?.category.map((row) => (
            <Link
              to={`/search?cat_id=${btoa(row?.id)}`}
              state={{ from: row }}
            >
              <span className="vid_category cursor me-2">#{row?.name} </span>
            </Link>
          ))}

     
       
            <h4 className="m-auto p-0">
              {postTitle} {title.length > 150 && <span> ...</span>}{" "}
            </h4>
       
        </div>
        <div className="vid_detail">
          <div className="row">
            <div className="col-xl-10 col-md-10 col-12">
              <div className="vid_para">
                <span className="text_black">
                  {" "}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-eye me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg> */}
                  {props.views} {getTranslation("views")} |{" "}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={11}
                    height={11}
                    fill="currentColor"
                    className="bi bi-calendar-check me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                  </svg> */}
                  {normalDate}
                </span>
                <br></br>
                <span>
                  {props.ap === 1 && (
                    <span
                      className="vid_category cursor me-2 badge fw-normal "
                      style={{
                        backgroundColor: "#eb7c3c",
                        color: " #f3f5f7",
                        fontSize: " 9px",
                        borderRadius: "2px",
                      }}
                    >
                      {getTranslation('akunahPublic')}
                    </span>
                  )}
                  {props.ae === 1 && (
                    <>
                      {role !== 0 && (
                        <span
                          className="vid_category cursor me-2 badge fw-normal "
                          style={{
                            backgroundColor: "#eb7c3c",
                            color: " #f3f5f7",
                            fontSize: " 9px",
                            borderRadius: "2px",
                          }}
                        >
                          {/* {row.name} */}
                          {getTranslation('akunahExc')}
                        </span>
                      )}
                    </>
                  )}
                  {props.op === 1 && (
                    <span
                      className="vid_category cursor me-2 badge fw-normal "
                      style={{
                        backgroundColor: "#eb7c3c",
                        color: " #f3f5f7",
                        fontSize: " 9px",
                        borderRadius: "2px",
                      }}
                    >
                      Organization Public
                    </span>
                  )}
                  {props.oe === 1 && (
                    <>
                      {role !== 0 && (
                        <span
                          className="vid_category cursor me-2 badge fw-normal "
                          style={{
                            backgroundColor: "#eb7c3c",
                            color: " #f3f5f7",
                            fontSize: " 9px",
                            borderRadius: "2px",
                          }}
                        >
                          {getTranslation('OrgExc')}
                        </span>
                      )}
                    </>
                  )}
                  {props.hp === 1 && (
                    <span
                      className="vid_category cursor me-2 badge fw-normal "
                      style={{
                        backgroundColor: "#eb7c3c",
                        color: " #f3f5f7",
                        fontSize: " 9px",
                        borderRadius: "2px",
                      }}
                    >
                      HCP Public
                    </span>
                  )}
                  {props.he === 1 && (
                    <span
                      className="vid_category cursor me-2 badge fw-normal "
                      style={{
                        backgroundColor: "#eb7c3c",
                        color: " #f3f5f7",
                        fontSize: " 9px",
                        borderRadius: "2px",
                      }}
                    >
                      HCP Exclusive
                    </span>
                  )}
                </span>
                <div className="trending-heading d-flex">
                  <div className="home-trending-img me-2 ms-3">
                    {props.profile !== "" && ( //This section will be - props.posted_by.img !=""
                      <img
                        src={props.profile}
                        alt=""
                        className="img-fluid"
                      ></img>
                    )}
                    {props.profile === "" && ( //This section will be - props.posted_by.img ==""
                      <Avatar
                        className="me-2"
                        name={props.posted_by.slice(0, 1)}
                        size="30"
                        maxInitials="1"
                        round={true}
                        fgColor="#EA702B"
                        color="#F3CDB8"
                      />
                    )}
                  </div>
                  <div className="mt-auto" style={{ marginLeft: "-3px" }}>
                    <p className="text-uppercase">{props.posted_by}</p>
                  </div>
                </div>

                <p className="mt-2 desc">
                  {des}
                  {des.length > 250 && <span className="text_black"> ...</span>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PostItemSmall;
