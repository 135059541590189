import React from 'react';
import Header from '../../Component/Header'
import Sidebar from '../../Component/Sidebar'
import { useSelector } from 'react-redux';
import Tab from '../../Component/admin/Tab';
import Footer from '../../Component/Footer';
import appStyle from '../../config/appStyle';
import NotificationList from '../../Component/admin/NotificationList';




export default function Notification() {
  const { show } = useSelector((state) => state.mainReducer.navData);
  document.title = 'Akunah Med || Notification';
  return (
    <>
      <Header />
      <main className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2} >

              <Sidebar></Sidebar>

            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>

              {/* <Tab /> */}
                 
                 <NotificationList/>

              

              <Footer />

            </div>
          </div>
        </div>
      </main>
    </>
  )
}
