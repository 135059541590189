import React, { useEffect } from "react";
import UserService from "./services/UserService";
import { useKeycloak } from "@react-keycloak/web";
// import Routess from "./Component/AppRouter";
import AppRouter from "./Component/Routess";
import './Component/css/main.css'
import './Component/css/responsive.css'


const App = () => {
  const { keycloak, initialized } = useKeycloak();

  const check = function loginCheck() {
    return UserService.isLoggedIn();
  };
console.log(initialized)
// console.log(keycloak)
  const abc = check();
  //   useEffect(()=>{
  //     check()
  //   },[])

  console.log("==login working in app js--", UserService.isLoggedIn());

  if (!initialized) {
    return (
      <h2 style={{ textAlign: "center", marginTop: "100px" }}>Loading...</h2>
    );
  }
  return <>{!!keycloak.authenticated && <AppRouter />}</>;
};

export default App;
