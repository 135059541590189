import React from "react";
// import { setHomeTrendingArr } from "../actions/homeTrendingAction";
import { useState, useEffect } from "react";
import SmallSkeleton from "../smallSkeleton";
import { Link } from "react-router-dom";
// import useTranslation from "../customHooks/translations";
import Postitem from "./Postitem";
import { useDispatch, useSelector } from "react-redux";
import en from "../../localization/en";
import fr from "../../localization/fr";
import ger from "../../localization/ger";
import ita from "../../localization/ita";
import ara from "../../localization/ara";
import spa from '../../localization/spa'
import tha from '../../localization/tha';
import kor from '../../localization/kor';
import chn from '../../localization/chn';

export default function OrgPublic(props) {
  // console.log("dtata aarha hai", props);

  const [check, setcheck] = useState('');
  const [show, setShow] = useState(false);
  let { arr } = useSelector((state) => state.mainReducer.catagoryData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, [])
  useEffect(() => {
    if (props.arr) {
      // setShow(false);
      setcheck(props.arr.length)
    }
  }, [props]);


  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };

  



  return (
    <>
      <div className="trending">
        <div className="upcoming-head filter">
          <h4> {props.title} </h4>
          <p className="filter" >
          <Link to={`/channel/${props.type}/${props.title.replace(/\s/g, '-').toLowerCase()}`}
          > {getTranslation("view_all")}</Link>
          </p>
        </div>
        {/* <hr/> */}
       
        <div className="trendig-box">
          <div className="row">
            {!show && <SmallSkeleton></SmallSkeleton>}
            {show &&
              props.arr.map((post) => (
                <div
                  className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12"
                  key={post.id}
                >
                  <Postitem
                    id={post.id}
                    title={post.title}
                    posted_by={post.posted_by}
                    videoKey={post.video_key}
                    duration={post.duration}
                    large={post.large}
                    medium={post.medium}
                    views={post.views}
                    description={post.description}
                    data={post.date}
                    category={post.category_id}
                    featured={post.featured}
                    sessionId={post.sessionId}
                    profile={post.profile}
                    type={post.type}
                    link={post.link}
                  />
                </div>
              ))}
            {check === 0 && (
              <div className="no_datas ">
                <h6 className="">{getTranslation('no_videos_found')}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
