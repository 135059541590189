import React, { useState, useEffect, Suspense, useMemo } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import { useSelector } from "react-redux/es/exports";
import { setArr } from "../actions/profileAction";

import "react-loading-skeleton/dist/skeleton.css";
import Search from "./Search";
import Notification from "./Notification";
import LanguageHandler from "./LanguageHandler";
// import Skeleton from "react-loading-skeleton";
import useTranslation from "./customHooks/translations";
import useAxios from "../axiosinstance";
// import HttpService from "../services/HttpService";
// import { useCookies } from 'react-cookie';
import Cookies from "js-cookie";
import logo from "../Logo/akunahlogo.svg";
import vector from "../Logo/Vector.svg";
import vector2 from "../Logo/Vector2.svg";

import HandBook from "./HandBook";
import HelpDesk from "./HelpDesk";
import NewUserPopup from "./NewUserPopup";
import UploadVideoPopup from "./UploadVideoPopup";
import UploadVideo from "./UploadVideo";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";

import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';

import { setChannelList, setChannelLists } from '../actions/channelAction'

export default React.memo(function Header() {
  const dispatch = useDispatch()
  let akToken = localStorage.getItem("akTokenMed");

  const { keycloak } = useKeycloak();
  const logout_url = window.location.origin;
  // console.log("Header page")
  const translation = useTranslation();
  const axiosinstance = useAxios();
  // const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  // let kk =sessionStorage.getItem('authentication');
  // console.log(kk);
  // setCookie('cookie-name', 'nikhil-kant', { path: '/' });
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  const handleClick = () => {
    navigate(-1, { replace: false });
  };

  const handleClick1 = () => {
    navigate(1, { replace: false });
  };

  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [role, setRole] = useState(0);

  function handlesearch() {
    setShow(true);
  }
  function handlesearch1() {
    setShow(false);
  }

  let display = "";
  let sm;
  if (show) {
    display = {
      display: "none",
    };
    sm = {
      display: "block",
    };
  } else {
    display = {
      display: "",
    };
    sm = {
      display: "none",
    };
  }

  const [img, setimg] = useState("");
  const [email, setemail] = useState("");
  // const [icon, seticon] = useState('nikhil');
  // const GetResult = () => {
  //     if (profile) {
  //       setimg(profile[0].profile_pic);
  //       setemail(profile[0].email);
  //     }
  // };
  // useEffect(() => {
  //   GetResult();
  // }, []);

  const handelchsnge = () => {
    localStorage.removeItem("akTokenMed");
    sessionStorage.removeItem("akunahhandbook")
    keycloak.logout({ redirectUri: logout_url });
  };

  const hcpRequest = () => {
    axiosinstance.current
      .get("first_time_user_signin")
      .then((res) => {
        setIsOpen(res.data.status);
      })
      .catch((error) => {

      });
  };

  useEffect(() => {
    hcpRequest();
    if (profile.length != 0) {
      setRole(profile[0].role_value);
      // setOrgID()
    }
  }, []);

  // console.log("isOpensssss", isOpen)

  useEffect(() => {
    if (profile.length != 0) {
      setimg(profile[0].profile_pic);
      setemail(profile[0].email);
    }
  }, [profile]);

  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };

  const handleProfileChange = () => {
    localStorage.removeItem("akTokenMed")
    dispatch(setArr([]))

  }






  const getUserChannelListData = () => {
    if (role === 100 || role === 70) {
      axiosinstance.current
        .get(`get_channel_list?ak_id=${akToken}&keyword=${""}`)
        .then((response) => {
          dispatch(setChannelList(response.data.data));
          dispatch(setChannelLists(response.data.data2))

        });
    }
  };

  const getParticularChannelList = () => {
    if (role === 0 || role === 40) {
      axiosinstance.current
        .get(`get_list_of_channel_accr_to_user?ak_id=${akToken}`)
        .then((response) => {
          dispatch(setChannelList(response.data.data));
          dispatch(setChannelLists(response.data.data2))

        });
    }
  };









  useEffect(() => {
    if (profile.length != 0) {
      setRole(profile[0]?.role_value);

    }


  }, [profile]);


  useEffect(() => {
    if (role === 100 || role === 70) {
      getUserChannelListData()
    } else {
      getParticularChannelList()
    }
  }, [profile]);










  return (
    <>
      <div className="sticky-top ">
        <nav className=" navbar-light bg-light " style={display}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-2 m-auto d-none d-lg-block d-xl-block d-md-block">
                <Link to={{ pathname: "/channels" }} className="navbar-brand">
                  <img
                    src={logo}
                    className="img-fluid"
                    style={{ padding: "19px" }}
                    alt="Akunah"
                  />
                </Link>
              </div>
              <div className="col-lg-2 col-md-2 col-2 m-auto d-md-none d-lg-none d-xxl-none  d-block">
                <Link to={{ pathname: "/channels" }} className="navbar-brand">
                  <img
                    src="/Assets/image/_Akunah_Med.png"
                    className="img-fluid"
                    alt="Akunah"
                  />
                </Link>
              </div>

              <div className="col-lg-1 col-md-1 col-1 m-auto">
                <div>
                  <i className="cursor_ " onClick={handleClick}>
                    {" "}
                    <img src={vector} alt="" />
                  </i>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-6 m-auto d-none d-md-block d-lg-block d-sm-block d-xxl-block ">
                <Search />
              </div>
              <div className="col-lg-6 col-md-6 col-6 m-auto d-block d-md-none d-lg-none d-sm-none d-xl-none d-xxl-none">
                <i
                  className=""
                  style={{ float: "right" }}
                  onClick={handlesearch}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                  >
                    {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                    <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
                  </svg>
                </i>
              </div>

              <div className="col-lg-3 col-md-3 col-3 d-flex justify-content-end pe-20">
                <LanguageHandler></LanguageHandler>
                {/* {profile[0]?.is_org_member == true ? (
                  <>
                    {" "}
                    <UploadVideo />
                  </>
                ) : null}
                {role === 100 || role === 70 ? (
                  <>
                    <UploadVideo />
                  </>
                ) : null} */}
                <HelpDesk type="1"></HelpDesk>
                <HandBook type="1"></HandBook>
                <Notification></Notification>
                {/* {isOpen === false ? (
                  <>
                    <NewUserPopup isOpen={true}></NewUserPopup>
                  </>
                ) : (
                  <>
                    {" "}
                    <NewUserPopup isOpen={false}></NewUserPopup>
                  </>
                )} */}

                <div>
                  <Suspense
                    fallback={
                      <div className="text-center">{translation.loading}</div>
                    }
                  >
                    <ul className="navbar-nav  mb-2 mb-lg-0 ms-2">
                      <li className="nav-item dropdown profile-image-size">
                        <Link
                          className="nav-link d-flex" //dropdown-toggle
                          to=""
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="icon_name">
                            {img.length <= 0 ? (
                              email.slice(0, 1)
                            ) : (
                              // <p className="m-auto">{profile[0].first_name[0]}</p>
                              <img
                                className=" img-fluid"
                                style={{
                                  width: "40px",
                                  borderRadius: "50%",
                                  height: "40px",
                                }}
                                src={img}
                                alt="icon"
                              />

                            )}
                          </span>
                          <span
                            className="d-none d-md-block d-lg-block d-xl-block"
                            style={{ margin: "auto" }}
                          >
                            {/* {profile.length <= 0 ? (
                            " "
                          ) : (
                            <p className="m-auto">{profile[0].first_name}</p>
                          )} */}
                          </span>
                        </Link>
                        <ul
                          className="dropdown-menu dropdown-menu-lg-end profile-nav shadow-sm rounded"
                          aria-labelledby="navbarDropdown"
                        >
                          <div className="profile-nav rounded">
                            <li className="profile-li">
                              <Link to={{ pathname: "/profile" }}>
                                <div className="profi">
                                  <i className="">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 576 512"
                                    >
                                      {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.co20m/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                      <path d="M160 256C160 185.3 217.3 128 288 128C358.7 128 416 185.3 416 256C416 326.7 358.7 384 288 384C217.3 384 160 326.7 160 256zM288 336C332.2 336 368 300.2 368 256C368 211.8 332.2 176 288 176C287.3 176 286.7 176 285.1 176C287.3 181.1 288 186.5 288 192C288 227.3 259.3 256 224 256C218.5 256 213.1 255.3 208 253.1C208 254.7 208 255.3 208 255.1C208 300.2 243.8 336 288 336L288 336zM95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6V112.6zM288 80C222.8 80 169.2 109.6 128.1 147.7C89.6 183.5 63.02 225.1 49.44 256C63.02 286 89.6 328.5 128.1 364.3C169.2 402.4 222.8 432 288 432C353.2 432 406.8 402.4 447.9 364.3C486.4 328.5 512.1 286 526.6 256C512.1 225.1 486.4 183.5 447.9 147.7C406.8 109.6 353.2 80 288 80V80z" />
                                    </svg>
                                  </i>{" "}
                                  {getTranslation('view_profile')}
                                </div>
                              </Link>
                            </li>

                            {profile[0]?.is_org_member == true ? (
                              <>
                                <li className="profile-li">
                                  <Link to={{ pathname: "/upload-post" }}>
                                    <div className="profi">
                                      <img
                                        className="me-2"
                                        src="/Assets/image/upload-video2.svg"
                                        alt=""
                                      />
                                      {translation.Upload_a_Video}
                                    </div>
                                  </Link>
                                </li>
                              </>
                            ) : null}

                            {/* {role === 100 || role === 70 ? (
                              <>
                                <li className="profile-li">
                                  <Link to={{ pathname: "/upload-post?event=1" }}>
                                    <div className="profi">
                                      <img
                                        className="me-2"
                                        src="/Assets/image/upload-video2.svg"
                                        alt=""
                                      />
                                      {getTranslation("Upload_a_Video")}
                                    </div>
                                  </Link>
                                </li>
                              </>
                            ) : null} */}


                            <li className="profile-li">
                              <Link to="/user-profile">
                                <div
                                  className="profi"
                                  onClick={() => handleProfileChange()}
                                >
                                  <img
                                    className="me-2"
                                    src="/Assets/image/transfer.png"
                                    alt=""
                                  />
                                  {getTranslation('switch_profile')}
                                </div>
                              </Link>
                            </li>



                            <li className="profile-li1">
                              <div
                                className="profi"
                              >
                                <HandBook type="2"></HandBook>
                              </div>
                            </li>


                            <li className="profile-li1">
                              <div
                                className="profi"
                              >
                                <HelpDesk type="2"></HelpDesk>
                              </div>
                            </li>





















                            <li className="profile-li">
                              <Link to="">
                                <div
                                  className="profi"
                                  onClick={() => handelchsnge()}
                                >
                                  <i className="">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      width="20"
                                      height="20"
                                    >
                                      {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                      <path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
                                    </svg>
                                  </i>
                                  {getTranslation('logout')}
                                </div>
                              </Link>
                            </li>
                          </div>
                        </ul>
                      </li>
                    </ul>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <nav className="navbar-light bg-light" style={sm}>
          <div className="container-fluid">
            <div className="row m-2">
              <div className=" col-1 m-auto">
                <div>
                  <i className="cursor_ " onClick={handlesearch1}>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-compact-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
                      />
                    </svg>
                  </i>
                </div>
              </div>
              <div className="col-11 ">
                <Search></Search>
              </div>
              {/* <div className="overlay"></div> */}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
});
