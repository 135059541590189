import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import useAxios from "../../src/axiosinstance";
import { useSelector, useDispatch } from "react-redux/es/exports";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


import { setChannelList, setChannelLists } from '../actions/channelAction'


const ChannnelData = () => {
	let result = []
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [ref, setRef] = useState();
	const [show, setShow] = useState(false);
	const [enable, setEnable] = useState(false)
	const [name, setName] = useState("")
	const [orgId, setOrgId] = useState("")
	const [role, setRole] = useState("")
	const [all, setAll] = useState("")
	const [arr, setArr] = useState([]);
	const [arr2, setArr2] = useState([])
	const [arr3, setArr3] = useState([])
	const [id, setId] = useState()
	const [keyword, setkeyword] = useState("")
	const [topData, setTopData] = useState([])
	const [refrash, setRefrash] = useState(false);
	const axiosinstance = useAxios()
	const { profile } = useSelector((state) => state.mainReducer.profileData);
	const listofchannels = useSelector((state) => state.mainReducer.channelList.channels);
	const org_channels = useSelector((state) => state.mainReducer.channelList.org_channel);



	const check = (data) => {
		if (data === false || data === "false") {
		  return false
		} else {
		  return true
		}
	
	  }

	let i = 0
	let json = {
		name: name,
		org_id: orgId || null,
		role_id: role,
		all: check(all),
		ak_id: result
	};


	const added = () => {
		axiosinstance.current.post("create_channel", json, {
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => {
			toast.success(getTranslation("channel_added"));
			// GetResult();
			setRefrash(true)
			setName("")
			setOrgId("")
			setRole("")
			setAll("")
			setSelectedOption([])
		})
	}


	useEffect(() => {
	  if(all===true || "true"){
		setSelectedOption([])
	  }
	}, [all])
	



	useEffect(() => {
		if (keyword.length > 2 || keyword.length === 0) {
			setTimeout(() => {
				axiosinstance.current.get(`get_channel_list?ak_id=${profile[0]?.id}&keyword=${keyword}`).then((response) => {
					if (response) {
						dispatch(setChannelList(response.data.data))
						dispatch(setChannelLists(response.data.data2))
						setTimeout(() => {
							setRefrash(false)
						}, 2000);
					}
				});
			}, 2000);
		}
	}, [profile, keyword, refrash])


	useEffect(() => {
		if (name.length !== 0 && all.length !== 0 || orgId.length !== 0 && result.length !== 0) {
			setEnable(false)
		} else {
			setEnable(true)

		}


	}, [name, orgId, role, all, result]);



	const Edit = (id, name, role, all, org_id) => {

		if (all === false || all==="false") {
			axiosinstance.current
				.get(`get_particular_channel_data?id=${id}`,
				)
				.then((response) => {

					setSelectedOption(response.data.data.users)
					setOrgId(response.data.data.org_id);
					setName(response.data.data.name)
					setId(response.data.data.id);
					setRole(response.data.data.role_id)
					setAll(false)
					setShow(true);
				})
				.catch(function (error) {

				});
		} else {
			if (org_id == null) {
				setOrgId("");
			} else {
				setOrgId(org_id);
			}
			setName(name)
			setId(id);
			setRole(role)
			setAll(all)
			setSelectedOption([])
			setShow(true);

		}

	};



	const EditItem = () => {


		const json = JSON.stringify({
			name: name,
			akou_id: orgId === "" ? null : orgId,
			role_id: role === "" ? "" : Number(role),
			all:  check(all),
			ak_ids: result
		});

		axiosinstance.current
			.patch(`update_channels?id=${id}&ak_id=${profile[0]?.id}`, json, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				toast.success(getTranslation("channel_updated"));
				setName("")
				setOrgId("")
				setRole("")
				setAll("")
				setRefrash(true)
				setSelectedOption([])
				// GetResult();
				setRefrash(1)
			})
			.catch(function (error) {
				// handle error
				toast.error(error.response.data.message);
				// GetResult();
				setName("")
				setOrgId("")
				setRole("")
				setAll("")
				setSelectedOption([])
			});
		setShow(false);

	};




	const Delete = () => {
		axiosinstance.current.delete(`delete_channel?id=${id}&ak_id=${profile[0]?.id}`).then((response) => {
			// console.log(response.data.message)
			setRefrash(1);
			toast.success(getTranslation("channel_deleted"));
			setRefrash(true)
			// GetResult();
		}).catch((error) => {
			toast.error(error);
		});

	};







	const handleOnSearch = (value, results) => {
		// console.log(value);
		if (value.length > 2) {
			setTimeout(() => {
				axiosinstance.current
					// .get(`/extapp/forms/search_organization?keyword=${value}`)
					.get(`searchUser?key=${value}&type=${Number(role)}&org_id=${orgId}`)
					.then((response) => {
						const options = response.data.data.map((item) => {
							return {
								value: item.ak_id,
								// label: item.firstname + " (" + item.ak_id + ")",
								label: `${item.firstname} ${item.lastname} (${item.ak_id})`

							};
						});
						setTopData(options);
					})
					.catch((error) => {

						setTopData([]);
					});
			}, 2000);

		} else {
			setTopData([]);
		}
	};

	const [selectedOption, setSelectedOption] = useState([]);
	const handleSelect = (event, newValue) => {
		setSelectedOption(newValue);
	};

	selectedOption.map((ele) => {
		result.push(ele.value)
	})

	const spacialCharReplace = (e) => {
		return e.replace(/-|'|,|_/g, "")
	}

	const organizationList = () => {
		axiosinstance.current.get(`akoulist`).then((response) => {
			setArr3(response.data.data);
		});

	}

	useEffect(() => {
		if (profile[0]?.role_value === 100) {
			organizationList()
		}

	}, [profile])



	const getTranslation = (key) => {
		const translations =
		  profile[0]?.language === "en"
			? en
			: profile[0]?.language === "ger"
			  ? ger
			  : profile[0]?.language === "ita"
				? ita
				: profile[0]?.language === "ara"
				  ? ara
				  : profile[0]?.language === "spa"
					? spa
					: profile[0]?.language === "tha" ? tha
					  : profile[0]?.language === "kor" ? kor
						: profile[0]?.language === "ch" ? chn : fr
		return translations[key] || key;
	  };
	return (
		<>
			<ToastContainer position="top-center" autoClose={1000} />
			<div className="container-fluid">
				<div className="row">
					<div className="upcoming-head">
						{/* <h4> Manage Categories</h4> */}
						<hr></hr>
					</div>
				</div>
				<div className="row">
					<div className="col-xxl-5 col-lg-5 col-md-5 col-12 ">
						<div className="">
							<div className="card custom-card">
								<div className="card-body">
									<h4 className="card-title">{getTranslation('add_channels')}</h4>
									<hr></hr>

									<div className="row justify-content-right">
										{
											profile[0]?.role_value === 70 ? (
												<div className="row g-3  mt-2">
													<div className="mxw_25">
														<label
															htmlFor="name"
															className="col-form-label for-label tags"
														>
															{getTranslation('organization')}:{" "}
														</label>
													</div>
													<div className="mxw_75 for-input">
														<select
															className="form-select dropdown-selector"
															style={{ borderRadius: "6px" }}
															onChange={(e) => setOrgId(e.target.value)}
															value={orgId}
														>
															<option value="">{getTranslation("select")}</option>
															{
																org_channels && org_channels.map((ele) => (
																	<option value={ele.label}>{ele.value}</option>
																))
															}
														</select>
													</div>
												</div>
											) : (null)
										}



										{
											profile[0]?.role_value === 100 ? (
												<div className="row g-3  mt-2">
													<div className="mxw_25">
														<label
															htmlFor="name"
															className="col-form-label for-label tags"
														>
															{getTranslation('organization')}:{" "}
														</label>
													</div>
													<div className="mxw_75 for-input">
														<select
															className="form-select dropdown-selector"
															style={{ borderRadius: "6px" }}
															onChange={(e) => setOrgId(e.target.value)}
															value={orgId}
														>
															<option value="">{getTranslation("global_channel")}</option>
															{
																arr3.map((ele) => (
																	<option value={ele.lab_no}>{ele.from}</option>
																))
															}
														</select>
													</div>
												</div>
											) : (null)
										}









										<div className="row g-3  mt-2">
											<div className="mxw_25">
												<label
													htmlFor="name"
													className="col-form-label for-label tags"
												>
													{getTranslation('name')}:{" "}
												</label>
											</div>
											<div className="mxw_75 for-input">
												<input
													type="text"
													id="name"
													name="title"
													className="form-control "
													style={{ borderRadius: "6px" }}
													defaultValue=""
													onChange={(e) => spacialCharReplace(setName(e.target.value.replace(/[\/,?,$,#,%,&,!,*@]/g, '')))}
													value={name}
												/>
											</div>

										</div>


										<div className="row g-3  mt-2">
											<div className="mxw_25">
												<label
													htmlFor="name"
													className="col-form-label for-label tags"
												>
													{getTranslation('role')}:{" "}
												</label>
											</div>
											<div className="mxw_75 for-input">
												<select
													className="form-select dropdown-selector"
													style={{ borderRadius: "6px" }}
													onChange={(e) => setRole(e.target.value)}
													value={role}
												>

													<option value="">{getTranslation("all_users")}</option>
													<option value="1">{getTranslation("patients")}</option>
													<option value="3">{getTranslation("HCPs")}</option>
												</select>
											</div>
										</div>

										<div className="row g-3  mt-2">
											<div className="mxw_25">
												<label
													htmlFor="name"
													className="col-form-label for-label tags"
												>
													{getTranslation('all')}:{" "}
												</label>
											</div>
											<div className="mxw_75 for-input">
												<select
													className="form-select dropdown-selector"
													style={{ borderRadius: "6px" }}
													onChange={(e) => setAll(e.target.value)}
													value={all}
												>
													<option value="0">{getTranslation("select")}</option>
													<option value={true}>{getTranslation("all_users")}</option>
													<option value={false}>{getTranslation("selected_users")}</option>

												</select>
											</div>
										</div>

										{
											all === "false" || all === false ? (
												<div className="row g-3  mt-2">
													<div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4">
														<label
															htmlFor="name"
															className="col-form-label for-label tags"
														>
															{getTranslation('users')}:{" "}
														</label>
													</div>

													<div className="col-xxl-8 col-lg-8 col-md-8 col-sm-8 col-8">
														<Stack spacing={2} sx={{ width: 500 }} style={{ widt: "100%" }}>
															<Autocomplete
																multiple
																id="size-small-outlined-multi"
																size="small"
																options={topData}
																// getOptionLabel={(option) => option.ak_id}
																value={selectedOption}
																style={{ marginLeft: "-20px" }}
																// defaultValue={default1[0]}
																onChange={handleSelect}
																renderInput={(params) => (
																	<TextField
																		placeholder="Select"
																		className=""
																		style={
																			{
																				// height: "34px",
																				// // border: "2px solid rgba(63, 39, 131, 0.7)",
																				// borderRadius: "10px",
																				// color: "#3F2783",
																				// // padding: "0 15px",
																				// height: "40px",
																				// marginBottom: "12px",
																				// width: "100%",
																				// boxShadow: "none",
																				// zIndex: 1,
																			}
																		}
																		{...params}
																		onChange={(e) => handleOnSearch(e.target.value)}
																		InputProps={{
																			...params.InputProps,
																			type: "search",
																		}}
																	/>
																)}
															/>
														</Stack>
													</div>
												</div>
											) : (null)
										}




										{/* <div className="row justify-content-right">
                                            <div className="col-12 ">
                                                <div className="float-end mt-4">
                                                    <button
                                                        className={enable == false ? "btn premium-btn tags-button butttonn" : "updatess-button"}
                                                        onClick={added}
                                                        disabled={enable}
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
										<div className="row justify-content-right">
											<div className="col-12 ">
												<div className="float-end mt-4">
													{!show ? (
														<button
															className={enable == false ? "btn premium-btn tags-button butttonn" : "updatess-button"}
															onClick={added}
															disabled={enable}
														>
															{getTranslation('add')}
														</button>
													) : (
														<button
															className={enable == false ? "btn premium-btn tags-button butttonn" : "updatess-button"}
															onClick={() => EditItem()}
															disabled={enable}
														>
															{getTranslation('edit')}
														</button>
													)}
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="col-xxl-7 col-lg-7 col-md-7 col-12 mob_ch_list">
						<div className="">
							<div className="card custom-card">
								<div className="card-body">
									<div className="col-md-12">
										<div className="row">
											<div className="col-md-8">

												<h4 className="card-title">{getTranslation('list_of_channels')}</h4>
											</div>
											<div className="col-md-4">
												<div className="col-auto ms-auto">
													<div className="input-group border-0">
														<input
															type="text"
															onChange={(e) => setkeyword(e.target.value)}
															className="form-control placeholder-design"
															style={{ width: "114px", boxShadow: "none" }}
															placeholder={getTranslation("search_by_name")}
															aria-label="Username"
															aria-describedby="basic-addon1"
														/>
														<span
															className="input-group-text bg-white"
															id="basic-addon1"
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width={16}
																height={16}
																fill="currentColor"
																className="bi bi-search"
																viewBox="0 0 16 16"
															>
																<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
															</svg>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<hr></hr>

									<div className="table-responsive table-scroll">
										<table className="table table-hover table-striped table-bordered  mt-3">
											<thead>
												<tr>
													<th>{getTranslation('s_no')}</th>
													<th>{getTranslation('name')}</th>
													<th>{getTranslation('user_type')}</th>
													<th>{getTranslation('actions')}</th>
												</tr>
											</thead>
											<tbody>
												{listofchannels.map((data) => (
													<tr key={data.id}>
														<td>{++i}</td>
														<td>{data.name}</td>
														<td>
															{/* <div className="form-check form-switch">
                                                        
                                                                <label className="toggle">
                                                                    <input className="form-check-input toggle__input" role="switch" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={
                                                                        data.status === 1 ? true : false

                                                                    } 
                                                                    // onChange={(e) =>handleChange(data.id, check, data.title, data.status)} 
                                                                    
                                                                    />
                                                                    <div class="toggle__fill">
                                                                        <span className="toggles">I</span>
                                                                        <span className="togglessss">o</span>
                                                                    </div>
                                                                </label>

                                                            </div> */}

															<td>{getTranslation(data.user_type)}</td>
														</td>
														<td>
															<button
																className="btn"
																onClick={() => Edit(data.id, data.name, data.role, data.all, data.org_id)}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="16"
																	height="16"
																	fill="currentColor"
																	className="bi bi-pencil-square"
																	viewBox="0 0 16 16"
																>
																	<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
																	<path
																		fillRule="evenodd"
																		d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
																	/>
																</svg>
															</button>
														</td>

														<td>
															<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
																<div class="modal-dialog modal-dialog-centered">
																	<div class="modal-content ">

																		<div class="modal-body">
																			<h5>{getTranslation('channel_delete')}</h5>
																		</div>
																		<div class="modal-footer">
																			<button type="button" className="btn btn-secondary yesnobutton" data-bs-dismiss="modal">{getTranslation('no')}</button>
																			<button type="button" className=" btn btn-secondary yesnobutton" data-bs-dismiss="modal"
																				onClick={() => Delete(data.id)}

																			>
																				{getTranslation('yes')}</button>
																		</div>
																	</div>
																</div>
															</div>
															<button
																// style={{ border: "none" }}
																data-bs-toggle="modal"
																data-bs-target="#exampleModal"
																onClick={() => setId(data.id)}
																className="btn"
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={16}
																	height={16}
																	fill="currentColor"
																	className="bi bi-trash-fill"
																	viewBox="0 0 16 16"
																>
																	<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
																</svg>
															</button>
														</td>
													</tr>
												))}
											</tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default ChannnelData
