import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import HttpService from "../services/HttpService";
import Avatar from "react-avatar";

import useTranslation from "./customHooks/translations";
// import { useDispatch } from "react-redux";
// import { setVideo } from "../actions/videoAction";

function PostItemlink(props) {
  // const [image1, setimage1] = useState('');
  const translation = useTranslation();



  // const dispatch = useDispatch();

  let title = props.title;
  let postTitle = title.substr(0, 40);
  //  function  handleClick(){
  //   dispatch(setVideo(props.id))
  //   }
  // const cont =(l)=>(
  // window.open(l)
  // )
  return (
    <a
      href={props.link}
      target="_blank"
      data-bs-toggle="tooltip"
      data-bs-placement="down"
      title={title}
      rel="noreferrer"
    >
    
      <div >
        <div className="live_e">
          {props.type === 4 && (
            <p>{translation.lives}</p>
          )}

        </div>

        <div className="upcoming-box">
          <img src={props.large} alt="" className="img-fluid "></img>
          <div className="play_">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              fill="currentColor"
              className="bi bi-play-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
            </svg>
          </div>
        </div>
        {/* <div className="time_">
          <p>{props.duration}</p>
        </div> */}
        <div className="upcoming-heading d-flex">
          <div className="upcoming-img">
            {props.profile != "" && (
              <img
                src={props.profile}
                alt=""
                className="img-fluid"
              ></img>
            )}
            {props.profile === "" && (
              <Avatar
                className="me-2"
                name={props.posted_by.slice(0, 1)}
                size="40"
                maxInitials="1"
                round={true}
                fgColor="#EA702B"
                color="#F3CDB8"
              />
            )}
          </div>
          <div className="upcoming-title">
            <h2>{postTitle} {title.length > 25 && (
              <span> ...</span>
            )}</h2>
            <p className="text-uppercase">{props.posted_by.name}</p>
          </div>
        </div>
      </div>
    </a>
  );
}
export default PostItemlink;