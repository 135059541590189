import React from "react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { setRecent } from "../actions/recentlyReducer";

import { setSearch } from "../actions/searchAction";
import SmallSkeleton from "./smallSkeleton";
// import instance from "../axiosinstance";
import PostItemSmall from "./PostItemSmall";
// import { useLocation } from "react-router-dom";
import HttpService from "../services/HttpService";
// import { useNavigate } from "react-router-dom";
import PostItemSmalllink from "./PostItemSmalllink";
import useAxios from "../axiosinstance";
import Categories from "./Categories";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';







export default function SearchList() {
  const axiosinstance = useAxios()
  const [offset, setOffset] = useState(16);
  // const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get("id");
  const start_date = queryParams.get("start");
  const end_date = queryParams.get("end");
  const cat_id = queryParams.get("cat_id");
  const tagdata = queryParams.get("tagdata");
  const ak = queryParams.get("ak");
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const [categoryids, setCategoryid] = useState("");
  const start = 16;
  const [limit, setLimit] = useState(16);
  let categoryid = "";
  if (cat_id) {
    categoryid = atob(cat_id);

  }
  let postid = "";
  if (q) {
    postid = atob(q);

  }
useEffect(()=>{
  setLimit(16)
},[categoryid])
  useEffect(() => {
    if (tagdata) {
      localStorage.setItem("tagdata", tagdata)
    }
  }, [tagdata])
  let tagdatacall = localStorage.getItem("tagdata")
  console.log(tagdata, "tagdata");

  // const post_id = atob(q)
  // const limit = 16;
  let dStartDate = " ";
  let denddate = " ";
  if (start_date) {
    dStartDate = atob(start_date);

  }
  if (end_date) {
    denddate = atob(end_date);

  }

  // let dStartDate=atob(start_date);
  // let denddate=atob(end_date);
  const [startDate, setStartDate] = useState(dStartDate);
  const [endDate, setendDate] = useState(denddate);
  const dispatch = useDispatch();
  const { arr } = useSelector((state) => state.mainReducer.searchData);
  const [newArr, setNewArr] = useState(arr)
  const [check, setcheck] = useState();

  const [loop, setLoop] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (profile.length > 0) {
      setLoop(false);
      axiosinstance.current
        .get(
          `categoryid?category_id=${categoryid}&ak_id=${profile[0]?.id}&limit=${start}&offset=0&post_id=${postid}`
        )
        .then((response) => {
          if (response) {

            setShow(true);
            // console.log(response.data.results);
            // setPost(response.data.results);
            setNewArr(response.data.data)
            dispatch(setSearch(response.data.data));

            setcheck(response.data.data.length);
          } else {
            setShow(false);
          }
        }).catch((err) => {
          setcheck(0)
        })
    }


  }, [profile, postid, categoryid]);






  const fetchNextUsers = () => {
    setLimit(limit + 16);
    console.log("limit", limit, loop)
    if (loop === false) {
      axiosinstance.current
        .get(
          `categoryid?category_id=${categoryid}&ak_id=${profile[0].id}&limit=${start}&offset=${limit}&post_id=${postid}`
        )
        .then((response) => {
          let res = response.data.data;
          if (res.length === 0) {
            setLoop(true);
            
          } else {

            setNewArr(newArr.concat(response.data.data));
          }
        });
    }
  };
  const getData = (data) => {
    setCategoryid(data);


  };



  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };


  useEffect(() => {
    if (categoryids && tagdatacall !== "true") {
      axiosinstance.current
        .get(
          `search?limit=${start}&offset=${limit}&category=${categoryids}&type=${1}&sDate=${startDate}&eDate=${endDate}&ak_id=${profile[0]?.id}`
        )
        .then((response) => {
          if (response) {
            setShow(true);
            setNewArr(response.data.data)
            setcheck(response.data.data.length);
          } else {
            setShow(false);
          }
        });
    }



  }, [profile, categoryids, startDate, endDate]);




  return (
    <>
      <Categories onSubmit={getData} />
      <div className=""></div>
      <div className="trending">
        <div className="upcoming-head filter">
          {ak === "true" ? <h4>{getTranslation("akunah_how_tos")}</h4> : <h4>{getTranslation("search")}</h4>}
        </div>
        <div className="trendig-box">
          <InfiniteScroll
            dataLength={newArr.length}
            next={fetchNextUsers}
            hasMore={true}
            loader={
              ""
              //   <div className="load_next text-center " style={{ 'textTransform': 'uppercase'}}>
              //   <span>No more Data</span>
              // </div>
            }
          >
            <div className="row">
              {!show && <SmallSkeleton></SmallSkeleton>}
              {show &&
                newArr.map((post) => (
                  <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12" >


                    {post.type === 1 || post.type === "1" ? (
                      <PostItemSmall
                        id={post.id}
                        title={post.title}
                        posted_by={post.posted_by}
                        videoKey={post.video_key}
                        duration={post.duration}
                        large={post.large}
                        medium={post.medium}
                        views={post.views}
                        description={post.description}
                        data={post.date}
                        category={post.category_id}
                        featured={post.featured}
                        ap={post.akunah_public}
                        ae={post.akunah_exc}
                        op={post.org_public}
                        oe={post.org_exc}
                        hp={post.hcp_public}
                        he={post.hcp_exc}
                        sessionId={post.sessionId}
                        profile={post.profile}
                        type={post.type}
                        link={post.link}
                      />
                    ) : (
                      <PostItemSmalllink
                        id={post.id}
                        title={post.title}
                        posted_by={post.posted_by}
                        videoKey={post.video_key}
                        duration={post.duration}
                        link={post.link}
                        large={post.large}
                        medium={post.medium}
                        views={post.views}
                        description={post.description}
                        data={post.date}
                        category={post.category_id}
                        featured={post.featured}
                        ap={post.akunah_public}
                        ae={post.akunah_exc}
                        op={post.org_public}
                        oe={post.org_exc}
                        hp={post.hcp_public}
                        he={post.hcp_exc}
                        sessionId={post.sessionId}
                        profile={post.profile}
                        type={post.type}

                      />
                    )}

                  </div>
                ))}
            </div>
          </InfiniteScroll>
          {/* <div className="load_next text-center " style={{ 'text-transform': 'uppercase'}}>
            <span>No more Data</span>
          </div> */}
          {check === 0 && (
            <div className="no_data">
              <h2>No videos found</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
