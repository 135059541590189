import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import typeConfig from "../config/typeConfig";
//import InfiniteScroll from 'react-infinite-scroll-component'
// import instance from "../axiosinstance";
// import appConfig from "../config/appUrlConfig";
import { setLive } from "../actions/liveAction";
import SmallSkeleton from "./smallSkeleton";
// import { setSearch } from "../actions";

import Categories from "./Categories";
import PostItemlink from './PostItembylink';
// import NewCategories from "./NewCategories";
// import HttpService from "../services/HttpService";
import PostItemSmalllink from "./PostItemSmalllink";
import useTranslation from "./customHooks/translations";
import useAxios from "../axiosinstance";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';





export default function LiveList() {
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  const translation = useTranslation();
  const axiosinstance =useAxios()
  const start = 16; 
  let typeID = typeConfig.liveID;
  const [show, setShow] = useState(false);
  const [categoryid, setCategoryid] = useState("");
  const [loop, setLoop] = useState(false);
  const dispatch = useDispatch();
  const { arr } = useSelector(state => state.mainReducer.liveData);

  const [check, setcheck] = useState(arr.length);

  const [loader1, setloader1] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [limit, setLimit] = useState(16);
  const [datalimit, setDataLimit] = useState(true);

  function handleChange(event) {
    setStartDate(event.target.value);
  }
  function handleChangeEnd(event) {
    setendDate(event.target.value);
  }


  const getData = (data) => {
    setCategoryid(data);
  };

  useEffect(() => {
    if (arr.length === 0 || categoryid !== "") {
      axiosinstance.current
        .get(
          `live_video?limit=${start}&offset=${0}&category=${categoryid}&type=${2}&sDate=${startDate}&eDate=${endDate}&ak_id=${profile[0]?.id}`
        )
        .then((response) => {
          if (response.status===200) {
            setloader1(true)
            const apiRes = response.data.data;
            setShow(true);
            dispatch(setLive(apiRes));
            setcheck(response.data.data.length);

          } else {
            setShow(false);

          }
        });
    }
    if (arr) {
      setShow(true);
    } else {
      setShow(false);

    }
  }, [categoryid, startDate, endDate]);




  const fetchNextUsers = () => {
    setLimit(limit + 16);
    // let cat_id = "";
    if (loop === false) {
      axiosinstance.current
        .get(
          `live_video?limit=${start}&category=${categoryid}&type=${2}&offset=${limit}&sDate=${startDate}&eDate=${endDate}&ak_id=${profile[0]?.id}`
        )
        .then((response) => {
          let res = response.data.data;
          if (res.length === 0) {
            setloader1(true)
            setDataLimit(false)
            setLoop(true)
          } else {
            // console.log('after scroll',response.data.data)
            setloader1(true)
            dispatch(setLive(arr.concat(response.data.data)));
          }
        });
    }
  };

  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };



  return (
    <>
      <Categories onSubmit={getData} />
      <div className="clearfix"></div>
      <div className="live upcoming">
        <div className="upcoming-head filter">
          <h4>{getTranslation("live")}</h4>
          {/* <p className="filter cursor" data-bs-toggle="dropdown">
            <img src="Assets/image/filter_icon.svg" alt="filter" />
            {translation.filter}
          </p> */}

          {/* <ul className="filter dropdown-menu p-2">
            <li className="m-2">
              {translation.start_date}{" "}
              <span className="filter_date">
                <input
                  onChange={handleChange}
                  type="date"
                  max={appConfig.new_date}
                />
              </span>{" "}
            </li>

            <li className="m-3 mt-4">
              {translation.end_date}{" "}
              <span className="filter_date">
                <input
                  onChange={handleChangeEnd}
                  type="date"
                  max={appConfig.new_date}
                />
              </span>{" "}
            </li>
          </ul> */}
        </div>

        <div className="row">
          {!show && <SmallSkeleton></SmallSkeleton>}
          {show &&
            arr.slice(0, 2).map((post) => (
              <div className="col-xxl-6 col-xl-6 col-md-6 col-md-6 col-sm-12 col-12" key={post.id}>
                <PostItemlink
                      id={post.id}
                      title={post.title}
                      posted_by={post.posted_by}
                      videoKey={post.video_key}
                      duration={post.duration}
                      large={post.large}
                      medium={post.medium}
                      link={post.link}
                      views={post.views}
                      description={post.description}
                      data={post.date}
                      category={post.category_id}
                      featured={post.featured}
                      ap={post.akunah_public}
                      ae={post.akunah_exc}
                      op={post.org_public}
                      oe={post.org_exc}
                      hp={post.hcp_public}
                      he={post.hcp_exc}
                      type={post.type}
                      profile={post.profile}
                />

              </div>
              // console.log(data)
            ))}
        </div>

      </div>

      <div className="clearfix"></div>
      <div className=" live trending">
        <div className="trendig-box">
          <InfiniteScroll
            dataLength={arr.length}
            next={fetchNextUsers}
            hasMore={true}
            loader={
              <>
               {!loader1 ? <div className="no_data">
               {/* <h2>{getTranslation('loading')}</h2> */}
               <SmallSkeleton></SmallSkeleton>
             </div>:(
               arr.length===0 ? (
                 <div className="no_data">
               <h2>{getTranslation('no_live_video')}</h2>
             </div>
               ):""
             )}
              </>
             }
          >
            <div className="row">
              {show &&
                arr.slice(2, arr.length).map((post) => (
                  <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-6 col-12" key={post.id}>
                    <PostItemSmalllink
                       id={post.id}
                      title={post.title}
                      posted_by={post.posted_by}
                      videoKey={post.video_key}
                      duration={post.duration}
                      large={post.large}
                      medium={post.medium}
                      views={post.views}
                      link={post.link}
                      description={post.description}
                      data={post.date}
                      category={post.category_id}
                      featured={post.featured}
                      ap={post.akunah_public}
                      ae={post.akunah_exc}
                      op={post.org_public}
                      oe={post.org_exc}
                      hp={post.hcp_public}
                      he={post.hcp_exc}
                      profile={post.profile}

                    />
                  </div>
                ))}
            </div>
          </InfiniteScroll>
          <div className="load_next text-center">
            {/* <span>Loading more</span> */}

          </div>
          {/* {check === 0 && (

            <div className="no_data">
              <h2>{getTranslation('no_live_video')}</h2>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
