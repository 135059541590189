
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "جميع مقاطع الفيديو",
  upcoming: "القادمة",
  trending: "الشائع",
  recently_added: "أضيف مؤخرا",
  live: "أحداث حية",
  library: "المفضلة لديّ",
  view_by_catagories: "عرض حسب التخصص",
  loading: "تحميل...",
  filter: "فلتر",
  profile: "الملف",
  profile_photo: "صورة الملف الشخصي",
  first_name: "الاسم ",
  last_name: "اسم العائلة",
  update_profile: "تحديث الملف",
  Update_Profiless: "تحديث الملف",


  edit_profile: "تعديل الملف الشخصي",
  date_of_birth: "تاريخ الميلاد",
  address: "العنوان",
  registered_email: "بريد الكتروني مسجل",
  registered_phone: "الهاتف المسجل",
  subscription_key: "مفتاح الاشتراك",
  renew_key: "تجديد مفتاح",
  start_date: "تاريخ البدء",
  end_date: "تاريخ الانتهاء",
  no_more_data: "لا مزيد من البيانات",
  search: "بحث",
  logout: "تسجيل خروج",
  view_profile: "عرض الصفحة الشخصية",
  notifications: "إشعارات",
  Get_Premium: "إحصل على المتميزة",
  App_Version: "نسخة التطبيق",
  Get_3_months_of_premium_for_free: "احصل على حساب المتميزة 3 أشهر مجانًا.",
  Click_below_to_sign_up_for_premium_Akunah_MEd: "انقر أدناه للتسجيل للحصول على قسط أكونا ميد",
  request_to_join: "انضم إلى HCP كمريض",
  request_to_join_p: "انضم إلى المنظمة كمريض",
  request_to_join_h: "الانضمام إلى المنظمة",
  lives: "مباشر",
  plan: "كل الخطط",
  channels: "الرئيسية",

  akunahPublicP: "فيديوهاتي الصحية",
  OrgPublicPatient: "مقاطع الفيديو الصحية لمزودي الخدمة",
  HCPPublicPatient: "فيديو طبيبي",
  HCPPrivatePatient: "فيديوهاتي الحصرية",
  akunahExc: "Akunah Global HCP", //Not Update
  akunahPublic: "أكونا جلوبال بابليك",
  OrgPublic: "مؤسستي عامة",
  OrgExc: "My Org HCP",
  mypublic: "شعبي",
  myprivate: "حصري",
  // myhcppublic: "f-my hcp public",
  // myhcpprivate: "f-my hcp Exclusive",
  myOrgPublic: "الجمهور الخاص بي",
  myOrgExc: "حصري",






  akunah_how_tos: "أكونا How Tos",
  Upload_a_Video: "تحميل الفيديو",
  Channelsdata: "القنوات",
  administration: "إدارة",
  view_all: " مشاهدة الكل",
  login_by: "تسجيل الدخول ك",
  upload_video: "رفع فيديو",
  type_of_content: "نوع المحتوى",
  title: "عنوان",
  thumb_imag1: "صورة مصغرة (حجم الصورة 720 × 405)",
  thumb_imag2: "صورة مصغرة (حجم الصورة 480X270)",
  video: "فيديو",
  link: "رابط التدفق",
  tags: "العلامات",
  organization: "منظمة",
  description: "وصف",
  publish: "ينشر",
  submit: "ارسال",



  manage_my_videos: "إدارة مقاطع الفيديو الخاصة بي",
  manage_my_organizations: "إدارة مؤسساتي",
  manage_my_hcps: "إدارة HCPs الخاص بي",
  manage_org_patient: "إدارة منظمة المريض",
  manage_my_patients: "إدارة مريضي",
  manage_tags: "إدارة العلامات",
  manage_my_plans: "إدارة خططي",







  video_list: "قائمة مقاطع الفيديو",
  upload_videos: "تحميل أشرطة الفيديو",
  limit: "حد",
  type: "نوع",
  posted_by: "منشور من طرف",
  organization: "منظمة",
  s_no: ".S No",
  video_id: "معرف الفيديو",
  duration: " مدة",
  video_size: "حجم الفيديو",
  actions: "أجراءات",
  add_a_organization: "أضف منظمة",
  org_name: "اسم المنظمة",
  size: "مقاس",
  watch_time: "وقت المشاهدة",
  sort: "نوع",
  status: "حالة",
  organization_Id: "معرّف المنظمة",
  total_post_size: "إجمالي حجم المنشور",
  total_watch_time: " إجمالي وقت المشاهدة",
  add_tags: "اضف اشارة",
  order: "طلب",
  add: "يضيف",
  edit: "يحرر",
  tags_list: "قائمة العلامات",
  add_plan: "أضف خطة",
  plan_name: "اسم الخطة",
  number_of_hcp: "عدد HCP",
  number_of_patient: "عدد المرضى",
  Limit: "الحد (جيجابايت)",
  plan_list: "قائمة الخطط",
  noofhcp1: "رقم HCP",
  nopatient1: "رقم المريض",
  save: "احفظ",
  add_channels: "أضف القنوات",
  name: "اسم",
  role: "ظاهر للأدوار",
  all: "طلب نشر",
  users: "المستخدمون",
  user_type: "نوع المستخدم",
  list_of_channels: "قائمة القنوات",
  event_Link: "رابط الحدث",
  visible_till: "* ظاهر حتى",
  tags_channels: "علامات القناة",
  manage_hcps: "إدارة HCP's",
  pending_hcp_req: "في انتظار طلب HCP",
  invite_hcp: "إستدعاء HCP",
  email: "بريد إلكتروني",
  delete_hcp: "هل أنت متأكد أنك تريد حذف HCP؟",
  no: "لا",
  yes: "نعم",
  add_email: "أضف بريدًا إلكترونيًا",
  view_plans: "مشاهدة الخطط",
  activate_date: "تاريخ تفعيل التسجيل ",
  expiry_date: "تاريخ الانتهاء",
  hcp: "طبيب عام",
  patient: "مريض",
  total_hcp: "إجمالي HCP",
  total_patient: "إجمالي المريض",
  invite_admin: "إستدعاء المسؤول",
  autoid: "معرف تلقائي",
  delete_admin: "هل أنت متأكد أنك تريد حذف كمسؤول؟",
  admin_user_email: "البريد الإلكتروني لالمستخدم المسؤول",
  view_admins: "مشاهدة ملف Admins",
  view_HCPs: "مشاهدة ملف HCP's",
  patient_list: "قائمة المرضى",
  pending_patient_req: "طلب المرضى قيد الانتظار",
  invite_patient: "إستدعاء المريض",
  patient_name: "اسم المريض",
  delete_patient: "هل أنت متأكد أنك تريد حذف المريض؟",
  no_videos_found: "لا توجد فيديوهات",
  not_found_trending: "لم يتم العثور على مقاطع فيديو شائعة",
  upload_video_popup: "يجب عليك إنشاء مؤسستك الخاصة لتحميل مقاطع الفيديو",
  create_org: "إنشاء منظمة",
  list_of_user_profiles: "قائمة ملفات تعريف المستخدمين",
  all_speciality: "كل تخصص",
  channel_delete: "هل أنت متأكد أنك ترغب في حذف هذا العنصر؟",
  req_for_create_org: "طلب إنشاء منظمة",
  cancel: "يلغي",
  send: "إرسال",
  akunahhandbook: "دليل أكونه",
  help: "مساعدة أكونه",
  subject: "موضوع",
  message: "رسالة",
  no_live_video: "لم يتم العثور على مقاطع فيديو مباشرة",
  hcp_name: "اسم HCP",
  expiry_days: "أيام انتهاء الصلاحية",
  send_req: "ارسل طلب",
  search_by_speciality: "البحث حسب التخصص",
  no_request_data: "لا توجد بيانات مطلوبة",
  no_data_found: "لاتوجد بيانات",
  approve_patient_list: "الموافقة على قائمة المرضى",
  create_event: "أنشئ حدثًا",
  live_event: "حدث مباشر",
  switch_profile: "تبديل الملف الشخصي",
  event_link: "رابط الحدث",
  search_by_patient_email_id: "البحث عن طريق المريض ومعرف البريد الإلكتروني",
  searchbyb_title: "البحث عن طريق العنوان",
  search_by_org_name: "البحث عن طريق اسم المنظمة",
  enter_email_address: "أدخل عنوان البريد الالكتروني",
  no_of_days: "عدد الأيام",




  //12/7/2023//need to transalte


  acceopt: "قبول",
  reject: "رفض",
  approved: "موافقة",
  select: "إختار",
  active: "نشط",
  deactive: "تعطيل",
  select_plan: "حدد الخطة",
  views: "مشاهدات",
  view_patients: "عرض المرضى",
  valid_email: "أدخل بريدًا إلكترونيًا صالحًا !!!",
  is_already_created: "تم إنشاؤه بالفعل",
  req_to_hcps: "طلب إلى HCP'S",
  approve: "موافقة",
  upload: "تحميل",
  search_by_name: "البحث عن طريق الإسم",
  global_channel: "القناة العالمية",
  all_users: "جميع المستخدمين",
  patients: "المرضى",
  HCPs: "HCPs",
  selected_users: "المستخدمون المحددون",
  post_updated: "آخر تحديث",
  video_deleted: "تم حذف الفيديو بنجاح",
  ascending: "تصاعدي",
  descending: "تنازلي",
  all_drop: "الجميع",
  akunah: "أكونا",
  channel_added: "تمت إضافة القناة",
  plan_status: "تم تغيير حالة الخطة",
  tag_added: "تمت إضافة العلامة",
  tag_updated: "علامة محدثة",
  hcp_deleted: "تم حذف HCP بنجاح",
  pending: "قيد الانتظار",
  patient_deleted: "تم حذف المريض بنجاح",
  is_new_patient: "تم إضافة مريض جديد",
  new_plan_added: "تمت إضافة الخطة جديدة",
  org_added: "المنظمة المضافة",
  date: "تاريخ",
  missing_subj: "الموضوع مفقود",
  missing_message: "الرسالة مفقودة",
  select_user: "اختر المستخدم",
  field_req: "هذه الخانة مطلوبه",
  select_org: "حدد المنظمة",
  is_already_org: "موجود بالفعل في المنظمة",
  org_error: "تم إنشاء هذه المنظمة بالفعل. يرجى الانضمام بدلاً من إنشاء واحدة جديدة.",
  email_sent: "تم إرسال البريد الإلكتروني بنجاح",
  content_body: "نص المحتوى مفقود",
  not_sent_email: "لم يتم إرسال البريد الإلكتروني",
  no_user_found: "لم يتم العثور على المستخدم",
  profile_update: "تحديث الملف الشخصي",
  req_sent: "تم ارسال الطلب",
  req_to_publish: "طلب للنشر",
  no_req: "لا يوجد طلب",
  akunah_global_public: "Akunah Global Public",
  akunah_global_patient: "Akunah Global Patient",
  enter_org_name: "الرجاء إدخال اسم المنظمة",
  select_a_plan: "الرجاء تحديد خطة",
  Plan: "يخطط",
  admin_deleted: "المسؤول حذف",
  email_added: "تمت إضافة البريد الإلكتروني",
  email_not_added: "البريد الإلكتروني غير مضاف",
  plan_not_added: "الخطة غير مضافة",
  hcp_not_delete: "HCP غير محذوف",
  hcp_approved: "تمت الموافقة على HCP بنجاح",
  hcp_reject: "تم رفض HCP",
  channel_updated: "تحديث القناة",
  channel_deleted: "تم حذف القناة بنجاح",
  limit_error: "يجب أن يكون الحد أكبر من 0",
  days: "أيام",
  gb: "GB",
  plan_created: "تم إنشاء الخطة",
  rejected: "مرفوض",
  expired: "منتهي الصلاحية",
  search_by_hcp_name: "البحث عن طريق HCP",


  name_already_present: "الاسم موجود بالفعل",
  already_req_hcp: "لقد طلبت بالفعل إلى HCP هذا",
  already_req_org: "لقد طلبت بالفعل إلى هذه المنظمة",


  video_not_delete: "لم يتم حذف الفيديو",
  video_updated_success: "تم تحديث الفيديو بنجاح",


  Doctor: "طبيب",
  Patient: "مريض",
  favorite: "مفضل",
  unfavourite:"غير مفضل",


  admin: "مسؤل",
  org_admin: "إدارة المنظمة",

  
video_upload_success:"Video Uploaded Successfully",//Not Done
live_event_create_success:"Live Event Create Successfully", //Not Done



privacy: "خصوصيتك بالنقر فوق 'قبول' جميع ملفات تعريف الارتباط ، فأنت توافق على أن akunah Med يمكنه تخزين ملفات تعريف الارتباط على جهازك والكشف عن المعلومات وفقًا لموقعنا.",
cookie: "سياسة ملفات الارتباط",


my_profiles:"ملفاتي",

not_found_recent:"لم يتم العثور على مقاطع فيديو حديثة"




};