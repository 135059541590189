import React, { useEffect } from "react";
import { useState } from "react";
// import { useEffect } from "react";
// import instance from '../axiosinstance';
import searchCloseIconGrey from "./image/search-close-grey.svg";
import searchGreyIcon from "./image/search-grey.svg";
import searchBlueIcon from "./image/search-blue.svg";
import searchCloseIcon from "./image/search-close.svg";
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useNavigate } from "react-router-dom";
// import HttpService from "../services/HttpService";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
// import { setGlobalArr } from "../actions/globalSearchAction";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';




export default function Search() {
  // const dispatch = useDispatch();
  // const { globalSearch } = useSelector(
  //   (state) => state.mainReducer.globalsearchData
  // );
  // const [searchInput, setSearchInput] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [noteSearch, setNoteSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams({});
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get("search");
  const search = queryParams.get("q");
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(globalSearch.length===0){
  //   .post(`globalsearch`, { keyword: searchInput  }).then((response)=>{

  //       if (response) {
  //       //   setShow(true);
  //       dispatch(setGlobalArr(response.data.data));
  //       } else {
  //       //   setShow(false);
  //       }
  //     });
  //   }

  // }, []);

  // const handleOnSearch = (string, results) => {
  //   // onSearch will have as the first callback parameter
  //   // the string searched and for the second the results.
  //   // console.log(string, results)
  // }

  // const handleOnHover = (result) => {
  //   // the item hovered
  //   // console.log(result)
  // }

  // const handleOnSelect = (item) => {
  //   // the item selected
  //   const id = btoa(item.id);
  //   navigate(`/search?q=${item.name}&id=${id}`)
  //   // console.log(item)
  // }

  // const handleOnFocus = () => {
  //   //console.log('Focused')
  // }

  // const formatResult = (item) => {
  //   // console.log('entry in format result');
  //   return (
  //     <>
  //       <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
  //     </>
  //   )
  // }
  useEffect(()=>{
    setNoteSearch(search)
  },[search])

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // setSearchParams({ q: noteSearch, search: "Y" });
    setNoteSearch(search)
    navigate(`/search?q=${noteSearch}&search=Y`);
  };
  const noteSearchChange = (e) => {
    setNoteSearch(e.target.value);
  };
  const handelClick = () => {
    // navigate("/patient-list");
  };
  const searchClose = () => {
    // alert(1);
    setNoteSearch("");
    setSearchParams();
    // navigate(`/`);
  };
  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };


  return (
    <div className="">
      <div className="form-group has-search">
        <div className="nav_inner_search">
          {/* <Search></Search> */}
          <form onSubmit={handleFormSubmit}>
            <div className="search-box">
              <div
                className={`input-group ${
                  searchActive === true ? "search_active" : ""
                }`}
              >
                <span className="input-group-addon first">
                  <img
                    className={`search_grey_icon`}
                    src={searchGreyIcon}
                    alt=""
                  />
                  <img
                    className={`search_blue_icon`}
                    src={searchBlueIcon}
                    alt=""
                  />
                </span>

                <input
                  value={noteSearch}
                  type="text"
                  placeholder={getTranslation('search')}
                  onFocus={(e) => {
                    setSearchActive(true);
                  }}
                  onBlur={(e) => {
                    setSearchActive(false);
                  }}
                  onChange={noteSearchChange}
                  className="form-control input_search"
                  onClick={() => handelClick()}
                />
                <span
                  className="input-group-addon first"
                  onClick={() => searchClose()}
                >
                  <span onClick={() => searchClose()} className="c_pointer">
                    <img
                      className={``} //search_close_icon
                      src={searchCloseIconGrey}
                      alt=""
                    />
                    <img
                      className={``} //search_close_icon
                      src={searchCloseIcon}
                      alt=""
                    />
                  </span>
                </span>
              </div>
            </div>
          </form>
        </div>
        {/* <span className="fa-solid fa-magnifying-glass form-control-feedback"></span> */}
        {/* <form>
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          name="keyword"
          value={searchInput || ""}
          onChange={handleChange}
        />
      </form> */}
        <div>
          {/* <ReactSearchAutocomplete
            items={globalSearch}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            placeholder='Search'
          /> */}
        </div>
      </div>
    </div>
  );
}
