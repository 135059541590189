import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

// import Main from "./Main";
// import TrandingPage from './TrandingPage'
// import Recently from "./RecentlyPages";
// import Upcoming from "./UpcomingPage";
// import Live from "./Live";
// import Profile from "./Profile";
// import Login from "./Login";

import Main from "../pages/Main";
import TrandingPage from "../pages/TrandingPage";
import Recently from "../pages/RecentlyPages";
import UpcomingPage from "../pages/UpcomingPage";
import Live from "../pages/Live";
import Profile from "../pages/Profile";
import Login from "../pages/Login";
import Details from "../pages/details";
import SearchPage from "../pages/SearchPage";
import Channels from "../pages/Channels";
// import RenderOnAnonymous from "./RenderOnAnonymous";
// import RenderOnAuthenticated from './RenderOnAuthenticated'
import NoMatch from "../pages/NoMatch";
import Loader from "../pages/Loader";
import Categories from "../pages/admin/Categories";
import Uploadvideo from "../pages/admin/Uploadvideo";
import PostList from "../pages/admin/PostList";
import Subscription from "../pages/admin/Subscription";
import Organization from "../pages/admin/Organization";
import ManageList from "./admin/ManageList";
import Library from "../pages/Library";
import SsoLogin from "../pages/SsoLogin";
import Notification from "../pages/admin/Notification";
import Manage from "../pages/admin/Manage";
import Plan from "../pages/admin/Plan";
import ManagePatientList from "../pages/admin/ManagePatientList";
import ManagePublicList from "../pages/admin/ManagePublicList";
import ManagePatientsOrg from "../pages/admin/ManagePatientsOrg";
import ChannelPage from "../pages/ChannelPage";
import AkunahHowTos from "../pages/AkunahHowTos";
import useAxios from "../axiosinstance";
import { useDispatch } from "react-redux";
import { setArr } from "../actions/profileAction";
import UserMap from "./UserMap";

// import ChannnelData from "./ChannnelData";
import ManageChannel from "../pages/admin/ManageChannel";
import LoginCheck from "./LoginCheck";
import DetailsPage from "../pages/DetailsPage";
import ParticularChannelPage from "../pages/ParticularChannelPage";



const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Channels /> },
    { path: "trending", element: <TrandingPage /> },
    { path: "recently", element: <Recently /> },
    { path: "upcoming", element: <UpcomingPage /> },
    { path: "how-tos", element: <AkunahHowTos /> },
    { path: "live", element: <Live /> },
    { path: "profile", element: <Profile /> },
    { path: "details/:id/:sessionid", element: <DetailsPage /> },
    { path: "home", element: <Main /> },
    { path: "search", element: <SearchPage /> },
    { path: "*", element: <NoMatch /> },
    { path: "loader", element: <Loader /> },
    { path: "library", element: <Library /> },
    { path: "sso-login", element: <SsoLogin /> },
    { path: "channels", element: <Channels /> },
    { path: "channel/:id/:title", element: <ChannelPage /> },
    // admin url---
    { path: "categories", element: <Categories /> },
    // { path: "upload-post", element: <Uploadvideo /> },
    // { path: "post-list", element: <PostList /> },
    { path: "admin-subscription", element: <Subscription /> },
    // { path: "manage-organization", element: <Organization /> },
    // { path: "manage-list/:id", element: <Manage /> },
    { path: "notification", element: <Notification /> },
    // { path: "manage-plan", element: <Plan /> },
    // { path: "manage-patient-list/:id", element: <ManagePatientList /> },
    // { path: "manage-public-list", element: <ManagePublicList /> },
    // { path: "manage-patient-lists", element: <ManagePatientsOrg /> },
    // { path: "channeldata", element: <ManageChannel /> },
    { path: "specificchannel/:id/:name", element: <ParticularChannelPage /> },
    { path: "/user-profile", element: <UserMap /> },




  ]);
  return routes;
};
const App1 = () => {
  let routes = useRoutes([

    { path: "trending", element: <TrandingPage /> },
    { path: "recently", element: <Recently /> },
    { path: "upcoming", element: <UpcomingPage /> },
    { path: "how-tos", element: <AkunahHowTos /> },
    { path: "live", element: <Live /> },
    { path: "profile", element: <Profile /> },
    { path: "details/:id/:sessionid", element: <DetailsPage /> },
    { path: "home", element: <Main /> },
    { path: "search", element: <SearchPage /> },
    { path: "*", element: <NoMatch /> },
    { path: "loader", element: <Loader /> },
    { path: "library", element: <Library /> },
    { path: "sso-login", element: <SsoLogin /> },
    { path: "channels", element: <Channels /> },
    { path: "channel/:id/:title", element: <ChannelPage /> },
    // admin url---
    { path: "categories", element: <Categories /> },
    // { path: "upload-post", element: <Uploadvideo /> },
    // { path: "post-list", element: <PostList /> },
    { path: "admin-subscription", element: <Subscription /> },
    // { path: "manage-organization", element: <Organization /> },
    // { path: "manage-list/:id", element: <Manage /> },
    { path: "notification", element: <Notification /> },
    // { path: "manage-plan", element: <Plan /> },
    // { path: "manage-patient-list/:id", element: <ManagePatientList /> },
    // { path: "manage-public-list", element: <ManagePublicList /> },
    // { path: "manage-patient-lists", element: <ManagePatientsOrg /> },
    { path: "channeldata", element: <ManageChannel /> },
    { path: "specificchannel/:id/:name", element: <ParticularChannelPage /> },


    { path: "/", element: <UserMap /> },
    { path: "/user-profile", element: <UserMap /> },
    { path: "channels", element: <Channels /> },
    { path: "*", element: <NoMatch /> },
  ]);

  return routes;
};
const App2 = () => {
  let routes = useRoutes([

    { path: "trending", element: <TrandingPage /> },
    { path: "recently", element: <Recently /> },
    { path: "upcoming", element: <UpcomingPage /> },
    { path: "how-tos", element: <AkunahHowTos /> },
    { path: "live", element: <Live /> },
    { path: "profile", element: <Profile /> },
    { path: "details/:id/:sessionid", element: <DetailsPage /> },
    { path: "home", element: <Main /> },
    { path: "search", element: <SearchPage /> },
    { path: "*", element: <NoMatch /> },
    { path: "loader", element: <Loader /> },
    { path: "library", element: <Library /> },
    { path: "sso-login", element: <SsoLogin /> },
    { path: "channels", element: <Channels /> },
    { path: "channel/:id/:title", element: <ChannelPage /> },
    // admin url---
    { path: "categories", element: <Categories /> },
    // { path: "upload-post", element: <Uploadvideo /> },
    // { path: "post-list", element: <PostList /> },
    { path: "admin-subscription", element: <Subscription /> },
    // { path: "manage-organization", element: <Organization /> },
    // { path: "manage-list/:id", element: <Manage /> },
    { path: "notification", element: <Notification /> },
    // { path: "manage-plan", element: <Plan /> },
    // { path: "manage-patient-list/:id", element: <ManagePatientList /> },
    // { path: "manage-public-list", element: <ManagePublicList /> },
    // { path: "manage-patient-lists", element: <ManagePatientsOrg /> },
    { path: "channeldata", element: <ManageChannel /> },
    { path: "specificchannel/:id/:name", element: <ParticularChannelPage /> },




    { path: "/", element: <LoginCheck /> },
    { path: "/user-profile", element: <UserMap /> },

    { path: "channels", element: <Channels /> },
    { path: "*", element: <NoMatch /> },
  ]);
  return routes;
};

const AppWrapper = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const akToken = queryParams.get("ak_id");
  // let akToken = localStorage.getItem("akTokenMed");
  console.log("akTokenakToken--route page",akToken)

  const axiosinstance = useAxios();
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosinstance.current.post(`sign`);
        setUserData(response.data);
      } catch (error) {
        // setMessage(error.message);
        // setMessageType("error");
        // console.log(error.message);
      }
    };
    getData();
  }, []);




  const GetResult = () => {
    axiosinstance.current.get(`getUser?ak_id=${akToken}`).then((response) => {
      if (response) {
        dispatch(setArr(response.data));
      }
    });
  };
  useEffect(() => {
    if (akToken) {
      GetResult();
    }
  }, []);


  useEffect(() => {
    if(akToken){
    localStorage.setItem("akTokenMed",window.atob(akToken));
    }
  }, [akToken])
  return (
    <>
      {userData.isEmail && akToken === null && (
        <Router>
          <App1 />
        </Router>
      )}
      {userData.isEmail && akToken !== null && (
        <Router>
          <App />
        </Router>
      )}

      {userData.isEmail === false && (
        <Router>
          <App2 />{" "}
        </Router>
      )}
      {/* <Router>{akToken !== null ? <App /> : <App1 />}</Router>; */}
    </>
  );
};

export default AppWrapper;
