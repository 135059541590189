import React, { useEffect } from 'react';
import Header from '../../Component/Header'
import Sidebar from '../../Component/Sidebar'
import { useSelector } from 'react-redux';
import Tab from '../../Component/admin/Tab';
import Footer from '../../Component/Footer';
import appStyle from '../../config/appStyle';
import ManagePlan from '../../Component/admin/ManagePlan';
import ChannnelData from '../../Component/ChannnelData';



const ManageChannel = () => {
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  document.title = 'Akunah Med || Manage-Plan';
  return (
    <>
      <Header /> 
      <main className="mt-4" dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2} >
              <Sidebar></Sidebar>

            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>

              <Tab />

              <ChannnelData />

              <Footer />

            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ManageChannel
