import React, { useState, useEffect } from "react";
import HttpService from "../../services/HttpService";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import useAxios from "../../axiosinstance";
const SubscriptionList = () => {
  const axiosinstance = useAxios()
  const [plans, setPlans] = useState([]);
  const [limit, setLimits] = useState(10);
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [setselected, setSelectedPage] = useState(0);
  const [plantype, setPlantype] = useState("");
  const [quantity, setQuantity] = useState("");
  const [plantypelist, setPlantypelist] = useState("");
  const [planlist, setPlanlist] = useState([]);
  const [refrash, setDateRefrash] = useState(false);
  useEffect(() => {
    axiosinstance.current.get("plans").then((response) => {
      setPlans(response.data);
    });
  }, []);
  function handlePageClick({ selected: selectedPage }) {
    setSelectedPage(selectedPage);
   
  }
  // let s = 1;
  // const [post, setPost] = useState([]);
function setLimit(e){
  setLimits(e)
  setSelectedPage(0)
}
  const submit = () => {
    setDateRefrash(false)
    if(quantity=="0" || quantity==""){
      toast.warning('No such Quantity !!')
    }else {
      const json = JSON.stringify({
        id: plantype,
        amount: quantity,
      });
      axiosinstance.current
        .post("subscription", json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          toast.success(response.data.message);
          setDateRefrash(true);
        })
        .catch((error) => {
          
          toast.warning(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    axiosinstance.current
      .get(`subscription/getList?limit=${limit}&page=${setselected}&status=${status}&planId=${plantypelist}`)
      .then((response) => {
        if (response) {
          setPlanlist(response.data);
          setShow(true);
        }
      });
  }, [limit,setselected,status,plantypelist ,refrash]);
  
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} />
      <div className="upcoming-head mt-2">
        {/* <h4>Manage Post</h4> */}
        <hr />
      </div>
      <div className="row">
        <div className="col-xxl-5 col-lg-5 col-md-5 col-12">
          <div className="">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Create Key</h4>
                <hr />
                <div className="row justify-content-right">
                  <div className="row g-3  mt-2">
                    <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                      <label
                        htmlFor="name"
                        className="col-form-label for-label "
                      >
                        Plan Type:{" "}
                      </label>
                    </div>
                    <div className="col-xxl-8 col-lg-8 col-md-8 col-sm-8 col-8 for-input">
                      <select
                        className="form-control"
                        onChange={(e) => setPlantype(e.target.value)}
                      >
                        <option value=" ">Select plan</option>
                        {plans.map((plan) => (
                          <option value={plan.id} key={plan.id}>
                            {plan.plan_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row g-3  mt-2">
                    <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                      <label
                        htmlFor="name"
                        className="col-form-label for-label "
                      >
                        Quantity:{" "}
                      </label>
                    </div>
                    <div className="col-xxl-8 col-lg-8 col-md-8 col-sm-8 col-8 for-input">
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setQuantity(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="row justify-content-right">
                    <div className="col-12 ">
                      <div className="float-end mt-4">
                        <button
                          className="btn premium-btn"
                          onClick={() => submit()}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-7 col-lg-7 col-md-7 col-12">
          <div className="">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Subscription List</h4>
                <hr />
                <div
                  className="row  align-items-center"
                  style={{
                    border: "1px solid #c7c7c78f ",
                    padding: "7px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "#EB7C3C",
                  }}
                >
                  <div className="col-md-auto col-6">
                    <label htmlFor="inputPassword6" className="col-form-label">
                      Limit
                    </label>
                  </div>
                  <div className="col-md-auto col-6">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) => setLimit(event.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                  <div className="col-md-auto col-6">
                    <label htmlFor="inputPassword6" className="col-form-label">
                      Plan Type
                    </label>
                  </div>
                  <div className="col-md-auto col-6">
                    <select
                      className="form-select"
                      onChange={(e) => setPlantypelist(e.target.value)}
                    >
                      <option value=" ">Select plan</option>
                      {plans.map((plan) => (
                        <option value={plan.id} key={plan.id}>
                          {plan.plan_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-auto col-6">
                    <label htmlFor="inputPassword6" className="col-form-label">
                      Status
                    </label>
                  </div>
                  <div className="col-md-auto col-6">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <option value={''}>Select</option>

                      <option value={0}>Active</option>
                      <option value={1}>Deactive</option>
                    </select>
                  </div>
                </div>
                <div className="table-responsive">

                <table className="table table-hover table-striped table-bordered  mt-3">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Plan</th>
                      <th>Key</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!show && <div>Loading....</div>}
                    {show &&
                      planlist.data.map((post) => (
                        <tr key={post.id}>
                          <td>{post.id}</td>
                          <td>{post.plan_name}</td>
                          <td> {post.key}</td>
                          <td>
                            {post.status === 0 ? (
                              <span className="text-success">Active</span>
                            ) : (
                              <span className="text-danger">Deactive</span>
                            )}{" "}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                </div>
                <nav
                  aria-label="Page navigation example"
                  style={{ display: "flex", "justify-content": "center" }}
                >
                  <ReactPaginate
                    nextLabel={"Next →"}
                    pageCount={planlist.total_pages}
                    onPageChange={handlePageClick}
                    previousLabel={"← Previous"}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active "
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubscriptionList;
