import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Routess from "./Component/Routess";
import App from "./App";
import UserService from "./services/UserService";
import HttpService from "./services/HttpService";
import "react-loading-skeleton/dist/skeleton.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { mainReducer } from "./reducer";
import keycloak from "./Keycloak";
import LanguageContextProvider from "./Component/contexts/LanguageContext";
import { ReactKeycloakProvider } from "@react-keycloak/web";

const store = configureStore({
  reducer: {
    mainReducer: mainReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
  <ReactKeycloakProvider
    initOptions={{ onLoad: 'login-required', checkLoginIframe: false }} authClient={keycloak}
  >
  <React.StrictMode>
    <LanguageContextProvider>
      <Suspense fallback={<div className="text-center">Loading...</div>}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </LanguageContextProvider>
  </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
// UserService.initKeycloak(root);
// HttpService.configure();
