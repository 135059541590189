import React, { useState, useEffect, useRef } from 'react'
import Modal from "react-modal";
import HttpService from "../services/HttpService";
import { useSelector } from "react-redux/es/exports";
import { toast } from "react-toastify";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


// import { Validators } from '../utils/Validator';
import useAxios from '../axiosinstance';
const HelpDesk = ({ type }) => {
    const [isOpen, setIsOpen] = useState(false);
    const axiosinstance = useAxios()
    const [title, setTile] = useState("")
    const [subj, setSubj] = useState("")
    const [errortitle, setFormerrortitle] = useState({})
    const [errorsubj, setFormerrorsubj] = useState({})
    const[disble,setDisble]=useState(false)
    const { profile } = useSelector((state) => state.mainReducer.profileData);


    const toggleModal = () => {
        setIsOpen(false);
        setSubj("")
        setTile("")
        setFormerrortitle([])
        setFormerrorsubj([])
    }

    useEffect(() => {
        if (title.length > 2) {
            setFormerrortitle([])
        }

    }, [title])


    useEffect(() => {
        if (subj.length > 2) {
            setFormerrorsubj([])
        }

    }, [subj])


    const validateForm = () => {
        let err = {}
        let flag = false
        if (title.length == 0) {
            errortitle.title = "Subject is missing"
        }
        if (subj.length == 0) {
            errorsubj.subj = "Message is missing"
        }

        setFormerrortitle({ ...errortitle, errortitle })
        setFormerrorsubj({ ...errorsubj, errorsubj })

        
        if (Object.keys(errortitle).length == 0 || Object.keys(errorsubj).length == 0) {
            flag = true
        } else {
            flag = false
        }

        return flag


    }



    const sendMail = () => {
        let isValid = validateForm()
        if (isValid) {
            setDisble(true)
            const json = {
                content: subj,
                subject: title,
                email: profile[0]?.email
                // from: "noreply@akunah.com"
            }
            axiosinstance.current
                .post(`request_to_create_organization?ak_id=${profile[0].id}`, json, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    toast.success(res.data.message);
                    setSubj("")
                    setTile("")
                    setDisble(false)
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                });
               
        } else {
            // toast.error("Check the Fields")
            console.log("Check the Fields")
        }



    }

    const getTranslation = (key) => {
        const translations =
          profile[0]?.language === "en"
            ? en
            : profile[0]?.language === "ger"
              ? ger
              : profile[0]?.language === "ita"
                ? ita
                : profile[0]?.language === "ara"
                  ? ara
                  : profile[0]?.language === "spa"
                    ? spa
                    : profile[0]?.language === "tha" ? tha
                      : profile[0]?.language === "kor" ? kor
                        : profile[0]?.language === "ch" ? chn : fr
        return translations[key] || key;
      };
    
    
    return (
        <>
            {
                type === "1" && (
                    <div className="lang_ d-block hellppp">
                        <img src="/Assets/image/callingimage.png" className="help-border image-sizeee" onClick={() => setIsOpen(true)} data-bs-placement="bottom" title={getTranslation("help")} />

                        <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            contentLabel="My dialog"
                            className="mymodal helpdesk-modal"
                            overlayClassName="myoverlay"
                        >
                            <div>
                                <button
                                    className="close"
                                    style={{ borderWidth: 0 }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={toggleModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="modal-header pt-0">
                                    <h5 className="modal-title fw-600">{getTranslation('help')}</h5>
                                </div>
                                <div className="modal-body">

                                    <div className="row g-3 justify-content-center">
                                        <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 for-input">
                                            <label className='fs-12'>
                                                {getTranslation('subject')}*
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="first_name"

                                                className="form-control "
                                                onChange={(e) => setTile(e.target.value)}
                                                value={title}
                                            />
                                            <span className="error-color">{errortitle.title}</span>
                                        </div>

                                    </div>
                                    <div className="row g-3  mt-2 justify-content-center">

                                        <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 for-input">
                                            <label
                                                className='fs-12'
                                            >
                                                {getTranslation('message')}*
                                            </label>
                                            <textarea

                                                type="text"
                                                id="name"
                                                name="first_name"

                                                className="form-control inputt"
                                                onChange={(e) => setSubj(e.target.value)}
                                                value={subj}

                                            ></textarea>
                                            <span className="error-color">{errorsubj.subj}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer footers">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={toggleModal}
                                            className="btn premium-btn btn_org_upload new-btn"
                                        >
                                            {getTranslation('cancel')}
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={sendMail}
                                            disabled={disble} 
                                            className="btn premium-btn btn_org_upload new-btn"
                                        >
                                            {getTranslation('send')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal>


                    </div>
                )
            }
            {
                type==="2" && (
                    <div className="">
                    <img src="/Assets/image/account.svg" className="help-border image-sizeee" onClick={() => setIsOpen(true)} data-bs-placement="bottom" title={getTranslation("help")} />
                    <span>Help Desk</span>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={toggleModal}
                        contentLabel="My dialog"
                        className="mymodal helpdesk-modal"
                        overlayClassName="myoverlay"
                    >
                        <div>
                            <button
                                className="close"
                                style={{ borderWidth: 0 }}
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={toggleModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="modal-header pt-0">
                                <h5 className="modal-title fw-600">{getTranslation('help')}</h5>
                            </div>
                            <div className="modal-body">
    
                                <div className="row g-3 justify-content-center">
                                    <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 for-input">
                                        <label className='fs-12'>
                                        {getTranslation('subject')}*
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="first_name"
    
                                            className="form-control "
                                            onChange={(e) => setTile(e.target.value)}
                                            value={title}
                                        />
                                        <span className="error-color">{errortitle.title}</span>
                                    </div>
    
                                </div>
                                <div className="row g-3  mt-2 justify-content-center">
    
                                    <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 for-input">
                                        <label
                                            className='fs-12'
                                        >
                                            {getTranslation('message')}*
                                        </label>
                                        <textarea
    
                                            type="text"
                                            id="name"
                                            name="first_name"
    
                                            className="form-control inputt"
                                            onChange={(e) => setSubj(e.target.value)}
                                            value={subj}
    
                                        ></textarea>
                                        <span className="error-color">{errorsubj.subj}</span>
                                    </div>
                                </div>
    
                            </div>
                            <div className="modal-footer footers">
                                <div>
                                    <button
                                        type="button"
                                        onClick={toggleModal}
                                        className="btn premium-btn btn_org_upload new-btn"
                                    >
                                        {getTranslation('cancel')}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        onClick={sendMail}
                                        disabled={disble}
                                        className="btn premium-btn btn_org_upload new-btn"
                                    >
                                        {getTranslation('send')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
    
    
                </div>
                )
            }


        </>
    )
}

export default HelpDesk
