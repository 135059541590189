import React from "react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { setArrTre } from "../actions/trendingAction";
import SmallSkeleton from "./smallSkeleton";
// import instance from "../axiosinstance";
import PostItemSmall from "./PostItemSmall";
import appConfig from "../config/appUrlConfig";
import Categories from "./Categories";
import typeConfig from "../config/typeConfig";
import HttpService from "../services/HttpService";
import useTranslation from "./customHooks/translations";
import useAxios from "../axiosinstance";
import { setArr } from "../actions/profileAction";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


const start = 16;
export default function TrendingList() {
  const translation = useTranslation();
const axiosinstance =useAxios()
  const queryParams = new URLSearchParams(window.location.search);
  const start_date = queryParams.get("start");
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  const end_date = queryParams.get("end");
  let dStartDate = " ";
  let denddate = " ";
  if (start_date) {
    dStartDate = atob(start_date);
  }
  if (end_date) {
    denddate = atob(end_date);
  }

  const [startDate, setStartDate] = useState(dStartDate);
  const [endDate, setendDate] = useState(denddate);
  const [loader1, setloader1] = useState(false);

  let typeID = typeConfig.trendigID;
  const dispatch = useDispatch();
  const { arr } = useSelector((state) => state.mainReducer.trendingdata);
  const [check, setcheck] = useState(arr.length);
  const [limit, setLimit] = useState(16);
  const [loop, setLoop] = useState(false);

  const [categoryid, setCategoryid] = useState("");
  function handleChange(event) {
    setStartDate(event.target.value);
  }
  function handleChangeEnd(event) {
    setendDate(event.target.value);
  }


  const [show, setShow] = useState(false);
  useEffect(() => {
    if (
      arr.length === 0 ||
      categoryid !== "" ||
      startDate !== " " ||
      endDate !== " "
    ) {
      axiosinstance.current
        .get(
          `search?limit=${start}&category=${categoryid}&type=${""}&sDate=${startDate}&eDate=${endDate}&ak_id=${profile[0]?.id}`
        )
        .then((response) => {
          if (response.status===200) {
            setloader1(true)
            setShow(true);
            // console.log("first", response.data.data);
            // setPost(response.data.results);
            
            dispatch(setArrTre(response.data.data));
            setcheck(response.data.data.length);
          } else {
            setShow(false);
          }
        }).catch((err)=>{
        
        });
    }
    if (arr) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [categoryid, startDate, endDate]);

  const getData = (data) => {
    setCategoryid(data);
  };
  // console.log(arr)
  const fetchData = () => {
    
    setLimit(limit + 16);
    if (loop === false) {
      axiosinstance.current
        .get(
          `search?limit=${start}&category=${categoryid}&type=${""}&offset=${limit}&sDate=${startDate}&eDate=${endDate}&ak_id=${profile[0]?.id}`
        )
        .then((response) => {
          let res = response.data.data;
          if (res.length === 0) {
            setLoop(true);
          } else {
            setloader1(true)
            dispatch(setArrTre(arr.concat(res)));
            
          }
        }).catch((err)=>{
          
        });;
    }
  };


  // const GetResult = () => {
  //   if(profile.length>0){
  //     axiosinstance.current.get(`getUser?ak_id=${profile[0]?.id}`).then((response) => {
  //       if (response) {
  //         dispatch(setArr(response.data));
  //       }
  //     });
  //   }
    
  // };
  // useEffect(() => {
  //     GetResult();
  // }, []);

  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };

  // console.log(arr)
  return (
    <>
      <Categories onSubmit={getData} />
      <div className="clearfix"></div>
      <div className="trending">
        <div className="upcoming-head filter">
          <h4 className="trending-page">{getTranslation("trending")}</h4>
          <p className="filter fill" dir={profile[0]?.language === 'ara' ? "ltr" : 'rtl'} data-bs-toggle="dropdown">
            <img src="Assets/image/filter_icon.svg" alt="filter" />
            {getTranslation("filter")}
          </p>
          <ul className="filter dropdown-menu p-2 arrow_top">
            <li className="m-2">
              {getTranslation("start_date")} :{" "}
              <span className="filter_date">
                <input
                  onChange={handleChange}
                  type="date"
                  max={appConfig.new_date}
                  value={!startDate ? setStartDate(" ") : startDate}
                  className="date-hover"
                />
              </span>{" "}
            </li>
            <li className="m-3 mt-4">
              <span style={{marginLeft:"-4px"}}>{getTranslation("end_date")} :{" "}</span>
              <span className="filter_date">
                <input
                  onChange={handleChangeEnd}
                  type="date"
                  max={appConfig.new_date}
                  value={!endDate ? setendDate(" ") : endDate}
                  className="date-hover"
                />
              </span>{" "}
            </li>
          </ul>
        </div>
        <div className="trendig-box">
          <InfiniteScroll
            dataLength={arr.length}
            next={fetchData}
            hasMore={true}
            loader={
             <>
              {!loader1 ? <div className="no_data">
              {/* <h2>{getTranslation('loading')}</h2> */}
              <SmallSkeleton></SmallSkeleton>
            </div>:(
              arr.length===0 ? (
                <div className="no_data">
              <h2>{getTranslation('not_found_trending')}</h2>
            </div>
              ):""
            )}
             </>
            }
          >
            <div className="row">
              {!show && <SmallSkeleton></SmallSkeleton>}
              {show &&
                arr.map((post) => (
                  <div
                    className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4"
                    key={post.id}
                  >
                    <PostItemSmall
                      id={post.id}
                      title={post.title}
                      posted_by={post.posted_by}
                      videoKey={post.video_key}
                      duration={post.duration}
                      large={post.large}
                      medium={post.medium}
                      views={post.views}
                      description={post.description}
                      data={post.date}
                      category={post.category_id}
                      featured={post.featured}
                      ap={post.akunah_public}
                      ae={post.akunah_exc}
                      op={post.org_public}
                      oe={post.org_exc}
                      hp={post.hcp_public}
                      he={post.hcp_exc}
                      sessionId={post.sessionId}
                      profile={post.profile}
                      link={post.link}
                      type={post.type}
                    />
                  </div>
                ))}
            </div>
          </InfiniteScroll>
{/* 
          {check === 0 && (
            <div className="no_data">
              <h2>{getTranslation('not_found_trending')}</h2>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
