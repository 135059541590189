import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// import instance from '../axiosinstance';
import HttpService from "../services/HttpService";
import { setArr } from "../actions/profileAction";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
// import HttpService from "../services/HttpService";
// import axios from "axios";
import ProfilePic from "./ProfilePic";
import useTranslation from "./customHooks/translations";
import Modal from "react-modal";
import Select from "react-select";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useAxios from "../axiosinstance";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';





function ProfileData() {
  const axiosinstance = useAxios()
  let s = 1;
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const org_channel = useSelector((state) => state.mainReducer.channelList.org_channel);
  const [show, setShow] = useState(false);
  const [upshow, setUpshow] = useState(false);
  const [first_name, setFname] = useState("");
  const [last_name, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [phone_no, setPhoneno] = useState("");
  const [edit, setEdit] = useState(true);
  const [key, setkey] = useState("");
  const [exp, setExpire] = useState("");
  const [status, setBool] = useState("");
  const [ref, setref] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenorg, setIsOpenorg] = useState(false);
  const [selected, setSelected] = useState({});
  const [list, setList] = useState([]);
  const [listorg, setListOrg] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [plist, setPlist] = useState([]);
  const [getList, setgetList] = useState([]);
  const [role, setRole] = useState("");
  const [org, setOrg] = useState("");
  const [totalHcp, setTotalHcp] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [docreqorglist, setDocReqOrgList] = useState([])
  const [keyword, setKeyword] = useState("")
  const [enable, setEnable] = useState(true)
  const [enable1, setEnable1] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [inputValue1, setInputValue1] = useState('');

  const [arr2, setArr2] = useState([])
  const [orgId, setOrgId] = useState("")
  const [selectedOrg, setSelectedOrg] = useState("")
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const [lastTypingTime1, setLastTypingTime1] = useState(null);

  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (profile.length !== 0) {
      setRole(profile[0].role_value);
      setOrg(profile[0].org_id);
    }
  }, [profile]);
  const Edites = () => {
    if (edit) {
      setEdit(false);
      setUpshow(true);
    } else {
      setEdit(true);
      setUpshow(false);
    }
  };

  function handleChange(event) {
    // console.log(event.target.value);
    setFname(event.target.value);
  }
  function handleChangeLname(event) {
    // console.log(event.target.value);
    setLname(event.target.value);
  }
  function handleChangeAddress(event) {
    // console.log(event.target.value);
    setAddress(event.target.value);
  }
  function handleChangePhone(event) {
    // console.log(event.target.value);
    if (event.target.value) {
      setPhoneno(event.target.value.slice(0, 13).replace(/[^0-9]/g, ""));
    } else {
      setPhoneno("");
    }
  }

  function handleChangeDob(event) {
    // console.log(event.target.value);
    setDob(event.target.value);
  }




  let akToken = localStorage.getItem("akTokenMed");
  const GetResult = () => {
    axiosinstance.current.get(`getUser?ak_id=${akToken}`).then((response) => {
      if (response) {
        setShow(true);
        dispatch(setArr(response.data));
        setFname(response.data.first_name);
        setLname(response.data.last_name);
        setAddress(response.data.address);
        setDob(response.data.dob === "2000-01-01T00:00:00.000Z" ? "" : response.data.dob);
        setPhoneno(response.data.phone_no);
        setEmail(response.data.email);
        // setkey(response.data.subscription_key);
        // console.log(response.data)
        setExpire(response.data.expiry);
        setBool(response.data.bool);
        setOrgName(response.data.org_name);
      }
    });
  };




  useEffect(() => {
    GetResult();
  }, []);

  const Submit = () => {
    setEnable1(true)
    const json = JSON.stringify({
      phone_no,
      first_name,
      address,
      last_name,
      dob: dob.length === 0 ? "0" : dob,
    });
    axiosinstance.current.put(`updateUser?ak_id=${akToken}`, json, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    setUpshow(false);
    setEdit(true);
    // dispatch(setArr())
    toast.success("Profile  Update !");
  };

  function toggleModal() {
    setIsOpen(false);
    setList([])
    setOrgName("")
  }
  function toggleModalorg() {
    setIsOpenorg(false);
    setList([])
    setOrgName("")
    setSelectedOrg("")

  }

  const saveHCP = () => {
    const json = JSON.stringify({
      hcp_id: selected.id,
    });
    axiosinstance.current
      .post(`requestToHcp?ak_id=${profile[0]?.id}&ak_dc=${orgName}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setref(res);
        setList([])
        setOrgName("")
        setSelectedOrg("")
        toast.success(getTranslation("req_sent"));
        toggleModal();
        setref(res);
      })
      .catch((error) => {
        // console.log("errorrrrrrrrrr",error.response.data.data)
        setList([])
        setOrgName("")
        setSelectedOrg("")

        toast.error(getTranslation("already_req_hcp"));
        toggleModal();
      });
  };

  const saveOrg = () => {
    setEnable(true)
    const json = JSON.stringify({
      org_id: selected.org_id,
    });
    axiosinstance.current
      .post(`requestToOrg?ak_id=${profile[0]?.id}&ak_ou=${orgName}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data.message);
        toast.success(getTranslation("req_sent"));
        setRefresh(true)
        setList([])
        setOrgName("")
        setSelectedOrg("")
        toggleModalorg()
        setref(res);
        setTimeout(() => {
          setRefresh(false)
        }, 2000);
      })
      .catch((error) => {
        setRefresh(true)
        setList([])
        setOrgName("")
        setSelectedOrg("")
        toast.error(getTranslation("already_req_org"));
        toggleModalorg()
        setTimeout(() => {
          setRefresh(false)
        }, 2000);
      });
  };



  let o = 1;


  useEffect(() => {
    if (profile[0]?.role_value === 0) {
      axiosinstance.current.get(`listOfPatientReqToOrgAdmin?ak_id=${profile[0].id}`).then((res) => {
        if (res) {
          setOrgList(res.data.data);
          setDocReqOrgList(res.data.data)
        }
      });
    }

  }, [profile, ref]);



  useEffect(() => {
    // if (role == 40) {
    if (profile[0]?.role_value == 0) {
      axiosinstance.current.get(`listOfPatientReqToHcp?ak_id=${profile[0].id}`).then((res) => {
        if (res) {
          setPlist(res.data.data);
        }
      });
    }

    // }
  }, [role, ref]);

  let i = 1;


  useEffect(() => {
    if (role === 70) {
      axiosinstance.current.get(`orgPlan/${orgId}`).then((response) => {
        // console.log(response.data);
        setgetList(response.data.result);
        setTotalHcp(response.data);
      });
    }
  }, [profile, orgId]);





  useEffect(() => {
    if (first_name.length === 0 || last_name.length === 0) {
      setEnable1(true)
    } else {
      setEnable1(false)

    }
  }, [enable1, first_name, last_name])



  const getAllHcp = async (value) => {
    try {
      const response = await axiosinstance.current.get(
        `listhcp?keyword=${value}`
      );
      // setItemss(response.data.data)
      const options = response.data.data.map((item) => {
        return {
          value: item.ak_id,
          label: item.name,
        };
      });

      setList(options);
    } catch (error) {
      setList([]);
    }
  };



  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        // Make your API call here using the inputValue
        if (inputValue.length > 1 || inputValue.length === 0) {
          getAllHcp(inputValue);
        } else {
          // setList([]);
        }

      }, 2000); // Adjust the delay (in milliseconds) as needed

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }
  }, [lastTypingTime, inputValue]);


  const handleHcpChange = (event, newValue) => {
    // setorg(newValue === null ? "" : newValue.aKou);
    setOrgName(newValue === null ? "" : newValue.value);
    setSelectedOrg(newValue === null ? "" : newValue.label)
    setEnable(false)
  };



  const handleOnSearch = (value, results) => {
    setLastTypingTime(new Date().getTime());
    setInputValue(value);
    setEnable(true)
    // setItemss([])

  };

  useEffect(() => {
    if (inputValue === "") {
      setInputValue("")
      setEnable(true)

    }
  }, [inputValue]);




  const getAllOrg = async (value) => {
    try {
      const response = await axiosinstance.current.get(
        `orglist?keyword=${value}`
      );
      // setItemss(response.data.data)
      const options = response.data.result.map((item) => {
        return {
          value: item.org_id,
          label: item.lavel,
        };
      });

      setList(options);
    } catch (error) {
      setList([]);
    }
  };

  useEffect(() => {
    if (lastTypingTime1) {
      const timer = setTimeout(() => {
        // Make your API call here using the inputValue
        if (inputValue1.length > 1 || inputValue1.length === 0) {
          getAllOrg(inputValue1);
        } else {
          // setList([]);
        }

      }, 2000); // Adjust the delay (in milliseconds) as needed

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }
  }, [lastTypingTime1, inputValue1]);





  const handleOrgChange = (event, newValue) => {
    // setorg(newValue === null ? "" : newValue.aKou);
    setOrgName(newValue === null ? "" : newValue.value);
    setSelectedOrg(newValue === null ? "" : newValue.label)
    setEnable(false)
  };



  const handleOnOrgSearch = (value, results) => {
    setLastTypingTime1(new Date().getTime());
    setInputValue1(value);
    setEnable(true)
    // setItemss([])

  };


  useEffect(() => {
    if (inputValue1 === "") {
      setInputValue1("")
      setEnable(true)

    }
  }, [inputValue1]);





  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };


  const result1 = () => {

    axiosinstance.current.get(`requested_hcp?ak_id=${akToken}`).then((response) => {
      // console.log(response.data);
      setDocReqOrgList(response.data.data);
    });
  }


  useEffect(() => {
    if (profile[0]?.role_value === 40) {
      result1()
    }

  }, [refresh])





  return (
    <div className="row">
      <div className="col-xl-7 col-md-7 col-sm-12 col-12">
        <div className="profile">
          <div className="profile_heading d-flex">
            <h2>{getTranslation('profile')}</h2>
            {/* <button className="btn ms-auto " onClick={() => Edites()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square me-1"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>{" "}
              {translation.edit_profile}
            </button> */}
          </div>

          <div className="row">
            {!show && (
              <div>
                <div className="col-12">
                  <div className="row g-3  mt-2">
                    <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                      <Skeleton height={40} />
                    </div>
                    <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4 for-input">
                      <Skeleton circle={true} height={100} />
                    </div>

                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <Skeleton height={40} />
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <Skeleton height={40} />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <Skeleton height={40} />
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <Skeleton height={40} />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <Skeleton height={40} />
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <Skeleton height={40} />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <Skeleton height={40} />
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <Skeleton height={40} />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <Skeleton height={40} />
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <Skeleton height={40} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {show && (
              // <div className="col-12" key={arr[0].sub_id}>
              <div className="col-12">
                <div className="">
                  <div className="">
                    <ProfilePic
                      image={profile[0].profile_pic}
                      email={profile[0].email}
                    ></ProfilePic>
                    {/* <button className="btn upload-btn" onClick={() => Edites()}>
                      Edit Profile{" "}
                    </button> */}
                    {role === 70 && (
                      <div className="row g-3  mt-2">
                        <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                          <label
                            htmlFor="name1"
                            className="col-form-label for-label"
                          >
                            {getTranslation('org_admin')}:{" "}
                          </label>
                        </div>
                        <div className="col-xxl-7 col-lg-7 col-md-7 col-sm-7 col-8 for-input">
                          <label htmlFor="name1"
                            className="col-form-label for-label"> {first_name} {" "} {last_name} ({profile[0]?.id})</label>
                        </div>
                      </div>
                    )}

                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <label
                          htmlFor="name"
                          className="col-form-label for-label"
                        >
                          {getTranslation('first_name')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <input
                          type="text"
                          id="name"
                          name="first_name"
                          className="form-control "
                          onChange={handleChange}
                          readOnly={edit}
                          value={first_name}
                        />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <label
                          htmlFor="name"
                          className="col-form-label for-label"
                        >
                          {getTranslation('last_name')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <input
                          type="text"
                          id="name"
                          name="last_name"
                          className="form-control "
                          value={last_name}
                          readOnly={edit}
                          onChange={handleChangeLname}
                        />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <label
                          htmlFor="name"
                          className="col-form-label for-label"
                        >
                          {getTranslation('date_of_birth')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <input
                          type="date"
                          id="name"
                          className="form-control "
                          value={dob.substring(0, 10)}
                          readOnly={edit}
                          onChange={handleChangeDob}
                        />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <label
                          htmlFor="name"
                          className="col-form-label for-label"
                        >
                          {getTranslation('address')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <input
                          type="text"
                          id="name"
                          className="form-control "
                          value={address}
                          readOnly={edit}
                          onChange={handleChangeAddress}
                        />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <label
                          htmlFor="email"
                          className="col-form-label for-label"
                        >
                          {getTranslation('registered_email')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <input
                          type="text"
                          id="name"
                          className="form-control "
                          value={email}
                          readOnly
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                        <label
                          htmlFor="name"
                          className="col-form-label for-label"
                        >
                          {getTranslation('registered_phone')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-5 col-8 for-input">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          value={phone_no}
                          readOnly={edit}
                          onChange={handleChangePhone}
                        />
                      </div>
                      {upshow && (
                        <>
                          <div className="col-3 for-input">
                            <button
                              className="btn premium-btn "
                              onClick={() => Submit()}
                              disabled={enable1}
                            >
                              {getTranslation('Update_Profiless')}{" "}
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                    {/* <SubscriptionKey id={key} exp={exp} status={status} newkey={profile[0].new_subscription_key} /> */}
                  </div>

                  <hr className="divider"></hr>
                  <button className="btn edit" onClick={() => Edites()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-square me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>{" "}
                    {getTranslation('edit_profile')}
                  </button>
                  {role === 70 && (
                    <>
                      <hr className="hr-line hrr" />

                      <div>
                        <div className="row g-3  mt-2 mb-5">
                          <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="profile_heading d-flex justify-content-between">
                              <h2>{getTranslation('plan')}:</h2>
                              <div className="row g-3 ">
                                <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                  <label
                                    htmlFor="name"
                                    className="col-form-label for-label tags"
                                    style={{ marginLeft: "9%" }}
                                  >
                                    {getTranslation('organization')}:{" "}
                                  </label>
                                </div>
                                <div className="col-xxl-8 col-lg-8 col-md-8 col-sm-8 col-8 for-input">
                                  <select
                                    className="form-select dropdown-selector"
                                    style={{ borderRadius: "6px" }}
                                    onChange={(e) => setOrgId(e.target.value)}
                                    value={orgId}
                                  >
                                    <option value="">{getTranslation("select")}</option>
                                    {
                                      org_channel && org_channel.map((ele) => (
                                        <option value={ele.label}>{ele.value}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>


                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 for-input">
                            <>
                              <div className="table-responsive ">
                                <table className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">{getTranslation('s_no')}</th>
                                      <th scope="col">{getTranslation('plan_name')}</th>
                                      <th scope="col">{getTranslation('activate_date')}</th>
                                      <th scope="col">{getTranslation('expiry_date')}</th>
                                      <th scope="col">{getTranslation('hcp')}</th>
                                      <th scope="col">{getTranslation('patient')}</th>
                                      <th scope="col">{getTranslation('limit')}</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {getList.map((row) => (
                                      <tr>
                                        <td>{i++}</td>
                                        <td>{row.plan_name}</td>
                                        <td>
                                          <span className="text-color">
                                            {row.sDate.substring(0, 10)}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-danger">
                                            {row.eDate.substring(0, 10)}
                                          </span>
                                        </td>

                                        <td>
                                          <span>{row.hcp}</span>
                                        </td>

                                        <td>
                                          <span>{row.patient}</span>
                                        </td>
                                        <td>
                                          <span>{row.limit} {getTranslation("gb")}</span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                                <div className="row">
                                  <div className="col-lg-4 col-sm-5 ms-auto">
                                    <table className="table table-bordered ">
                                      <tbody>
                                        <tr>
                                          <td className="left">
                                            <strong>{getTranslation('total_hcp')}</strong>
                                          </td>
                                          <td className="right">
                                            {totalHcp.totalhcp}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="left">
                                            <strong>{getTranslation('total_patient')}</strong>
                                          </td>
                                          <td className="right">
                                            {totalHcp.totalPatient}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-5 col-md-5 col-sm-12 col-12">




        {/* {role < 39 && (
          <div className="profile_request profile profiless">
            <div className="profile_heading d-flex mt-1">
              <button
                className="btn ms-auto premium-btn  w-auto"
                onClick={() => setIsOpen(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-person-plus me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path
                    fill-rule="evenodd"
                    d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>
                {getTranslation('request_to_join')}
              </button>
            </div>

            {plist.length > 0 && (
              <div className="req_table mt-4">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th scope="col">{getTranslation('s_no')}</th>
                        <th scope="col">{getTranslation('hcp_name')}</th>
                        <th scope="col">{getTranslation('status')}</th>
                        <th scope="col">{getTranslation('expiry_days')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plist.map((row) => (
                        <tr>
                          <td>{s++}</td>
                          <td>{row.name}</td>
                          <td>
                            {row.status === 1 && (
                              <span className="text-color">{getTranslation("approved")}</span>
                            )}
                            {row.status === 0 && (
                              <span className=" text-warning">{getTranslation("pending")}</span>
                            )}
                            {row.status === -1 && (
                              <span className="text-danger">{getTranslation("rejected")}</span>
                            )}
                          </td>
                          <td>
                            {row.status === 1 && (
                              <span className=" text-danger">
                                {row.days === "Expired"
                                  ? "Expired"
                                  : row.days + " " + "days left"}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

          </div>
        )} */}



        {/* {
          profile[0].role_value === 40 ? (
            <div className="req_table mt-4">
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S No.</th>
                      <th scope="col">HCP Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Expiry Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docreqorglist.map((row) => (
                      <tr>
                        <td>{s++}</td>
                        <td>{row.name}</td>
                        <td>
                          {row.status === 1 && (
                            <span className="text-color">Approved</span>
                          )}
                          {row.status === 0 && (
                            <span className=" text-warning">Pending</span>
                          )}
                          {row.status === -1 && (
                            <span className="text-danger">Rejected</span>
                          )}
                        </td>
                        <td>
                          {row.status === 1 && (
                            <span className=" text-danger">
                              {row.days === "Expired"
                                ? "Expired"
                                : row.days + "days left"}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (null)
        } */}



        {role <= 69 && (
          <div className="profile_request mt-4 profile profiless">
            <div className="profile_heading d-flex mt-1">
              <button
                className="btn ms-auto premium-btn  w-auto"
                onClick={() => setIsOpenorg(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-people-fill me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  <path
                    fillRule="evenodd"
                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                  />
                  <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                </svg>

                {role === 0
                  ? getTranslation('request_to_join_p')
                  : getTranslation('request_to_join_h')}
              </button>
            </div>
            {docreqorglist.length > 0 && (
              <div className="req_table mt-4">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th scope="col">{getTranslation('s_no')}</th>
                        <th scope="col">{getTranslation('org_name')}</th>
                        <th scope="col">{getTranslation('status')}</th>
                        {/* <th scope="col">Expiry Days</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {docreqorglist.map((row) => (
                        <tr>
                          <td>{o++}</td>
                          <td>{row.name}</td>
                          <td>
                            {row.status === 1 && (
                              <span className="text-color">{getTranslation("approved")}</span>
                            )}
                            {row.status === 0 && (
                              <span className=" text-warning">{getTranslation("pending")}</span>
                            )}
                            {row.status === -1 && (
                              <span className="text-dangerous">{getTranslation("rejected")}</span>
                            )}
                          </td>
                          {/* <td>
                            {row.status === 1 && (
                              <span className=" text-danger">
                                {row.days === "Expired"
                                  ? "Expired"
                                  : row.days + "days left"}
                              </span>
                            )}
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* hcp request  */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal model_org"
        overlayClassName="myoverlay"

      // closeTimeoutMS={5}
      >
        <div>
          <button
            className="close"
            style={{ borderWidth: 0 }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-header">
            <h5 className="modal-title"> {getTranslation('request_to_join')}</h5>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row justifyContentCenter">
                <div className="col-xl-10 col-md-10 col-sm-12 col-12">
                  {/* <ReactSearchAutocomplete
                    items={list}
                    fuseOptions={{
                      shouldSort: true,
                      threshold: 0.6,
                      location: 0,
                      distance: 100,
                      maxPatternLength: 32,
                      minMatchCharLength: 3,
                      keys: ["name", "g"],
                    }} // Search on both fields
                    resultStringKeyName="name" // String to display in the results
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    // onFocus={handleOnFocus}
                    placeholder={getTranslation("search_by_hcp_name")}
                    onClear={handleOnClear}
                    onChange={handleSearchChange}
                    showIcon={false}
                    styling={{
                      height: "34px",
                      border: "1px solid black",
                      borderRadius: "4px",
                      backgroundColor: "white",
                      boxShadow: "none",
                      hoverBackgroundColor: "orange",
                      color: "black",
                      fontSize: "12px",
                      fontFamily: "Courier",
                      iconColor: "black",
                      lineColor: "orange",
                      placeholderColor: "black",
                      clearIconMargin: "3px 8px 0 0",
                      zIndex: 1,
                    }}
                  /> */}
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    // disableClearable={true}
                    options={list}
                    value={selectedOrg}
                    className="w-100"
                    onChange={handleHcpChange}
                    // disableClearable={disable}
                    renderInput={(params) => (
                      <TextField
                        placeholder={getTranslation("search_by_hcp_name")}
                        className=""
                        style={
                          {
                            // height: "52px",
                            border: "1px solid #f4f4ff;",
                            // borderColor: "#7867a8",
                            // borderRadius: "7px",
                            // backgroundColor: "white",
                            // boxShadow: "none",
                            // // hoverBackgroundColor: "#3f2783",
                            // hoverColor: "#fff",
                            // color: "black",
                            // fontSize: "12px",
                            // fontFamily: "Courier",
                            // iconColor: "#7867a8",
                            // // lineColor: "black",
                            // placeholderColor: "black",
                            // clearIconMargin: "3px 8px 0 0",
                          }
                        }
                        {...params}
                        // onChange={(e) => handleOnSearch(e.target.value)}
                        onKeyUp={(e) => handleOnSearch(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                onClick={() => saveHCP()}
                class="btn premium-btn btn_org_upload"
                disabled={enable}
              >
                {getTranslation('send_req')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* end hcp request */}

      <Modal
        isOpen={isOpenorg}
        onRequestClose={toggleModalorg}
        contentLabel="My dialog"
        className="mymodal model_org"
        overlayClassName="myoverlay"

      // closeTimeoutMS={5}
      >
        <div>
          <button
            className="close"
            style={{ borderWidth: 0 }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleModalorg}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-header">
            <h5 className="modal-title mob-title" style={{ marginLeft: "14%" }}>
              {" "}
              {
                role === 0 ?
                  getTranslation('request_to_join_p') : getTranslation('request_to_join_h')



              }
            </h5>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row justifyContentCenter">
                <div className="col-xl-10 col-md-10 col-sm-12 col-12">
                  {/* <ReactSearchAutocomplete
                    items={listorg}
                    fuseOptions={{
                      shouldSort: true,
                      threshold: 0.6,
                      location: 0,
                      distance: 100,
                      maxPatternLength: 32,
                      minMatchCharLength: 3,
                      keys: ["org_name", "org_id"],
                    }} // Search on both fields
                    resultStringKeyName="org_name" // String to display in the results
                    onSearch={handleOnSearchorg}
                    onHover={handleOnHoverorg}
                    onSelect={handleOnSelectorg}
                    placeholder={getTranslation('search_by_org_name')}
                    onFocus={handleOnFocusorg}
                    onClear={handleOnClear}
                    showIcon={false}
                    styling={{
                      height: "34px",
                      border: "1px solid black",
                      borderRadius: "4px",
                      backgroundColor: "white",
                      boxShadow: "none",
                      hoverBackgroundColor: "orange",
                      color: "black",
                      fontSize: "12px",
                      fontFamily: "Courier",
                      iconColor: "green",
                      lineColor: "orange",
                      placeholderColor: "black",
                      clearIconMargin: "3px 8px 0 0",
                      zIndex: 1,
                    }}
                  /> */}

                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    // disableClearable={true}
                    options={list}
                    value={selectedOrg}
                    className="w-100"
                    onChange={handleOrgChange}
                    // disableClearable={disable}
                    renderInput={(params) => (
                      <TextField
                        placeholder={getTranslation('search_by_org_name')}
                        className=""
                        style={
                          {
                            // height: "52px",
                            border: "1px solid #f4f4ff;",
                            // borderColor: "#7867a8",
                            // borderRadius: "7px",
                            // backgroundColor: "white",
                            // boxShadow: "none",
                            // // hoverBackgroundColor: "#3f2783",
                            // hoverColor: "#fff",
                            // color: "black",
                            // fontSize: "12px",
                            // fontFamily: "Courier",
                            // iconColor: "#7867a8",
                            // // lineColor: "black",
                            // placeholderColor: "black",
                            // clearIconMargin: "3px 8px 0 0",
                          }
                        }
                        {...params}
                        // onChange={(e) => handleOnSearch(e.target.value)}
                        onKeyUp={(e) => handleOnOrgSearch(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                onClick={() => saveOrg()}
                disabled={enable}
                class="btn premium-btn btn_org_upload"
              >
                {getTranslation('send_req')}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/*Model end*/}
    </div>
  );
}

export default ProfileData;
