import React from "react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SmallSkeleton from "../smallSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import Postitem from "./Postitem";
import typeConfig from "../../config/typeConfig";
import HttpService from "../../services/HttpService";
import useTranslation from "../customHooks/translations";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import Categories from "../Categories";
import en from "../../localization/en";
import fr from "../../localization/fr";
import ger from "../../localization/ger";
import ita from "../../localization/ita";
import ara from "../../localization/ara";
import spa from '../../localization/spa'
import tha from '../../localization/tha';
import kor from '../../localization/kor';
import chn from '../../localization/chn';


const start = 16;
const ParticularChannelList = () => {
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const axiosinstance = useAxios()
  const navigate = useNavigate()

  const { id } = useParams();
  const { name } = useParams();


  const [arrList, setArrList] = useState([]);
  const [limit, setLimit] = useState(16);
  const [loop, setLoop] = useState(false);
  const [show, setShow] = useState(false);

  const [check, setcheck] = useState(arrList.length);



  useEffect(() => {
    if (profile.length > 0) {
      axiosinstance.current
        .get(
          `get_specific_channel_data?limit=${start}&offset=0&id=${id}&ak_id=${profile[0]?.id}`
        )
        .then((response) => {
          if (response) {
            setShow(true);
            setArrList(response.data.data);
            setcheck(response.data.data.length);
          } else {
            setShow(false);
          }
        });
    }

  }, [profile, id]);








  const fetchData = () => {
    setLimit(limit + 16);
    if (loop === false) {
      axiosinstance.current
        .get(
          `get_specific_channel_data?limit=${start}&offset=${limit}&id=${id}&ak_id=${profile[0]?.id}`
        )
        .then((response) => {
          let res = response.data.data;
          if (res.length === 0) {
            setLoop(true);
          
          } else {
            setArrList(arrList.concat(res));
          }
        });
    }
  };
  const getData = (data) => {
    navigate(`/search?cat_id=${btoa(data)}`)
  };

  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };




  return (
    <>
      <Categories onSubmit={getData} />
      <div className="clearfix"></div>
      <div className="trending">
        <div className="upcoming-head filter">
          <h4>{name.replace(/-/g, " ")}</h4>
        </div>
        <div className="trendig-box">
          <InfiniteScroll
            dataLength={arrList.length}
            next={fetchData}
            hasMore={true}
            loader={
              ""
              //   <div className="load_next text-center " style={{ 'textTransform': 'uppercase'}}>
              //   <span>No more Data</span>
              // </div>
            }
          >
            <div className="row">
              {!show && <SmallSkeleton></SmallSkeleton>}
              {show &&
                arrList.map((post) => (
                  <div
                    className="col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-12 mb-4"
                    key={post.id}
                  >
                    <Postitem
                      id={post.id}
                      title={post.title}
                      posted_by={post.posted_by}
                      videoKey={post.video_key}
                      duration={post.duration}
                      large={post.large}
                      medium={post.medium}
                      views={post.views}
                      description={post.description}
                      data={post.date}
                      category={post.category}
                      featured={post.featured}
                      sessionId={post.sessionId}
                      profile={post.profile}
                      type={post.type}
                      link={post.link}
                    />
                  </div>
                ))}
            </div>
          </InfiniteScroll>

          {check === 0 && (
            <div className="no_data">
              <h2>{getTranslation('no_videos_found')}</h2>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ParticularChannelList
