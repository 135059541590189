let current_date = new Date();
let dd = String(current_date.getDate()).padStart(2, '0');
let mm = String(current_date.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = current_date.getFullYear();

let new_date = yyyy + '-' + mm + '-' + dd;

 const appConfig =  {
  
    new_date
}

export default appConfig;