
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "Alle Videos",
  upcoming: "Bevorstehende",
  trending: "Im Trend",
  recently_added: "Kürzlich hinzugefügt",
  live: "Live-Events",
  library: "Meine Favoriten",
  view_by_catagories: "Nach Fachgebiet anzeigen",
  loading: "Wird geladen...",
  filter: "Filter",
  profile: "Profil",
  profile_photo: "Profilfoto",
  first_name: "Vorname",
  last_name: "Nachname",
  update_profile: "Profil aktualisieren",
  Update_Profiless: "PROFIL AKTUALISIEREN",
  edit_profile: "Profil bearbeiten",
  date_of_birth: "Geburtsdatum",
  address: "Adresse",
  registered_email: "Registrierte Email",
  registered_phone: "Registrierte Telefonnummer",
  subscription_key: "Abonnementschlüssel",
  renew_key: "SCHLÜSSEL ERNEUERN",
  start_date: "Startdatum",
  end_date: "Enddatum",
  no_more_data: "Keine weiteren Daten",
  search: "Suche",
  logout: "Abmelden",
  view_profile: "Profil anzeigen",
  notifications: "Benachrichtigungen",
  Get_Premium: "Premium Mitgliedschaft",
  App_Version: "App Version",
  Get_3_months_of_premium_for_free: "Erhalten Sie 3 Monate Premium kostenlos",
  Click_below_to_sign_up_for_premium_Akunah_MEd: "Klicken Sie unten, um sich für das Premium-Angebot von Akunah Med anzumelden",
  request_to_join: "Treten Sie HCP als Patient bei",
  request_to_join_p: "Treten Sie Organisation als Patient bei",
  request_to_join_h: "Treten Sie Organisation bei",
  lives: "live",
  plan: "Alle Pläne",
  channels: "Startbildschirm",

  akunahPublicP: "Meine Gesundheitsvideos", //Not Done
  OrgPublicPatient: "Mein Anbieter Gesundheitsvideos",
  HCPPublicPatient: "Meine Arzt Videos",
  HCPPrivatePatient: "Mein Exklusive Videos",
  akunahExc: "Akunah Global HCP",
  akunahPublic: "Akunah Global Public",
  OrgPublic: "Meine Organisation Öffentlich",
  OrgExc: "Mein Org HCP",
  mypublic: "F-my public",
  myprivate: "F-my private",
  // myhcppublic: "f-my hcp public",
  // myhcpprivate: "f-my hcp Exclusive",
  myOrgPublic: "Meine ORG Öffentlich",
  myOrgExc: "Meine ORG Exklusiv",
  akunah_how_tos: "Akunah Anleitungen",
  Upload_a_Video: "Ein Video hochladen",
  Channelsdata: "Kanäle",

  akunah_how_tos: "Akunah Anleitungen",
  administration: "Administration",
  view_all: " Alle ansehen",
  login_by: "Melden Sie sich an bei",
  upload_video: "Ein hochladen",
  type_of_content: "Art des Inhalts",
  title: "Titel",
  thumb_imag1: "Miniaturbild (Bildgröße 720 x 405)",
  thumb_imag2: "Miniaturbild (Bildgröße 480 x 270)",
  video: "Video",
  link: "Streaming Link",
  tags: "tags",
  organization: "Organisation",
  tags_channels: "Markiert Kanäle",
  description: "Beschreibung",
  publish: "Veröffentlichen",
  submit: "Einreichen",

  manage_my_videos: "Meine Videos verwalten",
  manage_my_organizations: "Meine Organisationen verwalten",
  manage_my_hcps: "Verwalten Sie meine HCPs",
  manage_org_patient: "Organisationspatienten verwalten",
  manage_my_patients: "Verwalte meine Patienten",
  manage_tags: "Tags verwalten",
  manage_my_plans: "Meine Pläne verwalten",






  video_list: "Videoliste",
  upload_videos: "Videos Hochladen",
  limit: "Limit",
  type: "Typ",
  posted_by: "Veröffentlicht bei",
  organization: "Organisation",
  s_no: "S Nr.",
  video_id: "Video-ID",
  duration: "Dauer",
  video_size: "Video Größe",
  actions: "Aktionen",
  add_a_organization: "Fügen Sie eine Organisation hinzu",
  org_name: "Name der Organisation",
  size: "Größe",
  watch_time: "Länge",
  sort: "Sortiere",
  status: "Statut",
  organization_Id: "Organisations-ID",
  total_post_size: "Gesamtgröße des Beitrags",
  total_watch_time: "Gesamte Wiedergabezeit",
  add_tags: "Tags hinzufügen",
  order: "Bestellung",
  add: "Hinzufügen",
  edit: "Bearbeiten",
  tags_list: "Tag-Liste",
  add_plan: "Plan hinzufügen",
  plan_name: "Planname",
  number_of_hcp: "Anzahl der HCP",
  number_of_patient: "Anzahl der Patienten",
  Limit: "Limit",
  plan_list: "Planliste",
  noofhcp1: "Anzahl der HCPs",
  nopatient1: "Anzahl der Patienten",
  save: "Speichern",
  add_channels: "Füge Channel hinzu",
  name: "Name",
  role: "Sichtbar für Rolle(n)",
  all: "Veröffentlichung anfordern",
  users: "Benutzer",
  user_type: "Benutzertyp",
  list_of_channels: "Kanalliste",
  event_Link: "Veranstaltungslink",
  visible_till: "* Sichtbar bis",
  manage_hcps: "HCPs verwalten",
  pending_hcp_req: "HCP-Anfrage ausstehend",
  invite_hcp: "HCP einladen",
  email: "Email",
  delete_hcp: "Lösche HCP ?",
  no: "NEIN",
  yes: "Ja",
  add_email: "E-Mail hinzufügen",
  view_plans: "Pläne anzeigen",
  activate_date: "Aktives Datum",
  expiry_date: "Verfallsdatum",
  hcp: "HCP",
  patient: "Patient",
  total_hcp: "Gesamt-HCP",
  total_patient: "Gesamtpatient",
  invite_admin: "Admin einladen",
  autoid: "Auto-ID",
  delete_admin: "Sind Sie sicher, dass Sie als Administrator löschen möchten ?",
  admin_user_email: "E-Mail des Admin-Benutzers",
  view_admins: "Administratoren anzeigen",
  view_HCPs: "HCPs ansehen",
  patient_list: "Patientenliste",
  pending_patient_req: "Ausstehende Patientenanfrage",
  invite_patient: "Patienten einladen",
  patient_name: "Patientenname",
  delete_patient: "Lösche Patient ?",
  no_videos_found: "Keine Videos gefunden",
  not_found_trending: "Keine Trendvideos gefunden",
  upload_video_popup: "Sie müssen Ihre eigene Organisation erstellen, um Videos hochzuladen",
  create_org: "Organisation erstellen",
  list_of_user_profiles: "Liste der Benutzerprofile",
  all_speciality: "Alle Fachgebiete",
  channel_delete: "Sind Sie sicher, dass Sie dieses Element löschen möchten?",
  req_for_create_org: "Anfrage zum Erstellen einer Organisation",
  cancel: "Stornieren",
  send: "Versende",
  akunahhandbook: "Akunah-Handbuch",
  help: "Akunah-Hilfe",
  subject: "Thema",
  message: "Nachricht",
  no_live_video: "Keine Live-Videos gefunden",
  hcp_name: "HCP-Name",
  expiry_days: "Ablauftage",
  send_req: "Anfrage senden",
  search_by_speciality: "Suche nach Fachgebiet",
  no_request_data: "Keine angeforderten Daten",
  no_data_found: "Keine Daten gefunden",
  approve_patient_list: "Patientenliste genehmigen",
  create_event: "Erstellen Sie eine Veranstaltung",
  live_event: "Live Veranstaltung",
  switch_profile: "Profil wechseln",
  event_link: "Veranstaltungslink",
  search_by_patient_email_id: "Suche nach Patienten- und E-Mail-ID",
  searchbyb_title: "Suche nach Titel",
  search_by_org_name: "Suche nach Organisationsnamen",
  enter_email_address: "E-Mail Adresse eingeben",
  no_of_days: "Anzahl der Tage",

//12/7/2023//need to translate


  acceopt: "Akzeptieren",
  reject: "Ablehnen",
  approved: "Genehmigt",
  select: "Wählen",
  active: "Aktiv",
  deactive: "Deaktiviert",
  select_plan:"Plan auswählen",
  views:"Ansichten",
  view_patients:"Patienten anzeigen",
  valid_email:"Geben Sie eine gültige E-Mail-Adresse ein !!!",
  is_already_created:"ist bereits erstellt",
  req_to_hcps:"Anfrage an HCP'S",
  approve:"Genehmigen",
  upload:"HOCHLADEN",
  search_by_name:"Suche mit Namen",
  global_channel:"Globaler Kanal",
  all_users:"Alle Benutzer",
  patients:"Patienten",
  HCPs:"HCPs",
  selected_users:"Ausgewählte Benutzer",
  post_updated:"Beitrag aktualisiert",
  video_deleted:"Video erfolgreich gelöscht",
  ascending:"Aufsteigend",
  descending:"Absteigend",
  all_drop:"Alle",
  akunah:"Akunah",
  channel_added:"Kanal hinzugefügt",
  plan_status:"Planstatus geändert",
  tag_added:"Tag hinzugefügt",
  tag_updated:"Tag aktualisiert",
  hcp_deleted:"HCP erfolgreich gelöscht",
  pending:"Ausstehend",
  patient_deleted:"Patient erfolgreich gelöscht",
  is_new_patient:"ist ein neuer Patient hinzugekommen",
  new_plan_added:"Neuer Plan hinzugefügt",
  org_added:"Organisation hinzugefügt",
  date:"Datum",
  missing_subj:"Betreff fehlt",
  missing_message:"Nachricht fehlt",
  select_user:"Nutzer wählen",
  field_req:"Dieses Feld ist erforderlich",
  select_org:"Wählen Sie Organisation aus",
  is_already_org:"ist bereits in der Organisation vorhanden",
  org_error:"Diese Organisation ist bereits erstellt. Bitte treten Sie bei, anstatt ein neue zu erstellen.",
  email_sent:"Email wurde erfolgreich Versendet",
  content_body:"Der Inhaltstext fehlt",
  not_sent_email:"E-Mail nicht gesendet",
  no_user_found:"Kein Benutzer gefunden",
  profile_update:"Profilaktualisierung",
  req_sent:"Anfrage versandt",
  req_to_publish:"Bitte um Veröffentlichung",
  no_req:"Keine Anfrage",
  akunah_global_public:"Akunah Global Public",
  akunah_global_patient:"Akunah Global Patient",
  enter_org_name:"Bitte geben Sie den Namen der Organisation ein", 
  select_a_plan:"Bitte wählen Sie einen Plan aus",
  Plan:"Planen",
  admin_deleted:"Admin gelöscht",
  email_added:"E-Mail hinzugefügt",
  email_not_added:"E-Mail nicht hinzugefügt",
  plan_not_added:"Plan nicht hinzugefügt",
  hcp_not_delete:"HCP nicht gelöscht",
  hcp_approved:"HCP erfolgreich angenommen.",
  hcp_reject:"HCP abgelehnt",
  channel_updated:"Kanal aktualisiert",
  channel_deleted:"Kanal erfolgreich gelöscht",
  limit_error:"Der Grenzwert sollte größer als 0 sein",
  days:"Tage",
  gb:"GB",
  plan_created:"Plan erstellt",
  rejected:"Abgelehnt",
  expired:"Abgelaufen",
  search_by_hcp_name:"Suche nach HCP-Namen",


name_already_present:"Name ist bereits vorhanden",
already_req_hcp:"Sie haben bereits eine Anfrage an diesen HCP gestellt",
already_req_org:"Sie haben bereits eine Anfrage an diese Organisation gestellt",

video_not_delete:"Video nicht gelöscht",

video_updated_success:"Video erfolgreich aktualisiert",
Doctor:"Médecin",

Patient: "Patient",
favorite:"Favorit",
unfavourite:"Unfavorit",

admin: "Administrator",
org_admin: "Organisationsadministrator",


video_upload_success:"Video Uploaded Successfully",//Not Done
live_event_create_success:"Live Event Create Successfully", //Not Done


privacy:"Ihre Privatsphäre Indem Sie auf „Alle Cookies akzeptieren“ klicken, erklären Sie sich damit einverstanden, dass akunah Med Cookies auf Ihrem Gerät speichern und Informationen gemäß unserer Datenschutzrichtlinie offenlegen kann",
cookie:"Cookie-Richtlinie",
my_profiles:"Meine Profile",

not_found_recent:"Keine aktuellen Videos gefunden"



};