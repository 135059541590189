import React, { Suspense } from "react";
import { useSelector } from "react-redux/es/exports";
import appStyle from "../config/appStyle";
import UserService from "../services/UserService";
import { useEffect } from "react";
// import authConfig from "../config/authConfig";
import Header from "../Component/Header";
// const Header = React.lazy(() => import("../Component/Header"));
import { useNavigate } from "react-router-dom";
import SideBar from "../Component/Sidebar";
import useTranslation from "../Component/customHooks/translations";
// const SideBar = React.lazy(() => import("../Component/Sidebar"));
const UpcomingList = React.lazy(() => import("../Component/UpcomingList"));
const Footer = React.lazy(() => import("../Component/Footer"));

export default function UpcomingPage() {
 // login check 

 const translation=useTranslation();
// const check = function loginCheck() {
//   return UserService.isLoggedIn()
// }
// const abc = check()

// const navigate = useNavigate();
//  useEffect(() => {
//     if (!abc) {
//       navigate("/loader");
//     }
//   },[])
// login check end
  const { show } = useSelector((state) => state.mainReducer.navData);
  document.title = "Akunah Med || Upcoming";
  return (
    <>
      {/* <Suspense fallback={<div className="text-center">Loading...</div>}> */}
        <Header></Header>
      {/* </Suspense> */}
      <main className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
              {/* <Suspense
                fallback={<div className="text-center">Loading...</div>}
              > */}
                <SideBar></SideBar>
              {/* </Suspense> */}
            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
              {/* Upcoming end */}
              <Suspense fallback={<div className="text-center">{translation.loading}</div>}>
              <UpcomingList></UpcomingList>
              </Suspense>
              <Footer></Footer>
              
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
