const initialValues = { 
    arr : []
}


export default (state = initialValues, action) => {
    switch (action.type){
   
        case 'SET_SEARCH':
            return{
                arr: action.payload
            };
      
        default:
            return state;
    }
}