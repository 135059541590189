
let upcomingID = 1
let trendigID = 2
let recentlyID = 3
let liveID = 4

 const typeConfig =  {
    upcomingID,
    trendigID,
    recentlyID,
    liveID 
}

export default typeConfig;