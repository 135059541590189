import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import HttpService from "../services/HttpService";
import { useSelector } from "react-redux";
import useAxios from "../axiosinstance";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from "../localization/spa";
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';

// import { Container } from './styles';

export default function MobileNav() {
  const axiosinstance = useAxios()
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  let { arr } = useSelector((state) => state.mainReducer.catagoryData);
  const navigate = useNavigate()
  const [statics, setStatics] = useState("");
  const [postId, setPostId] = useState("")
  const [channelData, setChannelData] = useState([])
  const [userChannelData, setUserChannelData] = useState([])
  let akToken = localStorage.getItem("akTokenMed");
  const [role, setRole] = useState(20);
  const listofchannels = useSelector((state) => state.mainReducer.channelList.channels);

  const handleClick = () => {
    navigate(`/manage-patient-list/${profile[0].id}`)

  }

  const handleClick2 = () => {
    navigate(`/post-list`)

  }

  const handleClick3 = () => {
    navigate("/channels")
  }




  const handleStatic = () => {
    arr && arr.map((ele) => {
      if (ele.title === "Akunah How To") {
        setStatics(ele.title)
        setPostId(ele.id)
      } else {
        return
      }
    })
  }


  useEffect(() => {
    if (profile.length != 0) {
      setRole(profile[0].role_value);
    }
    handleStatic()
  }, [role, profile]);
  const [isActive, setActive] = useState("");


  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };







  return (
    <section
      className="mobile_  d-lg-none d-xxl-none  d-block"
      style={{ marginTop: "125px" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="">
              <div className={profile[0]?.role_value === 100 || profile[0]?.role_value === 70 ? "sidebar_small_mobile" : profile[0]?.role_value === 40 || profile[0]?.role_value === 0 ? "sidebar_small_mobileee" : "sidebar_small_mobileee"}>
                <NavLink
                  className={`${isActive === "channels" ? "active" : ""}`}
                  onClick={() => setActive("channels")}
                  to={{ pathname: "/channels" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={getTranslation("channels")}

                >
                  <i className="me-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-house"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                      />
                    </svg>
                  </i>
                </NavLink>
                {/* <NavLink
                  className={`${isActive === "Home" ? "active" : ""}`}
                  onClick={() => setActive("Home")}
                  to={{ pathname: "/home" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Home"
                >
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-play-btn"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                    </svg>
                  </i>{" "}
                </NavLink> */}
                {/* <NavLink
                  className={`${isActive === "upcoming" ? "active" : ""}`}
                  onClick={() => setActive("upcoming")}
                  to={{ pathname: "/upcoming" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Upcoming"
                >
                  <i className=" ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-calendar-check"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                    </svg>
                  </i>{" "}
                </NavLink> */}
                <NavLink
                  className={`${isActive === "trending" ? "active" : ""}`}
                  onClick={() => setActive("trending")}
                  to={{ pathname: "/trending" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={getTranslation("trending")}
                >
                  <i className="">
                    {" "}
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g data-name="Layer 2">
                        <g data-name="trending-up">
                          <rect
                            width="24"
                            height="24"
                            transform="rotate(-90 12 12)"
                            opacity="0"
                          />
                          <path d="M21 7a.78.78 0 0 0 0-.21.64.64 0 0 0-.05-.17 1.1 1.1 0 0 0-.09-.14.75.75 0 0 0-.14-.17l-.12-.07a.69.69 0 0 0-.19-.1h-.2A.7.7 0 0 0 20 6h-5a1 1 0 0 0 0 2h2.83l-4 4.71-4.32-2.57a1 1 0 0 0-1.28.22l-5 6a1 1 0 0 0 .13 1.41A1 1 0 0 0 4 18a1 1 0 0 0 .77-.36l4.45-5.34 4.27 2.56a1 1 0 0 0 1.27-.21L19 9.7V12a1 1 0 0 0 2 0V7z" />
                        </g>
                      </g>
                    </svg>
                  </i>{" "}
                </NavLink>
                <NavLink
                  className={`${isActive === "recently" ? "active" : ""}`}
                  onClick={() => setActive("recently")}
                  to={{ pathname: "/recently" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={getTranslation("recently_added")}
                >
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </i>{" "}
                </NavLink>
                <NavLink
                  className={`${isActive === "live" ? "active" : ""}`}
                  onClick={() => setActive("live")}
                  to={{ pathname: "/live" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={getTranslation("live")}
                >
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-camera-video"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                      />
                    </svg>
                  </i>{" "}
                </NavLink>
                <NavLink
                  className={`${isActive === "Tos" ? "active" : ""} book_image`}
                  onClick={() => setActive("Tos")}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={getTranslation("akunah_how_tos")}
                  to={{ pathname: `/search?cat_id=${btoa(postId)}` }}

                >

                  <img className="me-3 bok_img" src="/Assets/image/handbook1.png" alt="" />

                </NavLink>

                <NavLink
                  className={`${isActive === "Tos" ? "active" : ""} book_image`}
                  onClick={() => setActive("Tos")}
                  data-bs-toggle="tooltip"
                  to={{ pathname: "/library" }}
                  data-bs-placement="top"
                  title={getTranslation("library")}

                >

                  <i className=" me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-collection-play"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1zm2.765 5.576A.5.5 0 0 0 6 7v5a.5.5 0 0 0 .765.424l4-2.5a.5.5 0 0 0 0-.848l-4-2.5z" />
                      <path d="M1.5 14.5A1.5 1.5 0 0 1 0 13V6a1.5 1.5 0 0 1 1.5-1.5h13A1.5 1.5 0 0 1 16 6v7a1.5 1.5 0 0 1-1.5 1.5h-13zm13-1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5h-13A.5.5 0 0 0 1 6v7a.5.5 0 0 0 .5.5h13z" />
                    </svg>
                  </i>{" "}

                </NavLink>



                {/* {
                  role === 100 || role === 70 ?
                    (
                      <>
                        <NavLink
                          className={`${isActive === "live" ? "active" : ""}`}
                          onClick={() => setActive("upload-video")}
                          to={{ pathname: "/upload-post?event=2" }}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={getTranslation("create_event")}
                        >

                          <img className="me-3 video-img" src="/Assets/image/event.png" alt="" />

                        </NavLink>


                        <NavLink
                          className={`${isActive === "live" ? "active" : ""}`}
                          onClick={() => setActive("upload-video")}
                          to={{ pathname: "/upload-post?event=1" }}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={getTranslation("Upload_a_Video")}
                        >

                          <img className="me-3 video-img" src="/Assets/image/upload-video2.svg" alt="" />

                        </NavLink>
                      </>
                    ) : (null)

                } */}




                {/* {role > 39 && (
                  <div className="dropdown mob_admin ms-2">
                    {
                      role === 40 ? (
                        <button
                          className="btn admin_bt admin_bt_mob channel-mob"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            className="me-2 "
                            src="/Assets/image/administration1.png"
                            alt=""
                            width={"20px"}
                          />
                          <p className="adminstartion" onClick={handleClick}>Administration</p>
                        </button>
                      ) : (
                        <button
                          className="btn admin_bt admin_bt_mob channel-mob"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            className="me-2 "
                            src="/Assets/image/administration1.png"
                            alt=""
                            width={"20px"}
                          />
                          <p className="adminstartion" onClick={handleClick2}>Administration</p>
                        </button>
                      )}
                  </div>
                )} */}
                {
                  role === 40 ? (
                    <>
                      {
                        listofchannels.length > 0 ? (
                          <div className="dropdown mob_admin ms-2 channels-class">
                            <div class="dropdown drop2">
                              <button class="btn admin_bt admin_bt_mob btn-secondary channel-mob" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img
                                  className="me-2 "
                                  src="/Assets/image/channels.png"
                                  alt=""
                                  width={"20px"}
                                />
                                <span className="">{getTranslation("Channelsdata")}</span>
                              </button>

                              <ul class="dropdown-menu channl_scroll parent-class">
                                {
                                  listofchannels && listofchannels.map((ele) => (
                                    <li>
                                      <NavLink
                                        className={`${isActive === "live" ? "active" : ""}`}
                                        onClick={() => setActive("upload-video")}
                                        // to={{ pathname: `/specificchannel/${ele.label}/${ele.value}` }}
                                        to={{
                                          // pathname: `/specificchannel/${ele.label}/${ele.value}`,
                                          pathname: `/specificchannel/${ele.label}/${ele.value.replace(/\s/g, '-').toLowerCase()}`,
          
                                        }}
                                      >

                                        {ele.value}
                                      </NavLink>
                                    </li>

                                  ))
                                }
                              </ul>


                            </div>
                          </div>
                        ) : (null)
                      }

                    </>
                  ) : (null)
                }


                {
                  role === 0 ? (
                    <>
                      {
                        listofchannels.length > 0 ? (
                          <div className="dropdown mob_admin  ms-2">
                            <div class="dropdown drop3">
                              <button class="btn admin_bt admin_bt_mob btn-secondary channel-mob" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img
                                  className="me-2 "
                                  src="/Assets/image/channels.png"
                                  alt=""
                                  width={"20px"}
                                />
                                <span className="">{getTranslation("Channelsdata")}</span>
                              </button>
                              <ul class="dropdown-menu channl_scroll parent-class">
                                {
                                  listofchannels && listofchannels.map((ele) => (

                                    <li>
                                      <NavLink
                                        className={`${isActive === "live" ? "active" : ""}`}
                                        onClick={() => setActive("upload-video")}
                                        // to={{ pathname: `/specificchannel/${ele.label}/${ele.value}` }}
                                        to={{
                                          // pathname: `/specificchannel/${ele.label}/${ele.value}`,
                                          pathname: `/specificchannel/${ele.label}/${ele.value.replace(/\s/g, '-').toLowerCase()}`,
          
                                        }}
                                      >
                                        {/* <img className="me-2 video-img" src="/Assets/image/upload-video1.svg" alt="" /> */}
                                        {ele.value}
                                      </NavLink>
                                    </li>

                                  ))
                                }
                              </ul>


                            </div>
                          </div>
                        ) : (null)
                      }

                    </>
                  ) : (null)
                }





                {
                  role === 100 || role === 70 ? (
                    <>
                      {
                        listofchannels.length > 0 ? (
                          <div className="dropdown mob_admin ms-2 channels-class">
                            <div class="dropdown drop2">
                              <button class="btn admin_bt admin_bt_mob btn-secondary channel-mob" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img
                                  className="me-2 "
                                  src="/Assets/image/channels.png"
                                  alt=""
                                  width={"20px"}
                                />
                                <span className="">{getTranslation("Channelsdata")}</span>
                              </button>
                              <ul class="dropdown-menu channl_scroll parent-class">
                                {
                                  listofchannels && listofchannels.map((ele) => (

                                    <li>
                                      <NavLink
                                        className={`${isActive === "live" ? "active" : ""}`}
                                        onClick={() => setActive("upload-video")}
                                        // to={{ pathname: `/specificchannel/${ele.id}/${ele.value}` }}
                                        to={{
                                          // pathname: `/specificchannel/${ele.id}/${ele.value}`,
                                          pathname: `/specificchannel/${ele?.id}/${(`${ele?.user_type === undefined ? (ele?.name?.replace(/\s/g, '-').toLowerCase()):(`${ele?.name} (${getTranslation(ele?.user_type)})`).replace(/\s/g, '-').toLowerCase()}`)}`,
          
                                        }}
                                      >

                                        {ele.value}
                                      </NavLink>
                                    </li>

                                  ))
                                }
                              </ul>


                            </div>
                          </div>
                        ) : (null)
                      }

                    </>
                  ) : (null)
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
