import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Component/custom/dropdown/Dropdown";
import Button from "../Component/custom/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { Validators } from "../utils/Validator";
// import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../axiosinstance";
// import { loginData } from "../../redux/actions/index";
import TopHeaderdocmap from "../Component/TopHeaderdocmap";

import '../Component/css/usermap.css'
import { setArr } from "../actions/profileAction";
import { s3UrlLink } from "../utils/S3Url";


const UserMap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const [userData, setUserData] = useState([]);
  const [usersId, setUserID] = useState('');
  const [languages, setLanguages] = useState([])
  const [lang, setLang] = useState("1")
  // const axiosInstance = useAxios();

  // useEffect(() => {
  //   if (profile.length === 1) setDoctor(profile[0].value);
  // }, [profile]);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.post(
          `sign`
        );
        // dispatch(fetchUserIdSuccess(response.data));
        setUserData(response.data.Data)
      } catch (error) {
        // setMessage(error.message);
        // setMessageType("error");
        // console.log(error.message);
      }
    };
    getData();
  }, []);


  const options = userData.map((item) => {
    return { value: item.akid, label: item.name + " (" + item.akid + ")" + "(" + item.user_type + ")", image: item.image, sname: item.sname };
  });


  const redirectToHome = () => {
    // axiosInstance.current
    //   .get("get_user_manual")
    //   .then((res) => {
    //     localStorage.setItem("akunahhandbook", res.data.link);
    //   })
    //   .catch((error) => {

    //   });

    // setInterval(() => {
    //   window.location.reload();
    // }, 1000);
    navigate("/channels", { replace: false });

  };

  const redirectToError = (doctor) => {
    navigate(`/login-error/${doctor} `, { replace: true });
  };
  const handleUsers = (e) => {
    setUserID(e)
  }
  const handleConnectMe = async (user_value) => {

    try {
      const result = await axiosInstance.current.get(
        `getUser?ak_id=${user_value}`
      );
      if (result.status === 200) {
        dispatch(setArr(result.data))
        localStorage.setItem("akTokenMed", user_value);
        redirectToHome();
      }
    } catch (err) {


    } finally {
      console.log("api response finally");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (usersId.trim().length === 0) {

      return;
    }
    handleConnectMe();
  };

  





  return (


    <>
      <div className="mb-5">
        <TopHeaderdocmap/>
      </div>

      {/* <div className="lang_ d-none d-lg-block " >
          <select onChange={(e) => handleChangeLanguage(e.target.value)} className="language" style={{ WebkitAppearance: "none" }}>
            {
              languages.map((ele) => (
                <option value={ele.id}>{ele.label}</option>
              ))
            }
          </select>
        </div> */}



      <div className="container">

        {/* <h2
          className="f_600 f_24 mt_40 mb_60 text-center"
          style={{ marginBottom: "50px" }}
        >
          {getTranslation("my_profiles")}
        </h2> */}

        <div className=" h-screen flex flex-col items-center justify-content-center d-flex ">
          <div className="flex flex-row flex-wrap gap-5 mt-8 d-flex justify-content-center">
            {options.map((res) => (
              <div
                className="flex flex-col items-center group gap-2  patient_imgS c_pointer"
                onClick={() => handleConnectMe(res.value)}
              >
                {res.image ? (
                  <img
                    className="rounded border-2 border-transparent group-hover:border-2 group-hover:border-gray-300 object-fit-scale patient_imgSS "
                    // style={{ "max-width": "100%", height: "170px" }}
                    width="150"
                    height="150"
                    src={res.image}
                  />
                ) : (
                  <img
                    className="rounded border-2 border-transparent group-hover:border-2 group-hover:border-gray-300 object-fit-scale patient_imgSS"
                    width="150"
                    height="150"
                    src="/Assets/image/user_demo.jpg"
                  />
                )}
                <p
                  className="text-gray-500 group-hover:text-gray-300 text-center mt-3 "
                  style={{
                    width: "150px",
                    color: "black",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  {res.label}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* <div class="add-btn row justify-content-center">
              <div className="col-sm-2 card-border">
                <img className="" src={plus} style={{ height: "100%" }} alt=""></img>
              </div>
            </div> */}
      </div>

    </>
  );
}

export default UserMap
