import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../Component/css/TopHeader.css'
import { useKeycloak } from "@react-keycloak/web";
import '../Component/css/usermap.css'
// import leftlogo from "../../images/akunah-hr-logo.svg";
import Button from "../Component/custom/button/Button";
import LanguageHandler from "./LanguageHandler";
import useAxios from "../axiosinstance";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


const TopHeaderdocmap = ({translation}) => {


  const { keycloak } = useKeycloak();
  const axiosinstance = useAxios();
  const [languages, setLanguages] = useState([])
  const [lang, setLang] = useState("1")
  const logout_url = window.location.origin;
  const handleLogout = () => {
    localStorage.removeItem("akTokenMed");
    keycloak.logout({ redirectUri: logout_url });
  };


  const GetLanguages = () => {
    axiosinstance.current.get(`getLanguages`).then((response) => {
      if (response) {
        setLanguages(response.data.data)
      }
    });
  };

  const handleChangeLanguage = (selectedLanguage) => {
    setLang(selectedLanguage)
  };




  useEffect(() => {
    GetLanguages();
  }, [])



  const getTranslation = (key) => {
    const translations =
      lang === "1"
        ? en
        : lang === "4"
          ? ger
          : lang === "5"
            ? ita
            : lang === "3"
              ? ara
              : lang === "6"
                ? spa
                : lang === "7"
                  ? tha
                  : lang === "8" ? kor
                    : lang === "9" ? chn : fr
    return translations[key] || key;
  };











  return (
    <>
      <div className="top__headerss">
        <div className="nav_inner_left">
          <Link to="/" className="leftLogo">
            <img src="Assets/image/akunahlogo.svg" className="left-logo" alt="logo" />
          </Link>
        </div>

        <div className="nav_inner_right">
          <div className="right_user_menu">
            <ul className="list-inline">
              <li className="chat-menu list-inline-item">
              <div className="lang1_ d-none d-lg-block " >
              <select onChange={(e) => handleChangeLanguage(e.target.value)} className="language" style={{ WebkitAppearance: "none" }}>
            {
              languages.map((ele) => (
                <option value={ele.id}>{ele.label}</option>
              ))
            }
           
          </select>
          </div>
              </li>
              <li className="list-inline-item use_lett">

                <Button
                  value= {getTranslation("logout")}
                  buttonStyle="btn_outline"
                  width="auto"
                  onClick={() => handleLogout()}
                />

              </li>
            </ul>
          </div>
        </div>
      
      </div>
      <h2
          className="f_600 f_24 mt_40 mb_60 text-center"
          style={{ marginBottom: "50px" }}
        >
          {getTranslation("my_profiles")}
        </h2>
    </>
  );
};

export default TopHeaderdocmap;
