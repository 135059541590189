import React from "react";
import { useState,useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import useTranslation from "./customHooks/translations";
import { useSelector,useDispatch } from "react-redux";
// import instance from '../axiosinstance';
import HttpService from "../services/HttpService";
import Avatar from 'react-avatar'
import useAxios from "../axiosinstance";
import { setArr } from "../actions/profileAction";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';



function ProfilePic(props) {
  const translation = useTranslation();
  const dispatch = useDispatch
  const axiosinstance =useAxios()
  const [selectedFile, setSelectedFile] = useState(null);
  const [show, setShow] = useState(false);
  // const [show, setShow] = useState('d-none');
  const [email, setemail] = useState(props.email);
  const [image, setImage] = useState(props.image);
  const[enable,setEnable]=useState(false)
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  let akToken = localStorage.getItem("akTokenMed");
  function onFileChange(event) {
    // Update the state

    if (
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpg" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      setSelectedFile(event.target.files[0]);
      // setShow("btn premium-btn");
      setShow(true);
    } else {
      toast.error("Invaild File Type !!");
    }
  }
  function onFileUpload(e) {
    e.preventDefault();
    setEnable(true)
    const formData = new FormData();
    formData.append("file", selectedFile);
    axiosinstance.current.post(`upload_profile_pic?ak_id=${profile[0].id}`, formData).then((response) => {
      if (response) {
        setImage(response.data.profile_pic);
        toast.success("Successfully Uploaded !");
        setShow(false);
        setEnable(false)
        getResult()
        // setShow('d-none);

      }
    });
  }


  const getResult = ()=>{
    axiosinstance.current.get(`getUser?ak_id=${akToken}`).then((response) => {
      if (response) {
        dispatch(setArr(response.data));
      }
    });
  }

useEffect(() => {
  getResult()
}, [akToken])



  function handleClick() {
    document.getElementById("clicked_").click();
  }
  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };




  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} />
      <div className="row g-3  mt-2">
        <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-4 col-4">
          <label htmlFor="name" className="col-form-label for-label">
            {getTranslation('profile_photo')}:{" "}
          </label>
        </div>
        <div className="col-xxl-3 col-lg-3 col-md-4 col-sm-4 col-5 for-input">
          <div className="profile_img">
            <div className="pro_outer">
              <img src={image} className="img-fluid img_set" alt=""></img>
              {image.length === 0 && (
                <span className="img_set text-capitalize">

                  <Avatar name={email.slice(0, 1)} size="100" maxInitials="2" round={true} fgColor='#EA702B' color='#F3CDB8' />
                </span>
              )}
              <div className="middle" onClick={handleClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
              </div>
            </div>
            {/* <button type="submit" className="update-button">UPDATE PHOTO</button> */}
            <input
              className="pro_inner d-none"
              type="file" 
              id="clicked_"
              name="profile_pic"
              onChange={onFileChange}
              accept=".jpg,.jpeg,.png"
            />
            <p>
             
            {show && show=== true && 
            <button type="submit" className="update-button" disabled={enable} onClick={onFileUpload}>{getTranslation('update_profile')}</button>}
            
              {/* <button className={show} onClick={onFileUpload}>
                {translation.update_profile}
              </button> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePic;
