
export const setChannelList = list =>{
    return {
        type: 'SET_CHANNEL_LIST',
        payload: list
    }
}
export const setChannelLists = datas =>{
    return {
        type: 'SET_CHANNEL_ORG_LIST',
        payload: datas
    }
}