import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import MobileNav from "./MobileNav";
import Cookies from "universal-cookie";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


export default function Footer() {
  const [show, setShow] = useState(true);
	const { profile } = useSelector((state) => state.mainReducer.profileData);

  const cookies = new Cookies();
  const acceptCookies = () => {
    cookies.set("Akunah", "Accept3", { path: "/" });
    setShow(false);
  };
  let data = cookies.get("Akunah");
  // setTimeout(()=>{
  //   if(data.length>0){
  //     setShow(false)
  //   }
  // },[1000])




  const getTranslation = (key) => {
		const translations =
		  profile[0]?.language === "en"
			? en
			: profile[0]?.language === "ger"
			  ? ger
			  : profile[0]?.language === "ita"
				? ita
				: profile[0]?.language === "ara"
				  ? ara
				  : profile[0]?.language === "spa"
					? spa
					: profile[0]?.language === "tha" ? tha
					  : profile[0]?.language === "kor" ? kor
						: profile[0]?.language === "ch" ? chn : fr
		return translations[key] || key;
	  };




  return (
    <>
      <MobileNav></MobileNav>
      { data !=="Accept3" && (
        <div className="cookie-banner small js-cookie-banner" role="alert" show={show}>
          <div className="cookie-banner__text">
          {getTranslation('privacy')}
            <a target='_blank' href="https://akunah.com/privacy-policy" className="link ms-2" rel="noreferrer">
            {getTranslation('cookie')}
            </a>
          </div>
          <button className="btn  btn_cookies" onClick={() => acceptCookies()}>
          {getTranslation('acceopt')}
          </button>
        </div>
      )}
    </>
  );
}