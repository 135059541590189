import React, { Suspense } from "react";
import { useSelector } from "react-redux/es/exports";
import Header from '../Component/Header'
import appStyle from "../config/appStyle";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
const AkunahHowTos = () => {
    const { show } = useSelector((state) => state.mainReducer.navData);
    document.title = "Akunah Med || Upcoming";
  return (
    <>
    <Header></Header>
    <main className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
                <Sidebar></Sidebar>
            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
             
              <Suspense fallback={<div className="text-center">{`No videos`}</div>}>
             
              </Suspense>
              <Footer></Footer>
              
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AkunahHowTos
