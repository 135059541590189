import { combineReducers } from "redux";
import profileReducer from "./profileReducer";
import upcomingReducer from "./upcomingReducer";
import recentlyReducer from "./recentlyReducer";
import trendingReducer from "./trendingReducer";
import navReducer from "./navReducer";
import homeUpcoming from "./homeUpcoming";
import homeTrending from "./homeTrending";
import homeRecently from "./homeRecently";
import catagory from "./catagory";
import video from "./videoReducer";
import lives from './liveReducer';
import Search from "./searchReducer";
import globalReducer from "./globalReducer";
import libarayReducer from "./libarayReducer";
import channelReducer from "./channelReducer";
import channelListReducer from "./channelListReducer";

export const mainReducer = combineReducers({

    profileData : profileReducer,
    upcomingData : upcomingReducer,
    recentlydata : recentlyReducer,
    trendingdata : trendingReducer,
    navData: navReducer,
    homeUpcomingData: homeUpcoming,
    homeTrendingData: homeTrending,
    homeRecentlyData: homeRecently,
    catagoryData: catagory,
    videoData: video,
    liveData:lives,
    searchData :Search,
    globalsearchData:globalReducer,
    libarayData:libarayReducer,
    channelData:channelReducer,
    channelList:channelListReducer
  

})
