import React, { useEffect, useState } from "react";
// import CategoriesData from "./CategoriesData";
import HttpService from "../../services/HttpService";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import useAxios from "../../axiosinstance";
import { setCatagoryArr } from "../../actions/catagoryAction";
import en from "../../localization/en";
import fr from "../../localization/fr";
import ger from "../../localization/ger";
import ita from "../../localization/ita";
import ara from "../../localization/ara";
import spa from '../../localization/spa'
import tha from '../../localization/tha';
import kor from '../../localization/kor';
import chn from '../../localization/chn';




export default function CategoriesList() {
  const [arr, setArr] = useState([]);
  const [title, setTitle] = useState("");
  const axiosinstance =useAxios()
  //const[item,setItem]=useState("");
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [check, setCheck] = useState(false);
  const [refrash, setRefrash] = useState(false);
  const[enable,setEnable] = useState(false)
  const[order,setOrder] = useState("")
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  
  const dispatch = useDispatch()
  // const index = arr.findIndex((object) => {
  //   return object.title === "Gynaecologist";
  // });
  let i = 0;
  const Edit = (id, title,order,enabled) => {
    // if(id){
    //   setCat()
    // }
    setTitle(title);
    setOrder(order)
    setShow(true);
    setId(id);
    setEnable(enabled)
    
  };
  const added = () => {
    if (title.length === 0) {
     
      toast.warning("Please Enter Category Name");
    } else {
      const json = JSON.stringify({
        title,
        enabled: 1,
        created_by: 5,
        order:order
      });
      axiosinstance.current.post("categories", json, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        toast.success(getTranslation("tag_added"));
        setRefrash(true)
      })
    }
    setTitle("");
    setOrder("")
  };
  
  const EditItem = () => {
    const json = JSON.stringify({
      name : title,
      enabled:1,
      order:order
    });
    axiosinstance.current
      .put(`updateCategories/${id}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast.success(getTranslation("tag_updated"));
        getResult1()
        setRefrash(1)
      })
      .catch(function (error) {
        // handle error
        toast.error(getTranslation("name_already_present"));
      });
    setShow(false);
    setTitle("");
    setOrder("")
  };
  const handleChange = (id, status, titles,e) => {
   

    let st=0;
    if(e==1){
      st=0

    }else{
      st=1
    }
    
    setCheck(!check);
    const json = JSON.stringify({
      enabled: e,
    });
    axiosinstance.current
      .put(`updateCategories/${id}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast.success(getTranslation("tag_updated"))
        getResult1()
      });
    
  };



  const getResult1 = ()=>{
    axiosinstance.current.get("categories?status=1").then((response) => {
      if (response) {
        
        dispatch(setCatagoryArr(response.data));
        GetResult()
        setRefrash(false)
       
      }
    });
  }


  const GetResult = () => {
    axiosinstance.current.get("categories").then((response) => {
      if (response) {
        setArr(response.data);
        // dispatch(setCatagoryArr(response.data));
        setRefrash(false)
      }
    });
  };
  
  useEffect(() => {
    if(title.length && order.length !==0){
      setEnable(false)
    }else{
      setEnable(true)
    }
    GetResult();
    
  }, [refrash,title,order]);



  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };



  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} />
      <div className="container-fluid">
        <div className="row">
          <div className="upcoming-head">
            {/* <h4> Manage Categories</h4> */}
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-5 col-lg-5 col-md-5 col-12 ">
            <div className="">
              <div className="card custom-card">
                <div className="card-body">
                  <h4 className="card-title">{getTranslation('add_tags')}</h4>
                  <hr></hr>
                  <div className="row justify-content-right">
                    <div className="row g-3  mt-2">
                      <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        <label
                          htmlFor="name"
                          className="col-form-label for-label tags"
                        >
                          {getTranslation('tags')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-8 col-lg-8 col-md-8 col-sm-8 col-8 for-input">
                        <input
                          type="text"
                          id="name"
                          name="title"
                          className="form-control "
                          defaultValue=""
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                        />
                      </div>
                    </div>

                    <div className="row g-3  mt-2">
                      <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        <label
                          htmlFor="name"
                          className="col-form-label for-label tags"
                        >
                        {getTranslation('order')}:{" "}
                        </label>
                      </div>
                      <div className="col-xxl-8 col-lg-8 col-md-8 col-sm-8 col-8 for-input">
                        <input
                          type="text"
                          id="name"
                          name="title"
                          className="form-control "
                          defaultValue=""
                          onChange={(e) => setOrder(e.target.value.replace(/[^0-9]/g, ""))}
                          value={order}
                        />
                      </div>
                    </div>


                    <div className="row justify-content-right">
                      <div className="col-12 ">
                        <div className="float-end mt-4">
                          {!show ? (
                            <button
                              className={enable==false?"btn premium-btn tags-button butttonn":"updatess-button"}
                              onClick={() => added()}
                              disabled={enable}
                            >
                              {getTranslation('add')}
                            </button>
                          ) : (
                            <button
                              className={enable==false?"btn premium-btn tags-button butttonn":"updatess-button"}
                              onClick={() => EditItem()}
                              disabled={enable}
                            >
                            {getTranslation('edit')}

                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-lg-7 col-md-7 col-12 mob_ch_list">
            <div className="">
              <div className="card custom-card">
                <div className="card-body">
                  <h4 className="card-title">{getTranslation('tags_list')}</h4>
                  <hr></hr>
                  <div className="table-responsive table-scroll">

                  <table className="table table-hover table-striped table-bordered  mt-3">
                    <thead>
                      <tr>
                        <th>{getTranslation('s_no')}</th>
                        <th>{getTranslation('title')}</th>
                        <th>{getTranslation('status')}</th>
                        <th>{getTranslation('actions')}</th>
                      </tr>
                    </thead>
                    {/* <tbody>
                    <tr>
                      <td>1</td> */}
                    <tbody>
                      {arr.map((data) => (
                        <tr key={data.id}>
                          <td>{++i}</td>
                          <td>{data.title}</td>
                          <td>
                            <div className="form-check form-switch">
                              {/* <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                defaultChecked={
                                  data.status === 1 ? true : false
                                }
                                onChange={(e) =>
                                  handleChange(data.id, check, data.title,data.status)
                                }
                              /> */}
                              <label className="toggle">
                                <input className="form-check-input toggle__input" role="switch" type="checkbox" id="flexSwitchCheckDefault" checked={data.status} value={data.status ? 0 : 1} onChange={(e) =>
                                  handleChange(data._id, check, data.title,e.target.value)
                                }/>
                                 <div class="toggle__fill">
                                <span className="toggles">I</span>
                                <span className="togglessss">o</span>
                              </div>
                            </label>
                              
                            </div>
                          </td>
                          <td>
                            <button
                              className="btn"
                              onClick={() => Edit(data._id, data.title,data.order,data.enabled)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}




















