const col6='col-6';
const navClass1='col-xxl-2 col-lg-2 col-md-2  d-none d-xxl-block d-lg-block';
const navClass2='col-xxl-1 col-lg-1 col-md-1  d-none d-xxl-block  d-lg-block';
const navClass3='col-xxl-10 col-lg-10 col-md-12 col-sm-12 pe-20';
const navClass4='col-xxl-11 col-lg-11 col-md-12 col-sm-12 pe-20';
const appStyle={
  
    col6,
    navClass1,
    navClass2,
    navClass3,
    navClass4,

}

export default appStyle;