import React, { useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import HttpService from "../../services/HttpService";
import en from '../../localization/en'
import fr from '../../localization/fr'
import ger from "../../localization/ger";
import ita from "../../localization/ita";
import ara from "../../localization/ara";
import spa from '../../localization/spa'
import tha from '../../localization/tha';
import kor from '../../localization/kor';
import chn from '../../localization/chn';


export default function Tab() {
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const [isActive, setActive] = useState();
  const [role, setRole] = useState("");



  useEffect(() => {
    if (profile.length !== 0) {
      setRole(profile[0].role_value);
    }
  }, [profile]);

  useEffect(() => {
    if (role === 40) {
      setActive("manage-patient-list");
    }
  }, [role]);


  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };
  





  return (
    <div className="container-fluid mt-3">
      <ul className="nav nav-tabs nav-justified nav_tab navv">
        {69 <= role && (
          <li className="nav-item" onClick={() => setActive("post-list")}>
            <NavLink
              className={`${
                isActive === "post-list" ? "nav-link active" : "nav-link nav_li "
              }`}
              to={{ pathname: "/post-list" }}
            >
              {getTranslation("manage_my_videos")}
            </NavLink>
          </li>
        )}

        {/* <li className="nav-item" >
                    <NavLink className={`${isActive === "admin-subscription" ? "nav-link active" : "nav-link nav_li"}`}
                        to={{ pathname: "/admin-subscription" }}
                        onClick={() => setActive('admin-subscription')}
                    >
                        Manage Subscription
                    </NavLink>
                </li> */}
        {100 <= role && (
          <li className="nav-item">
            <NavLink
              className={`${
                isActive === "organization"
                  ? "nav-link active"
                  : "nav-link nav_li"
              }`}
              to={{ pathname: "/manage-organization" }}
              onClick={() => setActive("organization")}
            >
              {getTranslation("manage_my_organizations")}
            </NavLink>
          </li>
        )}
        {70 === role && (
          <>
            <li className="nav-item">
              <NavLink
                className={`${
                  isActive === "manage-list"
                    ? "nav-link active"
                    : "nav-link nav_li"
                }`}
                to={{ pathname: `/manage-list/${profile[0].org_id}` }}
                onClick={() => setActive("manage-list")}
              >
                {getTranslation("manage_my_hcps")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`${
                  isActive === "manage-list-p"
                    ? "nav-link active"
                    : "nav-link nav_li"
                }`}
                to={{ pathname: `/manage-patient-lists` }}
                onClick={() => setActive("manage-list-p")}
              >
                {getTranslation("manage_org_patient")}
              </NavLink>
            </li>
          </>
        )}
        {40 === role && (
          <li className="nav-item">
            <NavLink
              className={`${
                isActive === "manage-patient-list"
                  ? "nav-link active"
                  : "nav-link nav_li"
              }`}
              to={{ pathname: `/manage-patient-list/${profile[0].id}` }}
              onClick={() => setActive("manage-patient-list")}
            >
              {getTranslation("manage_my_patients")}
            </NavLink>
          </li>
        )}

        {100 <= role && (
          <li className="nav-item">
            <NavLink
              className={`${
                isActive === "categories"
                  ? "nav-link active"
                  : "nav-link nav_li"
              }`}
              to={{ pathname: "/categories" }}
              onClick={() => setActive("categories")}
            >
              {getTranslation("manage_tags")}
            </NavLink>
          </li>
        )}

        {100 <= role && (
          <li className="nav-item">
            <NavLink
              className={`${
                isActive === "plan" ? "nav-link active" : "nav-link nav_li"
              }`}
              to={{ pathname: "/manage-plan" }}
              onClick={() => setActive("plan")}
            >
              {getTranslation("manage_my_plans")}
            </NavLink>
          </li>
        )}

{100 <= role && (
          <li className="nav-item">
            <NavLink
              className={`${
                isActive === "plan" ? "nav-link active" : "nav-link nav_li"
              }`}
              to={{ pathname: "/channeldata" }}
              onClick={() => setActive("plan")}
            >
             {getTranslation("Channelsdata")}
            </NavLink>
          </li>
        )}

{70 === role && (
          <li className="nav-item">
            <NavLink
              className={`${
                isActive === "plan" ? "nav-link active" : "nav-link nav_li"
              }`}
              to={{ pathname: "/channeldata" }}
              onClick={() => setActive("plan")}
            >
             {getTranslation("Channelsdata")}
            </NavLink>
          </li>
        )}


      </ul>
    </div>
  );
}
