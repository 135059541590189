import React from 'react'

export default function NotificationList() {
    return (
        <>
            <div className="container-fluid">
                <div className="upcoming-head mt-2" />
                <div className="row">
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex">
                                    <h4 className="card-title"> Notification List</h4>

                                    <hr />
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered  mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">S No.</th>
                                                <th scope="col">ID No.</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
