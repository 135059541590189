import React ,{useMemo, useState}from "react";
import Header from "../Component/Header";
import SideBar from "../Component/Sidebar";
import LiveList from "../Component/LiveList";
import { useSelector } from "react-redux/es/exports";
import appStyle from "../config/appStyle";
import Footer from "../Component/Footer";
// import UserService from "../services/UserService";
// import RenderOnAnonymous from "../Component/RenderOnAnonymous";
import RenderOnAuthenticated from "../Component/RenderOnAuthenticated";
// import { useEffect } from "react";
// import authConfig from "../config/authConfig";
// import { useNavigate } from "react-router-dom";
export default function Live() {
    // const navigate = useNavigate();
  // login check
// const [loader, setloader] = useState(false);
//   const check = function loginCheck() {
//     return UserService.isLoggedIn();
//   };
//   const abc = check();
//   useEffect(() => {
//       if (!abc) {
//         navigate("/loader");
//       }
//     },[])
 
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  document.title = "Akunah Med || Live";
  return (
    <>
    
        <Header></Header>
        <main className="mt-4"  dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
          <div className="container-fluid">
            <div className="row">
              <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
                <SideBar></SideBar>
              </div>
              <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
                <LiveList></LiveList>
                {/* Recently end */}
                <Footer></Footer>
              </div>
            </div>
          </div>
        </main>
  
    </>
  );
}
