
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "All videos",
  upcoming: "Upcoming",
  trending: "Trending",
  recently_added: "Recent videos",
  live: "Live",
  library: "My Favorites",
  view_by_catagories: "View by speciality ",
  loading: "loading...",
  filter: "Filter",
  profile: "Profile",
  profile_photo: "Profile photo",
  first_name: "First Name",
  last_name: "Last Name",
  update_profile: "UPDATE PHOTO",
  Update_Profiless: "UPDATE PROFILE",



  edit_profile: "Edit profile",
  date_of_birth: "Date of birth",
  address: "Address",
  registered_email: "Registered email",
  registered_phone: "Registered phone",
  subscription_key: "Subscription key",
  renew_key: "RENEW KEY",
  start_date: "Start date",
  end_date: "End date",
  no_more_data: "No more Data",
  search: "Search",
  logout: "Logout",
  view_profile: "View profile",
  notifications: "Notifications",
  Get_Premium: "Get Premium",
  App_Version: "App Version",
  Get_3_months_of_premium_for_free: "",
  Click_below_to_sign_up_for_premium_Akunah_MEd:
    "Click below to sign up for premium Akunah Med.",
  request_to_join: "Join HCP as a  patient",
  request_to_join_p: "Join Organization as a patient",
  request_to_join_h: "Join Organization",
  create_org: "Create Organization",
  lives: "live",
  plan: " All Plans",
  channels: "Home",


  akunahPublicP: "My health videos",//Not Done
  OrgPublicPatient: "My provider health videos",
  HCPPublicPatient: "My doctor videos",
  HCPPrivatePatient: "My exclusive videos",
  akunahExc: "Akunah Global HCP",
  akunahPublic: "Akunah Global Public",
  OrgPublic: "My Organization Public",
  myOrgPublic: "My Public",
  myOrgExc: "My Exclusive",
  OrgExc: "My Org HCP",
  mypublic: "my public",
  myprivate: "my Exclusive",
  // myhcppublic: "my hcp public",
  // myhcpprivate: "my hcp Exclusive",



  akunah_how_tos: "Akunah How To’s",
  Upload_a_Video: "Upload a Video",
  administration: "Administration",
  view_all: " View All",
  Channelsdata: "Channels",
  login_by: "Login by",
  upload_video: "Upload Video",
  type_of_content: "Type of content",
  title: "Title",
  thumb_imag1: "Thumbnail image (image-size 720X405)",
  thumb_imag2: "Thumbnail image (image-size 480X270)",
  video: "Video",
  link: "Streaming Link",
  tags: "Tags",
  organization: "Organization",
  tags_channels: "Channels",
  description: "Description",
  publish: "Publish",
  submit: "Submit",

  manage_my_videos: "Manage My Videos",
  manage_my_organizations: "Manage My Organizations",
  manage_my_hcps: "Manage My HCPs",
  manage_org_patient: "Manage Organization Patients",
  manage_my_patients: "Manage My Patient",
  manage_tags: "Manage Tags",
  manage_my_plans: "Manage My Plans",








  video_list: " Videos List",
  upload_videos: " Upload Videos",
  limit: "Limit",
  type: "Type",
  posted_by: "Posted By",
  organization: "Organization",
  s_no: "S No.",
  video_id: "Video Id",
  duration: "Duration",
  video_size: "Video size",
  actions: "Actions",
  add_a_organization: "Add a Organization",
  org_name: "Organization Name",
  size: "Size",
  watch_time: "Watch Time",
  sort: "Sort",
  status: "Status",
  organization_Id: "Organization Id",
  total_post_size: "Total Post Size",
  total_watch_time: "Total Watch Time",
  add_tags: "Add Tags",
  order: "Order",
  add: "Add",
  edit: "Edit",
  tags_list: "Tags List",
  add_plan: "Add Plan",
  plan_name: "Plan Name",
  number_of_hcp: "Number Of HCP",
  number_of_patient: "Number Of Patient",
  Limit: "Limit (GB)",
  plan_list: "Plans List",
  noofhcp1: "No. Of HCP",
  nopatient1: "No. Of Patient",
  save: "Save",
  add_channels: "Add Channels",
  name: "Name",
  role: "Visible To Role (s)",
  all: "Request Publish",
  users: "Users",
  user_type: "User Type",
  list_of_channels: "Channel List",
  event_Link: "Event Link",
  visible_till: "* Visible till",
  tags_channels: "Tags Channels",
  manage_hcps: "Manage HCP's",
  pending_hcp_req: "Pending HCP's Request",
  invite_hcp: "Invite HCP",
  email: "Email",
  delete_hcp: "Are you sure you wish to delete the HCP ?",
  no: "No",
  yes: "Yes",
  add_email: "Add Email",
  view_plans: "View Plans",
  activate_date: "Active Date",
  expiry_date: "Expiry Date",
  hcp: "HCP",
  patient: "Patient",
  total_hcp: "Total HCP",
  total_patient: "Total Patient",
  invite_admin: "Invite Admin",
  autoid: "Auto ID",
  delete_admin: "Are you sure you want to delete as admin ?",
  admin_user_email: "Admin User Email",
  view_admins: "View Admins",
  view_HCPs: "View HCP's",
  patient_list: "Patients List",
  pending_patient_req: "Pending Patients Request",
  invite_patient: "Invite Patient",
  patient_name: "Patient Name",
  delete_patient: "Are you sure you wish to delete the Patient ?",
  no_videos_found: "No videos found",
  not_found_trending: "No trending videos found",
  upload_video_popup: "You have to create your own organization to upload videos",
  create_org: "Create Organiation",
  list_of_user_profiles: "List of User profiles",
  all_speciality: "All speciality",
  channel_delete: "Are you sure you wish to delete this item?",
  req_for_create_org: "Request for Create Organization",
  cancel: "Cancel",
  send: "Send",
  akunahhandbook: "Akunah HandBook",
  help: "Akunah Help",
  subject: "Subject",
  message: "Message",
  no_live_video: "No live videos found",
  hcp_name: "HCP Name",
  expiry_days: "Expiry Days",
  send_req: "Send Request",
  search_by_speciality: "Search by speciality",
  no_request_data: "No Requested data",
  no_data_found: "No data found",
  approve_patient_list: "Approve Patients List",
  create_event: "Create an event",
  live_event: "Live event",
  switch_profile: "Switch profile",
  event_link: "Event Link",
  search_by_patient_email_id: "Search by patient & email ID",
  searchbyb_title: "Search by title",
  search_by_org_name: "Search by organization name",
  enter_email_address: "Enter email address",
  no_of_days: "No. of Days",



  //12/7/2023//need to transalte


  acceopt: "Accept",
  reject: "Reject",
  approved: "Aopproved",
  select: "Select",
  active: "Active",
  deactive: "Deactive",
  select_plan: "Select Plan",
  views: "Views",
  view_patients: "View Patients",
  valid_email: "Enter valid email !!!",
  is_already_created: "is already created",
  req_to_hcps: "Request to HCP'S",
  approve: "Approve",
  upload: "UPLOAD",
  search_by_name: "Serach by name",
  global_channel: "Global Channel",
  all_users: "All Users",
  patients: "Patients",
  HCPs: "HCPs",
  selected_users: "Selected Users",
  post_updated: "Post Updated",
  video_deleted: "Video Deleted Successfully",
  ascending: "Ascending",
  descending: "Descending",
  all_drop: "All",
  akunah: "Akunah",
  channel_added: "Channel Added",
  plan_status: "Plan Status Changed",
  tag_added: "Tag Added",
  tag_updated: "Tag Updated",
  hcp_deleted: "HCP Deleted Successfully",
  pending: "Pending",
  patient_deleted: "Patient Deleted Successfully",
  is_new_patient: "is new patient added",
  new_plan_added: "New Plan Added",
  org_added: "Organization Added",
  date: "Date",
  missing_subj: "Subject is missing",
  missing_message: "Message is missing",
  select_user: "Select User",
  field_req: "This field is required",
  select_org: "Select Organization",
  is_already_org: "is already present in organization",
  org_error: "This Organization is already created. Please join instead of creating new one.",
  email_sent: "Email sent successfully",
  content_body: "Content body is missing",
  not_sent_email: "email not sent",
  no_user_found: "No user found",
  profile_update: "Profile Update",
  req_sent: "Request Sent",
  req_to_publish: "Request To Publish",
  no_req: "No Request",
  akunah_global_public: "Akunah Global Public",
  akunah_global_patient: "Akunah Global Patient",
  enter_org_name: "please enter organization name", //Not Done
  select_a_plan: "Please select a plan",//Not Done
  Plan: "Plan",
  admin_deleted: "Admin Deleted",//Not Done
  email_added: "Email Added",//Not Done
  email_not_added: "Email Not Added",//Not Done
  plan_not_added: "Plan Not Added",//Not Added
  hcp_not_delete: "HCP Not Deleted",//Not Done
  hcp_approved: "HCP Approved Succesfully",//Not Done
  hcp_reject: "HCP Rejected",//Not Done
  channel_updated: "Channel Updated",//Not Done
  channel_deleted: "Channel Deleted Successfully",//Not Done
  limit_error: "Limit should be greater than 0",//Not Done
  days: "Days",//Not Done
  gb: "GB",//Not Done
  plan_created: "Plan Created",//Not Done
  rejected: "거부됨",
  expired: "만료됨",
  search_by_hcp_name: "Search by HCP name",//Not Done





  name_already_present: "Name is already present",//Not Done
  already_req_hcp: "You Already Request To This HCP",//Not Done
  already_req_org: "You Already Request To This Organization",//Not Done


  video_not_delete: "동영상이 삭제되지 않음",

  video_updated_success: "동영상이 성공적으로 업데이트되었습니다.",
  Doctor: "의사",
  Patient: "Patient",
  favorite: "가장 좋아하는",
  unfavourite: "마음에 들지 않는",

  admin: "관리자",
  org_admin: "조직 관리자",


  video_upload_success: "Video Uploaded Successfully",//Not Done
  live_event_create_success: "Live Event Create Successfully", //Not Done

  privacy:"Your privacy By clicking 'Accept' all cookies, you agree akunah Med can store cookies on your device and disclose information in accordance with our",
  cookie:"cookie policy",
  
  my_profiles:"My Profiles",

  not_found_recent:"최근 동영상이 없습니다."


};
