import en from "./en";
import fr from "./fr";
import ger from "./ger";
import chn from "./chn";
import ita from "./ita";
import spa from "./spa";
import ara from "./ara";
import tha from "./tha";
import kor from './kor'

export default {
  en,
  fr,
  ger,
  chn,
  ita,
  spa,
  ara,
  tha,
  kor
};
