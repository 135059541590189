import React from 'react'
import Skeleton from 'react-loading-skeleton'
export default function smallSkeleton() {
  return (
    <div>
      <div className='row'>
        <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
          <Skeleton height={"25vh"} />
          <p className='mt-2 mb-2'></p>

        </div>
        <div className='col-xxl-8 col-lg-8 col-md-8 col-sm-6 col-12'>
          <Skeleton height={20}></Skeleton>
          <p className='mt-2 mb-2'></p>
          <Skeleton height={20}></Skeleton>
          <p className='mt-2 mb-2'></p>
          <Skeleton height={20}></Skeleton> <p className='mt-2 mb-2'></p>
          <Skeleton height={50}></Skeleton>
          <p className='mt-2 mb-2'></p>

        </div>
        <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
          <Skeleton height={"25vh"} />
          <p className='mt-2 mb-2'></p>

        </div>
        <div className='col-xxl-8 col-lg-8 col-md-8 col-sm-6 col-12'>
          <Skeleton height={20}></Skeleton>
          <p className='mt-2 mb-2'></p>
          <Skeleton height={20}></Skeleton>
          <p className='mt-2 mb-2'></p>
          <Skeleton height={20}></Skeleton> <p className='mt-2 mb-2'></p>
          <Skeleton height={50}></Skeleton>
          <p className='mt-2 mb-2'></p>

        </div>
        



      </div>
    </div>
  )
}
