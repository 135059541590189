/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Avatar from "react-avatar";
import ReactPlayer from "react-player";
import useAxios from "../axiosinstance";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';



export default function details() {
  const navigate = useNavigate()
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const { id } = useParams();
  const { sessionid } = useParams();
  const axiosinstance = useAxios()
  let email = "";
  if (profile.length !== 0) {
    email = profile[0].email;
  }
  const akTokenMed = localStorage.getItem("akTokenMed")
  console.log("profile", profile)

  document.title = "Akunah Med || Detail";
  // eslint-disable-next-line react-hooks/rules-of-hooks

  // if(locationid){
  //   postId=locationid
  // }else{
  //   postId=ids
  // }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [post, setPost] = useState({});
  const [show1, setShow] = useState(true);
  const [tim, settim] = useState(false);
  const [witchTime, setwitchTime] = useState(true);
  const [wetch, setWetch] = useState("");
  const [duration, setDuration] = useState("")
  let x = post.status;
  let y = post.like_status;
  const [status, setStatus] = useState(post.status);
  const [loop, setLoop] = useState(false);
  const [like, setlike] = useState(y);
  const [limit, setLimit] = useState(16);
  const [posted_by, setPostedBy] = useState({});
  const [played, setPlayed] = useState(0);
  const [url, setUrl] = useState("")
  const [loader1, setloader1] = useState(false);

  useEffect(() => {
    if (y !== undefined) {
      setlike(y);
    }
  }, [post]);

  const videoRef = useRef(null);


  const [alldata, setAlldata] = useState([]);
  const location = useLocation();
  let locationid = location.state?.id;
  // let sessionId = location.state?.sessionId
  const [postId, setIds] = useState(id);
  const [sessId, setSessId] = useState(sessionid);



  // let url2 = 
  // const [url22, setUrl22] = useState(`${process.env.REACT_APP_APIURL}/stream_file?sessionId=${sessId}`)
  useEffect(() => {
    if (x !== undefined) {
      setStatus(x);
    }
  }, [post]);


  const [catId, setCatId] = useState("");

  const [preid, setPrevid] = useState(locationid);



  useEffect(() => {
    const timer = setTimeout(() => {
      settim(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  let tt = Math.floor(played)





  // window.addEventListener("beforeunload", (event) => {
  //   event.preventDefault()

  //   const json = JSON.stringify({
  //     video_id: postId,
  //     watch_time: Math.floor(witchTime),
  //   });


  //   axiosinstance.current
  //     .post(`addWatchTime?ak_id=${profile[0]?.id}`, json, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {

  //     });

  // });



  useEffect(() => {
    axiosinstance.current.get(`stream_file?sessionId=${sessId}&ak_id=${profile[0]?.id}`).then((response) => {
      if (response) {
        setUrl(response?.data.data)
        // setPost(response?.data.data[0]);
        // setPostedBy(response.data.data[0].posted_by)
        setShow(true);
      }
    }).catch((err) => {

    })
  }, [postId, tim, profile]);

  console.log("postId", postId)

  const getPostLinkResult = () => {
    axiosinstance.current.get(`postlink?id=${postId}&ak_id=${akTokenMed}`).then((response) => {
      if (response) {
        setPost(response.data.data[0]);
        setPostedBy(response.data.data[0].posted_by)
        setShow(true);
      }
    });
  }



  useEffect(() => {
    getPostLinkResult()
  }, [postId,like]);




  const getAllcategoryVideo = () => {
    try {
      axiosinstance.current
        .get(`allCategoryPost?id=${postId}&limit=${limit}&offset=${0}&ak_id=${profile[0]?.id}`)
        .then((response) => {
          if (response.status === 200) {
            setloader1(true)
            setAlldata(response.data.data);
          }
        });

    } catch (error) {
      console.log(error)
    }
  }


  const fetchData = () => {
    setLimit(limit + 16);
    axiosinstance.current
      .get(`allCategoryPost?id=${postId}&limit=${16}&offset=${limit}&ak_id=${profile[0]?.id}`)
      .then((response) => {
        let res = response.data.data;
        setTimeout(() => {
          setAlldata(alldata.concat(res));
        }, 500);
      });
  }


  useEffect(() => {
    getAllcategoryVideo()
  }, [postId, profile])












  let postid = ""


  const addlibrary = (id) => {
    const json = JSON.stringify({
      id,
    });

    axiosinstance.current
      .post(`addtoLibrary?ak_id=${profile[0]?.id}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
      });
  };

  const addLike = (s) => {
    if (s === false) {
      const json = JSON.stringify({
        video_id: postId
      });

      axiosinstance.current
        .post(`like_video?ak_id=${profile[0]?.id}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setlike(true);
          // getPostLinkResult()
        });
      setlike(true);
    } else {
      const json = JSON.stringify({
        video_id: postId
      });

    }


  };


  const deletelibrary = (id) => {
    axiosinstance.current.delete(`deleteFromLibrary/${id}?ak_id=${profile[0]?.id}`);
  };

  const action = (e) => {
    setStatus(e);
    if (!e) {
      deletelibrary(post.id);
    }
    if (e) {
      addlibrary(post.id);
    }
  };

console.log("likelike",like)

  // useEffect(() => {
  //   window.addEventListener('popstate', (event) => {
  //     const json = JSON.stringify({
  //       video_id: postId,
  //       watch_time: Math.floor(witchTime),
  //     });
  //     axiosinstance.current
  //       .post(`addWatchTime?ak_id=${profile[0]?.id}`, json, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //       .then((response) => {

  //       });
  //   })
  // }, [])




  const Handleclick = (id, link, sessionId) => {
    if (link) {
      window.open(link, "_blank")
    } else {
      setIds(id);
      setPrevid(id);
      setLimit(16);
      setSessId(sessionId)
      navigate(`/details/${id}/${sessionId}`)

      const json = JSON.stringify({
        video_id: postId,
        watch_time: Math.floor(witchTime),
      });
      axiosinstance.current
        .post(`addWatchTime?ak_id=${profile[0]?.id}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {

        });
    }

  };

  ;
  //  category loop
  let map1 = post.cat;
  let dd = [];
  if (map1) {
    dd = map1;
  }
  // date format
  let date = post.posted_on;
  let day, month, year;
  if (date) {
    day = date.slice(0, 2);
    month = date.slice(3, 5);
    year = date.slice(6, Infinity);
  }
  const event = new Date(Date.UTC(year, month - 1, day));

  // const options = { year: "numeric", month: "short", day: "numeric" };

  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const normalDate = new Date(date).toLocaleDateString(undefined, options)




  function convertSecondsToTimeString(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }





  const handleDuration = (duration) => {

    const timeString = convertSecondsToTimeString(duration);
    setDuration(timeString)
    let json = {
      duration: timeString
    }
    axiosinstance.current
      .put(`updateduration?id=${postId}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
      });
  };


  useEffect(() => {

  }, [duration])

  const handleContextMenu = (e) => {
    e.preventDefault();
    videoRef.current.volume = videoRef.current.volume === 0 ? 1 : 0;
    return false;
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    setDuration(video.duration);
    let time = convertSecondsToTimeString(video.duration)
    let json = {
      duration: time
    }
    axiosinstance.current
      .put(`updateduration?id=${postId}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
      });

  };


  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };



  return (
    <>

      <div className="clearfix"></div>
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-8 col-sm-6 col-12">
          <div className="sticky">
            {show1 && (
              <div className="cross" onContextMenu={handleContextMenu}>


                <video style={{ width: '100%', maxWidth: '100%',maxHeight:"60vh" }} controls autoPlay controlsList="nodownload" ref={videoRef} key={url} onPause={handleTimeUpdate}>
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>


              </div>
            )}
            {!show1 && (
              <div className="container-fluid bg-light">
                <div className="row">
                  <div className="col-12 p-0">
                    <div
                      className=""
                      style={{
                        height: "50vh",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        paddingTop: "10rem",
                      }}
                    >
                      <img
                        src="Assets/image/akunah.gif"
                        alt="loader"
                        width={100}
                      ></img>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="vid_title upcoming-head mt-3" >
              {dd.map((item) => (

                <Link
                  className="vid_category cursor"
                  style={{}}
                  to={`/search?cat_id=${btoa(item.id)}`}
                  state={{ from: item.id }}
                >
                  <span
                    className="vid_category cursor"
                    style={{}}
                  >
                    #{item.name}
                  </span>
                </Link>
              ))}
              <h4 className="pt-2 pb-2">{post.title} </h4>
            </div>
            <div className="vid_detail">
              <div className="row">
                <div className="col-xl-8 col-md-8 col-12">
                  <div className="vid_para">
                    <span className="text_black">
                      {" "}
                      {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-eye me-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg> */}
                      {post.total_views} {getTranslation("views")} |{" "}
                      {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-calendar-check me-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                              </svg> */}
                      {normalDate}
                    </span>
                    <div className="trending-heading d-flex pb-2" >
                      <div className="trending-img me-2">
                        {post.profile === "" ? (
                          <Avatar
                            name={post.posted_by.name.slice(0, 1)}
                            size="40"
                            maxInitials="2"
                            round={true}
                            fgColor="#EA702B"
                            color="#F3CDB8"
                          />
                        ) : (
                          <img
                            src={post.profile}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div className="mt-auto">
                        <p className="text-uppercase">
                          {posted_by.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4 col-12">
                  <div className="d-flex float-end">
                    {like === false && (
                      <div
                        className="like_"
                        onClick={() => addLike(false)}
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="I like this"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-hand-thumbs-up"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                        </svg>

                        <span className="ms-1">{post.total_likes}</span>
                      </div>
                    )}
                    {like === true && (
                      <div
                        className="like_"
                        onClick={() => addLike(true)}
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="I like this"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-hand-thumbs-up-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                        </svg>

                        <span className="ms-1">{post.total_likes}</span>
                      </div>
                    )}

                    {/* <div
                              className="like_ ms-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Share"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-cursor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z" />
                              </svg>
                              <span className="ms-1">Share</span>
                            </div> */}
                    <div
                      className="like_ ms-3"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Add to favorite"
                    >
                      {status === false && (
                        <div
                          className="d-flex"
                          onClick={() => action(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-heart me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                          </svg>{" "}
                          {getTranslation("favorite")}
                        </div>
                      )}
                      {status === true && (
                        <div
                          className="d-flex"
                          onClick={() => action(false)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-heart-fill me-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                            />
                          </svg>{" "}
                          {getTranslation("unfavourite")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-auto">
                  <p
                    className="text-capitalize  text"
                    style={{ textAlign: "justify" }}
                  >
                    {post.description == null ? (
                      <p className="mt-2">
                        {" "}
                        <b className="text_black"></b>{" "}
                      </p>
                    ) : (
                      post.description
                    )}
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>

          </div>
        </div>

        <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-12">
          <InfiniteScroll
            dataLength={alldata.length}
            next={fetchData}
            hasMore={true}
            loader={
              <>

                {
                  !loader1 ? <div className="text-center">{getTranslation("loading")}</div> : (
                    <>
                      {
                        alldata.length === 0 ? (
                          <div className="text-center">{getTranslation("no_data_found")}</div>
                        ) : ""
                      }
                    </>

                  )
                }
              </>

            }
          >
            {alldata.map((item) => (
              <div
                onClick={() => Handleclick(item.id, item.link, item.sessionId)}
                style={{ cursor: "pointer" }}
              >
                <div className="row">
                  <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                    <div className="trending-boxs rounded-0">
                      <img
                        src={item.medium}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="play_">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={50}
                          height={50}
                          fill="currentColor"
                          className="bi bi-play-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                        </svg>
                      </div>
                    </div>
                    {
                      item.duration ?
                        <div className="side_time_trend">
                          <p>{item.duration}</p>
                        </div> : (null)
                    }

                  </div>
                  <div className="col-xxl-7 col-lg-7 col-md-7 col-sm-7 col-12">
                    <div className="">
                      <div className="side_heading mt-auto">
                        <h4 style={{textTransform:"capitalize"}}>{item.title}</h4>
                        <p className="text_black">
                          {item.total_views} {getTranslation("views")} |{" "}
                          <span>{item.posted_on}</span>{" "}
                        </p>
                        <div className="trending-heading d-flex">
                          <div className="side-img me-2 ">
                            {item.profile === "" ? (
                              <Avatar
                                name={item.posted_by.name.slice(0, 1)}
                                size="40"
                                maxInitials="2"
                                round={true}
                                fgColor="#EA702B"
                                color="#F3CDB8"
                              />
                            ) : (
                              <img
                                src={item.profile}
                                alt=""
                                className="img-fluid"
                              />
                            )}
                          </div>
                          <div className="mt-2">
                            <p className="text-uppercase">
                              {item.posted_by.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>

    </>
  );
}
