
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "Inicio",
  upcoming: "Proximamente",
  trending: "En tendencia",
  recently_added: "Agregados recientemente",
  live: "En vivo",
  library: "Mis favoritos",
  view_by_catagories: "ver por especialidad",
  loading: "cargando...",
  filter: "Filtrar",
  profile: "Perfil",
  profile_photo: "Foto de perfil",
  first_name: "Nombre",
  last_name: "Apellido",
  update_profile: "Actualizar perfil",
  Update_Profiless: "ACTUALIZACIÓN DEL PERFIL",

  edit_profile: "Modificar perfil",
  date_of_birth: "Fecha de nacimiento",
  address: "Direccion",
  registered_email: "E-mail registrado",
  registered_phone: "Telefono registrado",
  subscription_key: "Clave de suscripcion",
  renew_key: "Renovar clave",
  start_date: "Fecha de inicio",
  end_date: "Fecha de termino",
  no_more_data: "No mas datos",
  search: "Buscar",
  logout: "Salir",
  view_profile: "Ver perfil",
  notifications: "Notificaciones",
  Get_Premium: "Obtener Premium",
  App_Version: "Version de App",
  Get_3_months_of_premium_for_free: "Obtener 3 meses de Premium gratis",
  Click_below_to_sign_up_for_premium_Akunah_MEd: "Presiona abajo para suscribirse a Akunah Med Premium",
  request_to_join: "Unirse a HCP como paciente",
  request_to_join_p: "Unirse a una organizacion como paciente",
  request_to_join_h: "Unirse a organizacion",
  lives: "En vivo",
  plan: "Todos los planes",
  channels: "Inicio",

  akunahPublicP: "mis videos de salud", //Not Done
  OrgPublicPatient: "Videos de mi proveedor de salud",
  HCPPublicPatient: "Videos de mi doctor",
  HCPPrivatePatient: "Mis videos exclusivos",
  akunahExc: "Akunah Global Profesional de la Salud",
  akunahPublic: "Público mundial de Akunah",
  OrgPublic: "Mi organización pública",
  OrgExc: "Mi organización HCP",
  mypublic: "mi publico",
  myprivate: "mi Exclusivo",
  // myhcppublic: "f-my hcp public",
  // myhcpprivate: "f-my hcp Exclusive",
  myOrgPublic: "Mis publicos",
  myOrgExc: "Mi exclusivos",




  akunah_how_tos: "Instrucciones de Akunah",
  Upload_a_Video: "Subir video",
  Channelsdata: "Canales",
  administration: "Administración",
  view_all: "Ver todas",
  login_by: "Iniciar sesión por",
  upload_video: "Subir vídeo",
  type_of_content: "tipo de contenido",
  title: "Dítulo",
  thumb_imag1: "Imagen en miniatura (tamaño 720X405)",
  thumb_imag2: "Imagen en miniatura (tamaño 480X270)",
  video: "Video",
  link: "Enlace de transmisión",
  tags: "Etiquetas",
  description: "Descripción",
  publish: "Publicar",
  submit: "Envío",




  manage_my_videos: "Administrar mis videos",
  manage_my_organizations: "Administrar mis organizaciones",
  manage_my_hcps: "Administrar mis HCP",
  manage_org_patient: "Gestionar Organización del Paciente",
  manage_my_patients: "Administrar mi paciente",
  manage_tags: "Administrar etiquetas",
  manage_my_plans: "Administrar mis planes",











  video_list: "Lista de vídeos",
  upload_videos: "Subir vídeos",
  limit: "Límite",
  type: "Tipo",
  posted_by: "Publicado por",
  organization: "Organización",
  s_no: "S No.",
  video_id: "Identificación de video",
  duration: "Duración",
  video_size: "Tamaño de vídeo",
  actions: "Comportamiento",
  add_a_organization: "Agregar una organización",
  org_name: "Nombre de la Organización",
  size: "Tamaño",
  watch_time: "Ver tiempo",
  sort: "Clasificar",
  status: "Estado",
  organization_Id: "Identificación de la organización",
  total_post_size: "Tamaño total de la publicación",
  total_watch_time: "Tiempo total de visualización",
  add_tags: "Agregar etiquetas",
  order: "Orden",
  add: "Agregar",
  edit: "Editar",
  tags_list: "Lista de etiquetas",
  add_plan: "Agregar plan",
  plan_name: "Nombre del plan",
  number_of_hcp: "Número de HPC",
  number_of_patient: "Número de paciente",
  Limit: "Límite (GB)",
  plan_list: "Lista de planes",
  noofhcp1: "Nº de profesionales de la salud",
  nopatient1: "Nº de paciente",
  save: "Guardar",
  add_channels: "Agregar canales",
  name: "Nombre",
  role: "Visible para los roles",
  all: "Solicitar publicación",
  users: "Usuarios",
  user_type: "Tipo de usuario",
  list_of_channels: "Lista de canales",
  event_Link: "Enlace al evento",
  visible_till: "* Visible hasta",
  tags_channels: "Etiquetas de Canales",
  manage_hcps: "Administrar HCP",
  pending_hcp_req: "Solicitud pendiente del HCP",
  invite_hcp: "Invitar al HCP",
  email: "Correo electrónico",
  delete_hcp: "¿Está seguro de que desea eliminar el HCP?",
  no: "No",
  yes: "Sí",
  add_email: "Agregar correo electrónico",
  view_plans: "Ver planes",
  activate_date: "Fecha activa",
  expiry_date: "Fecha de caducidad",
  hcp: "HCP",
  patient: "Paciente",
  total_hcp: "PS totales",
  total_patient: "Paciente Total",
  invite_admin: "Invitar a administrador",
  autoid: "identificación automática",
  delete_admin: "¿Está seguro de que desea eliminar como administrador?",
  admin_user_email: "Correo electrónico del usuario administrador",
  view_admins: "Ver administradores",
  view_HCPs: "Ver HCP",
  patient_list: "Lista de pacientes",
  pending_patient_req: "Solicitud de pacientes pendientes",
  invite_patient: "Invitar paciente",
  patient_name: "Nombre del paciente",
  delete_patient: "¿Está seguro de que desea eliminar al paciente?",
  no_videos_found: "No se han encontrado vídeos",
  not_found_trending: "No se encontraron videos populares",
  upload_video_popup: "Tienes que crear tu propia organización para subir vídeos",
  create_org: "Crear organización",
  list_of_user_profiles: "Lista de perfiles de usuario",
  all_speciality: "Todas las especialidades",
  channel_delete: "¿Está seguro de que desea eliminar este elemento?",
  req_for_create_org: "Solicitud de creación de organización",
  cancel: "Cancelar",
  send: "Enviar",
  akunahhandbook: "Manual de Akunah",
  help: "Ayuda Akuna",
  subject: "Sujeto",
  message: "Mensaje",
  no_live_video: "No se han encontrado vídeos en directo",
  hcp_name: "Nombre del HCP",
  expiry_days: "Días de caducidad",
  send_req: "Enviar petición",
  search_by_speciality: "Buscar por especialidad",
  no_request_data: "Sin datos solicitados",
  no_data_found: "No se han encontrado datos",
  approve_patient_list: "Aprobar lista de pacientes",
  create_event: "Crear un evento",
  live_event: "Evento en vivo",
  switch_profile: "Cambiar perfil",
  event_link: "Enlace al evento",
  search_by_patient_email_id: "Buscar por ID de paciente y correo electrónico",
  searchbyb_title: "Buscar por título",
  search_by_org_name: "Buscar por nombre de la organización",
  enter_email_address: "Introducir la dirección de correo electrónico",
  no_of_days: "Nº de días",


  //12/7/2023//

  acceopt: "Aceptar",
  reject: "Rechazar",
  approved: "Aprobado",
  select: "Escoger",
  active: "Activo",
  deactive: "Inactivo",
  select_plan: "Seleccionar plan",
  views: "Vistas",
  view_patients: "Ver pacientes",
  valid_email: "Introduzca correo electrónico válido !!!",
  is_already_created: "ya está creado",
  req_to_hcps: "Solicitud a HCP'S",
  approve: "Aprobar",
  upload: "SUBIR",
  search_by_name: "Buscar por nombre",
  global_channel: "Canal Global",
  all_users: "Todos los usuarios",
  patients: "Pacientes",
  HCPs: "HCPs",
  selected_users: "Usuarios seleccionados",
  post_updated: "Post Actualizado",
  video_deleted: "Video eliminado con éxito",
  ascending: "Ascendente",
  descending: "Descendente",
  all_drop: "Todo",
  akunah: "Akunah",
  channel_added: "Canal añadido",
  plan_status: "Estado del plan cambiado",
  tag_added: "Etiqueta añadida",
  tag_updated: "Etiqueta actualizada",
  hcp_deleted: "HCP eliminado correctamente",
  pending: "Pendiente",
  patient_deleted: "Paciente eliminado correctamente",
  is_new_patient: "¿Se ha agregado un nuevo paciente?",
  new_plan_added: "Nuevo plan añadido",
  org_added: "Organización añadida",
  date: "Fecha",
  missing_subj: "Asunto no ingresado",
  missing_message: "Mensaje no ingresado",
  select_user: "Seleccionar usuario",
  field_req: "Este campo es obligatorio",
  select_org: "Seleccionar organización",
  is_already_org: "ya está presente en la organización",
  org_error: "Esta organización ya está creada. Por favor, únase en lugar de crear uno nuevo.",
  email_sent: "Correo electrónico enviado correctamente",
  content_body: "Falta el cuerpo del contenido",
  not_sent_email: "Correo electrónico no enviado",
  no_user_found: "No se encontró ningún usuario",
  profile_update: "Actualización de perfil",
  req_sent: "Solicitud enviada",
  req_to_publish: "Solicitud de publicación",
  no_req: "Sin solicitud",
  akunah_global_public: "Akunah Global Public",
  akunah_global_patient: "Akunah Global Paciente",
  enter_org_name: "por favor ingrese el nombre de la organización", 
  select_a_plan: "Por favor seleccione un plan",
  Plan: "Plan",
  admin_deleted: "Administrador eliminado",
  email_added: "Correo electrónico agregado",
  email_not_added: "Correo electrónico no agregado",
  plan_not_added: "Plan no agregado",
  hcp_not_delete: "Profesional de la salud no eliminado",
  hcp_approved: "Profesional de la salud  aprobado con éxito",
  hcp_reject: "Profesional de la salud rechazado",
  channel_updated: "Canal actualizado",
  channel_deleted: "Canal eliminado con éxito",
  limit_error: "El límite debe ser mayor que 0",
  days: "Días",
  gb: "GB",
  plan_created: "Plan creado",
  rejected: "rechazado",
  expired: "Caducado",
  search_by_hcp_name: "Buscar por nombre de HCP",



  name_already_present: "El nombre ya está presente",
  already_req_hcp: "Ya solicitó a este Profesional de la salud ",
  already_req_org: "Ya solicitó a esta organización",
  video_not_delete:"Video no eliminado",
  video_updated_success: "Vídeo actualizado con éxito",

  Doctor:"Doctor",

  Patient: "Paciente",
  favorite: "favorito",
  unfavourite:"Me gusta",

  admin: "Administrador",
  org_admin: "Administrador de la organización",




  video_upload_success:"Video Uploaded Successfully",//Not Done
  live_event_create_success:"Live Event Create Successfully", //Not Done




  privacy: "Su privacidad Al hacer clic en 'Aceptar' todas las cookies, acepta que akunah Med puede almacenar cookies en su dispositivo y divulgar información de acuerdo con nuestros",
  cookie: "política de cookies",
  my_profiles:"Mis perfiles",


  not_found_recent:"No se encontraron videos recientes"










































};