
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "Toutes les vidéos",
  upcoming: "Prochainement",
  trending: "Populaire",
  recently_added: "Ajouté récemment",
  live: "Evènements en direct",
  library: "Mes Favoris",
  view_by_catagories: "Voir par spécialité",
  loading: "chargement...",
  filter: "Filtre",
  profile: "Profil",
  profile_photo: "Phot de profil",
  first_name: "Prénom",
  last_name: "Nom",
  update_profile: "Mettre à jour le profil ",
  Update_Profiless: "Mettre à jour le profil",

  edit_profile: "Modifier le profil ",
  date_of_birth: "Date de naissance",
  address: "Adresse",
  registered_email: "Email d'inscription",
  registered_phone: "Téléphone d'inscription",
  subscription_key: "Clé de subscription",
  renew_key: "RENOUVELLER LA CLE",
  start_date: "Date de début",
  end_date: "Date de fin",
  no_more_data: "Plus de données",
  search: "Rechercher",
  logout: "Se déconnecter",
  view_profile: "Voir le profil",
  notifications: "Notifications",
  Get_Premium: "S'abonner Premium ",
  App_Version: "Version",
  Get_3_months_of_premium_for_free: "Essayer Premium gratuitement pendant 3 mois",
  Click_below_to_sign_up_for_premium_Akunah_MEd: "Cliquez ci-dessous pour s'abonner à Akunah Med Premium",
  request_to_join: "M'abonner à un praticien en tant que patient ",
  request_to_join_p: "M'abonner à un établissement en tant que patient ",
  request_to_join_h: "M'abonner à un établissement",
  lives: "En direct",
  plan: "Tous les plans",
  channels: "Accueil",

  akunahPublicP: "Mes vidéos santé", //Not Done
  OrgPublicPatient: "Mes vidéos Etablissement",
  HCPPublicPatient: "Mes vidéos Praticen",
  HCPPrivatePatient: "Mes vidéos Exclusives",
  akunahExc: "Akunah Praticien Global",
  akunahPublic: "Akunah Public Global ",
  OrgPublic: "Chaîne publique de mon Etablissement",
  OrgExc: "Ma Etablissement Praticien",
  mypublic: "F-my public",
  myprivate: "F-my private",
  // myhcppublic: "f-my hcp public",
  // myhcpprivate: "f-my hcp Exclusive",
  myOrgPublic: "Ma chaîne publique",
  myOrgExc: "Mes Exclusivités",
  akunah_how_tos: "Akunah Comment Faire",
  Upload_a_Video: "Publier une vidéo ",
  Channelsdata: "Chaînes",


  administration: "Administration",
  view_all: "Tout voir",
  login_by: "Se connecter en tant que",
  upload_video: "Télécharger une video",
  type_of_content: "F-Type of content",
  title: "Titre",
  thumb_imag1: "Image vignette (taille de l'image 720x405)",
  thumb_imag2: "Image vignette (taille de l'image 480x270)",
  video: "Vidéo",
  tags: "Tags",
  link: "Lien de streaming",
  organization: "Etablissement",
  description: "Description",
  publish: "Publier",
  submit: "Envoyer",
  manage_my_videos: "Gérer mes vidéos",
  manage_my_organizations: "Gérer mes Etablissements",
  manage_my_hcps: "Gérer mes Etablissements",
  manage_org_patient: "Gérer mes Etablissements Patients",
  manage_my_patients: "Gérer mes patients",
  manage_tags: "Gérer les tags",
  manage_my_plans: "Gérer mes plans",







  video_list: "Liste de vidéos",
  upload_videos: "Charger des vidéos",
  limit: "Limite",
  type: "Type",
  posted_by: "Posté par",
  organization: "Etablissement",
  s_no: "S No.",
  video_id: "Vidéo ID",
  duration: "Durée",
  video_size: "Taille de la vidéo",
  actions: "Actions",
  add_a_organization: "Ajouter un Etablissement",
  org_name: "Etablissement",
  size: "Taille",
  watch_time: "Durée de visualisation",
  sort: "Filtrer par",
  status: "Statut",
  organization_Id: "Etablissement ID",
  total_post_size: "Taille total de la publication",
  total_watch_time: "Temps de visualisation total",
  add_tags: "Ajouter des tags",
  order: "Commander",
  add: "Ajouter",
  edit: "Modifier",
  tags_list: "Liste de tags",
  add_plan: "Ajouter un Plan",
  plan_name: "Nom du Plan",
  number_of_hcp: "Nombre d'Etablissements",
  number_of_patient: "Nombre de Patients",
  Limit: "Limite (Gb)",
  plan_list: "Liste de plans",
  noofhcp1: "Nombre de Praticiens",
  nopatient1: "Nombre de Patients",
  save: "Sauvegarder",
  add_channels: "Ajouter des chaînes",
  name: "Nom",
  role: "Visibles par les role(s)",
  all: "Demander la publication",
  users: "Utilisateurs",
  user_type: "Type d'utilisateur",
  list_of_channels: "Liste de chaînes",
  event_Link: "Lien de l'évènement",
  visible_till: "* Visible jusqu'à",
  tags_channels: "Tags des chaînes",
  manage_hcps: "Gérer les Praticiens",
  pending_hcp_req: "Demande de Praticiens en attente",
  invite_hcp: "Invitez un Praticien",
  email: "Email",
  delete_hcp: "Etes-vous certain de vouloir supprimer ce praticien ?",
  no: "Non",
  yes: "Oui",
  add_email: "Ajouter une adresse email",
  view_plans: "Voir les plans",
  activate_date: "Date d'activation",
  expiry_date: "Date d'expiration",
  hcp: "Praticien",
  patient: "Patient",
  total_hcp: "Praticien Total",
  total_patient: "Patient Total",
  invite_admin: "Invitez un Administrateur",
  autoid: "Identification automatique",
  delete_admin: "Etes-vous sûr(e) de vouloir supprimer en tant au'admin ?",
  admin_user_email: "Email de l'utilisateur admin",
  view_admins: "Voir les Administrateurs",
  view_HCPs: "Voir les Praticiens",
  patient_list: "Liste de patients",
  pending_patient_req: "Demande de Patients en attente",
  invite_patient: "Invitez um Patient",
  patient_name: "Nom du patient",
  delete_patient: "Etes-vous certain de vouloir supprimer ce patient ?",
  no_videos_found: "Pas de vidéos trouvées",
  not_found_trending: "Pas de vidéo populaire",
  upload_video_popup: "Vous devez créer votre propre Etablissement pour charger des vidéos",
  create_org: "Créer un Etablissement",
  list_of_user_profiles: "Liste de profils utilisateurs",
  all_speciality: "Toutes les spécialités",
  channel_delete: "Etes-vous sûr(e) de vouloir supprimer cet élément ? ",
  req_for_create_org: "Demande pour créer un Etablissement",
  cancel: "Annuler",
  send: "Envoyer",
  akunahhandbook: "Manuel d'Akunah",
  help: "Akunah Help",
  subject: "Sujet",
  message: "Message",
  no_live_video: "Pas de vidéos en direct trouvées ",
  hcp_name: "Nom du Praticien",
  expiry_days: "Jours avant expiration",
  send_req: "Envoyer la demande",
  search_by_speciality: "Rechercher par spécialités",
  no_request_data: "Pas de données demandées",
  no_data_found: "Pas de données trouvées",
  approve_patient_list: "Accepter la liste de patients",
  create_event: "Créer un évènement",
  live_event: "Evènement en direct",
  switch_profile: "Changer de profil",
  event_link: "Lien de l'évènement",
  search_by_patient_email_id: "Rechercher par patient & email ID",
  searchbyb_title: "Rechercher par titre",
  search_by_org_name: "Rechercher par Etablissement",
  enter_email_address: "Renseigner l'adresse email",
  no_of_days: "Nombre de jours",




  //12/7/2023//need to translate


  acceopt: "Accepter",
  reject: "Rejeter",
  approved: "Approuvé",
  select: "Sélectionner",
  active: "Actif",
  deactive: "Inactif",
  select_plan: "Sélectionner un plan",
  views: "Vues",
  view_patients: "Voir les Patients",
  valid_email: "Entrez une adress email valide !!!",
  is_already_created: "est déjà créé(e)",
  req_to_hcps: "Demander à un Etablissement",
  approve: "Accepter",
  upload: "CHARGER",
  search_by_name: "Chercher par nom",
  global_channel: "Chaîne Globale",
  all_users: "Tous les Utilisateurs",
  patients: "Patients",
  HCPs: "Praticiens",
  selected_users: "Sélectionner les utilisateurs",
  post_updated: "Post mis à jour",
  video_deleted: "Vidéo supprimée avec succès",
  ascending: "Ascendant",
  descending: "Descendant",
  all_drop: "Tout",
  akunah: "Akunah",
  channel_added: "Chaîne ajoutée",
  plan_status: "Statut du plan modifié",
  tag_added: "Tag ajouté",
  tag_updated: "Tag modifié",
  hcp_deleted: "Praticien supprimé avec succès",
  pending: "En attente",
  patient_deleted: "Patient supprimé avec succès ",
  is_new_patient: "est un nouveau patient ajouté",
  new_plan_added: "Nouveau plan ajouté",
  org_added: "Etablissement Ajouté",
  date: "Date",
  missing_subj: "L'objet est manquant",
  missing_message: "Le message est manquant",
  select_user: "Sélectionner un utilisateur",
  field_req: "Ce champs est requis",
  select_org: "Sélectionner un Etablissement",
  is_already_org: "est déjà présent dans l'Etablissement",
  org_error: "Cet Etablissement est déjà créée. Veuillez le rejoindre au lieu d'en créer un nouveau",
  email_sent: "Email envoyé avec succès",
  content_body: "Le corps du conu est manquant",
  not_sent_email: "Email non envoyé",
  no_user_found: "Pas d'utilisateur trouvé",
  profile_update: "Profil mis à jour",
  req_sent: "Demande envoyée",
  req_to_publish: "Demande de publication",
  no_req: "Pas de demande",
  akunah_global_public: "Akunah Global Public",
  akunah_global_patient: "Akunah Global Patient",
  enter_org_name: "Veuillez entrer un nom d'Etablissement",
  select_a_plan: "Veuillez sélectionner un plan",
  Plan: "Plan",
  admin_deleted: "Administrateur supprimé",
  email_added: "Email ajouté",
  email_not_added: "Email non ajouté",
  plan_not_added: "Plan non ajouté",
  hcp_not_delete: "Praticien pas supprimé",
  hcp_approved: "Praticien accepté avec succès ",
  hcp_reject: "Praticien rejeté",
  channel_updated: "Chaîne mise à jour",
  channel_deleted: "Chaîne supprimée avec succès",
  limit_error: "La limite doit être supérieure à 0",
  days: "Jours",
  gb: "GB",
  plan_created: "Plan créé",
  rejected: "Rejeté",
  expired: "Expiré",
  search_by_hcp_name: "Rechercher par Praticien ",

  name_already_present: "Le nom est déjà présent",
  already_req_hcp: "Vous avez déjà effectuer une demande pour ce Praticien",
  already_req_org: "Vous avez déjà effectuer une demande pour cet Etablissement",
  video_not_delete: "Vidéo non supprimée",
  video_updated_success: "Vidéo mise à jour avec succès",
  Doctor: "Arzt",
  Patient: "Patient",
  favorite: "préféré",
  unfavourite: "Défavorisé",




  admin: "Administrateur",
  org_admin: "Administrateur de l'organisation",


  video_upload_success: "Video Uploaded Successfully",//Not Done
  live_event_create_success: "Live Event Create Successfully", //Not Done


  privacy: "Votre vie privée En cliquant sur 'Accepter' tous les cookies, vous acceptez qu'akunah Med puisse stocker des cookies sur votre appareil et divulguer des informations conformément à nos",
  cookie: "politique relative aux cookies",

  my_profiles:"Mes profils",
  not_found_recent:"Pas de vidéo récente"




};