import React from "react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { setLibraryArr } from "../actions/libraryAction";
// import instance from "../axiosinstance";
import useAxios from "../axiosinstance";
import PostItemSmall from "./PostItemSmall";
import appConfig from "../config/appUrlConfig";
import SmallSkeleton from "./smallSkeleton";
import useTranslation from "./customHooks/translations";
import Profile from "../pages/Profile";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


const start = 16;

export default function LibraryList() {
  const axiosinstance = useAxios();
  const translation = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const start_date = queryParams.get("start");
  const end_date = queryParams.get("end");
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  let dStartDate = " ";
  let denddate = " ";
  if (start_date) {
    dStartDate = atob(start_date);
  }
  if (end_date) {
    denddate = atob(end_date);
  }

  // let dStartDate=atob(start_date);
  // let denddate=atob(end_date);
  const [startDate, setStartDate] = useState(dStartDate);
  const [endDate, setendDate] = useState(denddate);
  let typeID = 5;
  const dispatch = useDispatch();
  const { arr } = useSelector((state) => state.mainReducer.libarayData);

  const [check, setcheck] = useState(arr.length);
  const[offset,setOffset] = useState(0)
  const [limit, setLimit] = useState(16);
  const [categoryid, setCategoryid] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setendDate] = useState("");
  const [loop, setLoop] = useState(false);
  function handleChange(event) {
    setStartDate(event.target.value);
  }
  function handleChangeEnd(event) {
    setendDate(event.target.value);
  }
  // const [arr, setPost] = useState();
  const [show, setShow] = useState(false);
  const getData = (data) => {
    setCategoryid(data);
  };
  // getLibraryList?limit=16&offset=&sDate=&eDate=
  useEffect(() => {
    if(profile.length>0){
 // if (
    //   arr.length === 0 ||
    //   categoryid !== "" ||
    //   startDate !== " " ||
    //   endDate !== " "
    // ) {
      axiosinstance.current
      .get(`getLibraryList?limit=${start}&sDate=${startDate}&eDate=${endDate}&ak_id=${profile[0]?.id}&offset=${offset}`)
      .then((response) => {
        if (response) {
          setShow(true);
          // console.log("first", response.data.data);
          // setPost(response.data.results);
          dispatch(setLibraryArr(response.data.data));
          setcheck(response.data.data.length);
        } else {
          setShow(false);
        }
      });
    // }
    // if (arr) {
    //   setShow(true);
    // } else {
    //   setShow(false);
    // }
    }
   
  }, [profile,categoryid, startDate, endDate]);
  const fetchData = () => {
    setLimit(limit + 16);
    //let cat_id = "";
    if (loop === false) {
      axiosinstance.current
        .get(
          `getLibraryList?limit=${start}&offset=${limit}&sDate=${startDate}&eDate=${endDate}`
        )
        .then((response) => {
          let res = response.data.data;
          if (res.length === 0) {
            setLoop(true);
            // dispatch(setLibraryArr(arr.concat(response.data.data)));
          } else {
            //console.log('after scroll',response.data.data)
            dispatch(setLibraryArr(arr.concat(res)));
          }
        });
    }
  };
  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };








  return (
    <div className="trending">
      <div className="upcoming-head filter">
        <h4>{getTranslation("library")}</h4>
        <p className="filter " dir={profile[0]?.language === 'ara' ? "ltr" : 'rtl'} data-bs-toggle="dropdown">
          <img src="Assets/image/filter_icon.svg" alt="filter" />
          {getTranslation("filter")}
        </p>
        <ul className="filter dropdown-menu arrow_top p-2">
          <li className="m-2">
          {getTranslation("start_date")} :{" "}
            <span className="filter_date">
              <input
                onChange={handleChange}
                type="date"
                max={appConfig.new_date}
                value={!startDate ? setStartDate(" ") : startDate}
              />
            </span>{" "}
          </li>
          <li className="m-3 mt-4">
          {getTranslation("end_date")} :{" "}
            <span className="filter_date">
              <input
                onChange={handleChangeEnd}
                type="date"
                max={appConfig.new_date}
                value={!endDate ? setendDate(" ") : endDate}
              />
            </span>{" "}
          </li>
        </ul>
      </div>
      <div className="trendig-box">
        <InfiniteScroll
          dataLength={limit}
          next={fetchData}
          hasMore={true}
          loader={
            ""
            //   <div className="load_next text-center " style={{ 'textTransform': 'uppercase'}}>
            //   <span>No more Data</span>
            // </div>
          }
        >
          <div className="row">
            {!show && <SmallSkeleton></SmallSkeleton>}
            {show &&
              arr.map((post) => (
                <div
                  className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4"
                  key={post.video_id}
                >
                  <PostItemSmall
                    id={post.id}
                    title={post.title}
                    posted_by={post.posted_by}
                    videoKey={post.video_key}
                    large={post.large}
                    medium={post.medium}
                    duration={post.duration}
                    views={post.views}
                    description={post.description}
                    data={post.date}
                    category={post.category_id}
                    featured={post.featured}
                    ap={post.akunah_public}
                    ae={post.akunah_exc}
                    op={post.org_public}
                    oe={post.org_exc}
                    hp={post.hcp_public}
                    he={post.hcp_exc}
                    sessionId={post.sessionId}
                    profile={post.profile}
                  />
                </div>
              ))}
          </div>
        </InfiniteScroll>
        {check === 0 && (
          <div className="no_data">
            <h2>{getTranslation('no_videos_found')}</h2>
          </div>
        )}
      </div>
    </div>
  );
}
