import React, { useEffect } from "react";
import Header from "../Component/Header";
import SideBar from "../Component/Sidebar";
import { useSelector } from "react-redux";
import appStyle from "../config/appStyle";
import Footer from "../Component/Footer";
import UserService from "../services/UserService";
import { useNavigate } from "react-router-dom";
import ParticularChannelList from "../Component/channel/ParticularChannelList";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from "../localization/spa";
import tha from '../localization/tha';
import kor from '../localization/kor';

const ParticularChannelPage = () => {

  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  document.title = "Akunah Med || Channel";
  return (
    <>
    <Header></Header>
    <main className="mt-4" dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
      <div className="container-fluid">
        <div className="row">
          <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
            <SideBar></SideBar>
          </div>
          <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
          <ParticularChannelList></ParticularChannelList>

            <Footer></Footer>
            {/* Trending end */}
          </div>
        </div>
      </div>
    </main>
  </>
  )
}

export default ParticularChannelPage
