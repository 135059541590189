import React from "react";
import Header from "../Component/Header";
import SideBar from "../Component/Sidebar";
// import Categories from "../Component/Categories";
import RecentlyList from "../Component/RecentlyList";
import { useSelector } from "react-redux/es/exports";
import appStyle from "../config/appStyle";
import Footer from "../Component/Footer";
// import UserService from "../services/UserService";
// import { useEffect } from "react";
// import authConfig from "../config/authConfig";
// import { useNavigate } from "react-router-dom";
export default function RecentlyPages() {
  // login check
  // const check = function loginCheck() {
  //   return UserService.isLoggedIn();
  // };
  // const abc = check();

  // const navigate = useNavigate();
  //      useEffect(() => {
  //   if (!abc) {
  //     navigate("/loader");
  //   }
  // },[])
  // login check end
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  document.title = "Akunah Med || Recently";
  return (
    <>
      <Header></Header>
      <main className="mt-4" dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
              <SideBar></SideBar>
            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
              <RecentlyList></RecentlyList>
              {/* Recently end */}
              <Footer></Footer>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
