import React, { useEffect } from "react";
import Header from "../Component/Header";
import SideBar from "../Component/Sidebar";
import { useSelector } from "react-redux";
import appStyle from "../config/appStyle";
import Footer from "../Component/Footer";
import UserService from "../services/UserService";
import { useNavigate } from "react-router-dom";
import ChannelList from "../Component/channel/ChannelList";
// import authConfig from "../config/authConfig";
export default function ChannelPage() {
  // login check

  // const check = function loginCheck() {
  //   return UserService.isLoggedIn();
  // };
  // const abc = check();

  // const navigate = useNavigate();
  //  useEffect(() => {
  //     if (!abc) {
  //       navigate("/loader");
  //     }
  //   },[])
  // login check end
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  document.title = "Akunah Med || Channel";
  return (
    <>
      <Header></Header>
      <main className="mt-4" dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
              <SideBar></SideBar>
            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
              <ChannelList />
              
              <Footer></Footer>
              {/* Trending end */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
