import React, { useEffect } from 'react'
import Header from '../../Component/Header'
import Footer from '../../Component/Footer'
import Sidebar from '../../Component/Sidebar'
import { useSelector } from 'react-redux/es/exports';
import appStyle from '../../config/appStyle';
import UserService from '../../services/UserService';
import CategoriesList from '../../Component/admin/CategoriesList';
import Tab from '../../Component/admin/Tab';
import { useNavigate } from "react-router-dom";


export default function Categories() {
    // const navigate = useNavigate();

    // const check = function loginCheck() {
    //     return UserService.isLoggedIn()
    // }
    // const abc = check()

    // useEffect(() => {
    //     if (!abc) {
    //         navigate("/loader");
    //     }
    // }, [])
    const { show } = useSelector((state) => state.mainReducer.navData);
    const { profile } = useSelector((state) => state.mainReducer.profileData);
    document.title = 'Akunah Med || Categories';
    return (
        <>
            <Header />
            <main className="mt-4"  dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
                <div className="container-fluid">
                    <div className="row">
                        <div className={show ? appStyle.navClass1 : appStyle.navClass2} >

                            <Sidebar></Sidebar>

                        </div>
                        <div className={show ? appStyle.navClass3 : appStyle.navClass4}>

                            <Tab />

                            <CategoriesList />

                            <Footer></Footer>

                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
