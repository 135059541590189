const initialValues = {
    show: true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialValues, action) => {
    switch (action.type) {

        case 'SET_SHOW':
            return {
                show: action.payload
            };

        default:
            return state;
    }
}
