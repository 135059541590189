
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "วีดีโอทั้งหมด",
  upcoming: "กำลังมา",
  trending: "เทรนด์ใหม่",
  recently_added: "วิดีโอล่าสุด",
  live: "ไลฟ์สด",
  library: "สิ่งที่ชอบ",
  view_by_catagories: "มุมมองของผู้เชี่ยวชาญ",
  loading: "กำลังโหลด...",
  filter: "ตัวกรอง",
  profile: "โปรไฟล์",
  profile_photo: "รูปโปรไฟล์",
  first_name: "ชื่อ",
  last_name: "นามสกุล",
  update_profile: "อัพเดตรูปโปรไฟล์",
  Update_Profiless: "อัปเดตรูปโปรไฟล์",



  edit_profile: "แก้ไข้รูปโปรไฟล์",
  date_of_birth: "วันเกิด",
  address: "ที่อยู่",
  registered_email: "กรอกอีเมล์",
  registered_phone: "กรอกเบอร์โทรศัพท์",
  subscription_key: "รหัสสมาชิก",
  renew_key: "ต่ออายุสมาชิก",
  start_date: "วันที่เริ่มต้นใช้งาน",
  end_date: "สิ้นสุดวันใช้งาน",
  no_more_data: "ไม่พบข้อมูล",
  search: "ค้นหา",
  logout: "ออกจากระบบ",
  view_profile: "ดูโปรไฟล์",
  notifications: "แจ้งเตือน",
  Get_Premium: "สมัครพรีเมียม",
  App_Version: "เวอร์ชั่นล่าสุด",
  Get_3_months_of_premium_for_free: "ฟรีใช้งานพรีเมียม 3 เดือน",
  Click_below_to_sign_up_for_premium_Akunah_MEd:
    "คลิกด้านใต้เพื่อสมัคร พรีเมียม Akunah Med",
  request_to_join: "เข้าร่วม สถานะผู้ป่วย HCP ",
  request_to_join_p: "เข้าร่วมองค์กร สถานะผู้ป่วย ",
  request_to_join_h: "เข้าร่วมองค์กร",
  create_org: "Create Organization",
  lives: "ไลฟ์สด",
  plan: "แผนการณ์ทั้งหมด",
  channels: "หน้าหลัก",


  akunahPublicP: "วิดีโอสุขภาพของฉัน", //Not Done
  OrgPublicPatient: "ผู้จัดหาวิดีโอโปรแกรมสุขภาพของฉัน",
  HCPPublicPatient: "แพทย์ผู้จัดหาวิดีโอของฉัน",
  HCPPrivatePatient: "วิดีโอพิเศษสำหรับฉัน",
  akunahExc: "สถานะ Akunah Global HCP",
  akunahPublic: "สถานะ สาธารณะ Akunah Global",
  OrgPublic: "องค์กรสาธารณะของฉัน",
  myOrgPublic: "สถานะสาธารณะของฉัน",
  myOrgExc: "สถานะพิเศษของฉัน",
  OrgExc: "องค์กร HCP ของฉัน",
  mypublic: "สถานะสาธารณะของฉัน",
  myprivate: "สถานะพิเศษของฉัน",
  // myhcppublic: "my hcp public",
  // myhcpprivate: "my hcp Exclusive",



  akunah_how_tos: "สอนวิธีทำโปรแกรม Akunah",
  Upload_a_Video: "อัพโหลดวิดีโอ",
  administration: "การจัดการ",
  view_all: "ดูทั้งหมด",
  Channelsdata: "ช่องรายการ",
  login_by: "เข้าสู่ระบบโดย",
  upload_video: "อัปโหลดวิดีโอ", 
  type_of_content: "ชนิดของเนื้อหา",
  title: "หัวข้อ",
  thumb_imag1: "รูปภาพขนาดเล็ก (ขนาดรูปภาพ 720x405)",
  thumb_imag2: "รูปภาพขนาดเล็ก (ขนาดรูปภาพ 480x270)",
  video: "วิดีโอ",
  link: "ลิงค์สตรีมมิ่ง",
  tags: "แท็ก",
  organization: "องค์กร",
  description: "คำอธิบาย",
  publish: "ตีพิมพ์",
  submit: "ส่ง",

  manage_my_videos: "จัดการวิดีโอของฉัน",
  manage_my_organizations: "จัดการองค์กรของฉัน",
  manage_my_hcps: "จัดการรายการ HCP ของฉัน",
  manage_org_patient: "จัดการ ผู้ป่วยองค์กร",
  manage_my_patients: "จัดการผู้ป่วยของฉัน",
  manage_tags: "จัดการ แท็ก",
  manage_my_plans: "จัดการแผนการของฉัน",








  video_list: "รายการ วิดีโอ",
  upload_videos: " Upload Videos",
  limit: "จำกัด",
  type: "ชนิด",
  posted_by: "โพสโดย",
  organization: "องค์กร",
  s_no: "S No.",
  video_id: "หมายเลข วิดีโอ",
  duration: "ช่วงเวลา",
  video_size: "ขนาดวิดีโอ",
  actions: "การดำเนินการ",
  add_a_organization: "เพิ่มเติมองค์กร",
  org_name: "ช่ือองค์กร",
  size: "ขนาด",
  watch_time: "เวลาที่ชมรายการ",
  sort: "ประเภท",
  status: "สถานะ",
  organization_Id: "หมายเลของค์กร",
  total_post_size: "ขนาดโพสทั้งหมด",
  total_watch_time: "เวลาที่ดูทั้งหมด",
  add_tags: "เพิ่มแท็ก",
  order: "รายการ",
  add: "เพิ่ม",
  edit: "Edit",
  tags_list: "รายการแท็ก",
  add_plan: "เพิ่มแผนการ",
  plan_name: "ชื่อการวางแผน",
  number_of_hcp: "จำนวน HCP ",
  number_of_patient: "จำนวนผู้ป่วย",
  Limit: "ข้อจำกัด",
  plan_list: "แผนการของฉัน",
  noofhcp1: "หมายเลข HCP",
  nopatient1: "หมายเลขผู้ป่วย",
  save: "บันทึก",
  add_channels: "เพิ่มช่องรายการ",
  name: "ชื่อ",
  role: "Visible To Role (s)",
  all: "Request Publish",
  users: "ผู้ใช้งาน",
  user_type: "ชนิดผู้ใช้งาน",
  list_of_channels: "ช่องรายการ",
  event_Link: "Event Link",
  visible_till: "* Visible till",
  tags_channels: "Tags Channels",
  manage_hcps: "Manage HCP's",
  pending_hcp_req: "Pending HCP's Request",
  invite_hcp: "Invite HCP",
  email: "อีเมล์",
  delete_hcp: "คุณมั่นใจที่จะลบ HCP ใช่หรือไม่",
  no: "ไม่ใช่",
  yes: "ใช่",
  add_email: "เพิ่ม อีเมล์",
  view_plans: "ดู แผนการ",
  activate_date: "วันที่เริ่มใช้งาน",
  expiry_date: "วันที่หมดอายุ",
  hcp: "HCP",
  patient: "ผู้ป่วย",
  total_hcp: "HCP ทั้งหมด",
  total_patient: "ผู้ป่วยทั้งหมด",
  invite_admin: "Invite Admin",
  autoid: "หมายเลขอัตโนมัติ",
  delete_admin: "Are you sure you want to delete as admin ?",
  admin_user_email: "Admin User Email",
  view_admins: "ดู แอดมิน",
  view_HCPs: "ดู HCP ของคุณ",
  patient_list: "รายการผู้ป่วย",
  pending_patient_req: "Pending Patients Request",
  invite_patient: "Invite Patient",
  patient_name: "ชื่อผู้ป่วย",
  delete_patient: "คุณมั่นใจที่จะลบผู้ป่วยใช่หรือไม่ ?",
  no_videos_found: "No videos found",
  not_found_trending: "ไม่พบเทรนด์วิดีโอ",
  upload_video_popup: "You have to create your own organization to upload videos",
  create_org: "Create Organiation",
  list_of_user_profiles: "รายการโปรไฟล์ผู้ใช้งาน",
  all_speciality: "ผู้เชี่ยวชาญทั้งหมด",
  channel_delete: "Are you sure you wish to delete this item?",
  req_for_create_org: "Request for Create Organization",
  cancel: "ยกเลิก",
  send: "Send",
  akunahhandbook: "คู่มือ Akunah",
  help: "ความช่วยเหลือ Akunah",
  subject: "หัวข้อเรื่อง",
  message: "ข้อความ",
  no_live_video: "No live videos found",
  hcp_name: "HCP Name",
  expiry_days: "วันหมดอายุ",
  send_req: "ส่งคำร้องขอ",
  search_by_speciality: "ค้นหาผู้เชียวชาญ",
  no_request_data: "ไม่รายการร้องขอ",
  no_data_found: "No data found",
  approve_patient_list: "ยอมรับรายการผู้ป่วย",
  create_event: "Create an event",
  live_event: "Live event",
  switch_profile: "Switch profile",
  event_link: "Event Link",
  search_by_patient_email_id: "ค้นหาโดย ผู้ป่วย และ อีเมล์ ",
  searchbyb_title: "ค้นหาหัวข้อ",
  search_by_org_name: "ค้นหาชื่อองค์กร",
  enter_email_address: "เข้าสู่อีเมล์",
  no_of_days: "หมายเลขวันที่",

  //12/7/2023//need to translate

  acceopt: "Accept",
  reject: "Reject",
  approved: "Aopproved",
  select: "Select",
  active: "Active",
  deactive: "Deactive",
  select_plan: "Select Plan",
  views: "Views",
  view_patients: "View Patients",
  valid_email: "Enter valid email !!!",
  is_already_created: "is already created",
  req_to_hcps: "Request to HCP'S",
  approve: "Approve",
  upload: "UPLOAD",
  search_by_name: "Serach by name",
  global_channel: "Global Channel",
  all_users: "All Users",
  patients: "Patients",
  HCPs: "HCPs",
  selected_users: "Selected Users",
  post_updated: "Post Updated",
  video_deleted: "Video Deleted Successfully",
  ascending: "Ascending",
  descending: "Descending",
  all_drop: "All",
  akunah: "Akunah",
  channel_added: "Channel Added",
  plan_status: "Plan Status Changed",
  tag_added: "Tag Added",
  tag_updated: "Tag Updated",
  hcp_deleted: "HCP Deleted Successfully",
  pending: "Pending",
  patient_deleted: "Patient Deleted Successfully",
  is_new_patient: "is new patient added",
  new_plan_added: "New Plan Added",
  org_added: "Organization Added",
  date: "Date",
  missing_subj: "Subject is missing",
  missing_message: "Message is missing",
  select_user: "Select User",
  field_req: "This field is required",
  select_org: "Select Organization",
  is_already_org: "is already present in organization",
  org_error: "This Organization is already created. Please join instead of creating new one.",
  email_sent: "Email sent successfully",
  content_body: "Content body is missing",
  not_sent_email: "email not sent",
  no_user_found: "No user found",
  profile_update: "Profile Update",
  req_sent: "Request Sent",
  req_to_publish: "Request To Publish",
  no_req: "No Request",
  akunah_global_public: "Akunah Global Public",
  akunah_global_patient: "Akunah Global Patient",
  enter_org_name: "please enter organization name", //Not Done
  select_a_plan: "Please select a plan",//Not Done
  Plan: "Plan",
  admin_deleted: "Admin Deleted",//Not Done
  email_added: "Email Added",//Not Done
  email_not_added: "Email Not Added",//Not Done
  plan_not_added: "Plan Not Added",//Not Added
  hcp_not_delete: "HCP Not Deleted",//Not Done
  hcp_approved: "HCP Approved Succesfully",//Not Done
  hcp_reject: "HCP Rejected",//Not Done
  channel_updated: "Channel Updated",//Not Done
  channel_deleted: "Channel Deleted Successfully",//Not Done
  limit_error: "Limit should be greater than 0",//Not Done
  days: "Days",//Not Done
  gb: "GB",//Not Done
  plan_created: "Plan Created",//Not Done
  rejected:"ถูกปฏิเสธ",
  expired:"หมดอายุ",
  search_by_hcp_name:"ค้นหา ชื่อ HCP",


  name_already_present:"Name is already present",//Not Done
  video_not_delete:"วิดีโอไม่ถูกลบ",
  video_updated_success: "อัปเดตวิดีโอเรียบร้อยแล้ว",
  Doctor:"หมอ",
  Patient: "ผู้ป่วย",
  favorite:"ที่ชื่นชอบ",
  unfavourite:"ไม่ชอบ",


  admin: "ผู้ดูแลระบบ",
  org_admin: "ผู้ดูแลระบบองค์กร",

  
video_upload_success:"Video Uploaded Successfully",//Not Done
live_event_create_success:"Live Event Create Successfully", //Not Done


privacy:"Your privacy By clicking 'Accept' all cookies, you agree akunah Med can store cookies on your device and disclose information in accordance with our",
cookie:"cookie policy",

my_profiles:"โปรไฟล์ของฉัน",

not_found_recent:"ไม่พบวิดีโอล่าสุด"



};
