import React from 'react';
import TopHeaderdocmap from './TopHeaderdocmap';
import NewUserPopup from './NewUserPopup';

const LoginCheck = () => {
    return (
        <>
        <div className="mb-5">
          <TopHeaderdocmap />
        </div>
        <div className="doctor_roles_map_screen">
          <div className="panel">
            <form>
              <div className="panel-body p_25">
                <h2 className="f_600 mt_40 mb_60 text-center header">
                  {/* List of User profiles */}
                </h2>
                <div className="row">
                  <div className="col-sm-12 col-md-12 mb_20">
                    <NewUserPopup></NewUserPopup>
                  </div>
                </div>
              </div>
              {/* <div className="row mb_30 button_box ml_15 mr_15">
              
                </div> */}
            </form>
          </div>
        </div>
      </>
    );
}

export default LoginCheck;
