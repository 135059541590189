import React from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { useSelector } from 'react-redux';
function Postitem(props) {

  const { profile } = useSelector((state) => state.mainReducer.profileData);
  let title = props.title;
  let sessionId = props.sessionId
  let postTitle = title.slice(0, 50);


  return (
    <>
      {
        props.type === 2 || props.type === "2" ? (
          <a
            href={props.link}
            target="_blank"
            data-bs-toggle="tooltip"
            data-bs-placement="down"
            title={title}
            rel="noreferrer"
          >
            <div>
              <div className="trending-boxs">
                <img src={props.medium} alt="" className="img-fluid"></img>
                <div className="play_">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    className="bi bi-play-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                  </svg>
                </div>
              </div>
              {/* {
            props.duration ? ( <div className="time_trend">
            <p>{props.duration}</p>
          </div>):(null)
          } */}

              <div className="trending-heading d-flex">
                <div className="trending-img me-2 avatarss">
                  {props.profile !== "" && ( // This section will be - props.posted_by.img != ""
                    <img
                      src={props.profile}
                      alt=""
                      className=""
                    ></img>
                  )}
                  {props.profile === "" && (// This section will be - props.posted_by.img === ""
                    <Avatar
                      className={profile[0]?.language === 'ara' ? "avatar-text" : "me-2 avatar-text"}
                      name={props.posted_by.slice(0, 1)}
                      size="30"
                      maxInitials="1"
                      round={true}
                      fgColor="#EA702B"
                      color="#F3CDB8"
                    />
                  )}
                </div>
                <div className="trending-title">
                  <h2>
                    {postTitle} {title.length > 25 && <span> ...</span>}
                  </h2>
                  <p className="text-uppercase">{props.posted_by}</p>
                </div>
              </div>
            </div>

          </a>
        ) : (
          <Link
            to={`/details/${props.id}/${sessionId}`}
            state={{ id: props.id, sessionId: sessionId }}
            data-bs-toggle="tooltip"
            data-bs-placement="down"
            title={title}
            sessionId={sessionId}
          >
            <div>
              <div className="trending-boxs">
                <img src={props.medium} alt="" className="img-fluid"></img>
                <div className="play_">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    className="bi bi-play-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                  </svg>
                </div>
              </div>
              {
                props.duration ? (<div className="time_trend">
                  <p>{props.duration}</p>
                </div>) : (null)
              }

              <div className="trending-heading d-flex">
                <div className="trending-img me-2 avatarss">
                  {props.profile !== "" && ( // This section will be - props.posted_by.img != ""
                    <img
                      src={props.profile}
                      alt=""
                      className=""
                    ></img>
                  )}
                  {props.profile === "" && (// This section will be - props.posted_by.img === ""
                    <Avatar
                      className={profile[0]?.language === 'ara' ? "avatar-text" : "me-2 avatar-text"}
                      name={props.posted_by.slice(0, 1)}
                      size="30"
                      maxInitials="1"
                      round={true}
                      fgColor="#EA702B"
                      color="#F3CDB8"
                    />
                  )}
                </div>
                <div className="trending-title">
                  <h2>
                    {postTitle} {title.length > 50 && <span> ...</span>}
                  </h2>
                  <p className="text-uppercase">{props.posted_by}</p>
                </div>
              </div>
            </div>
          </Link>
        )
      }

    </>
  );
}
export default Postitem;
