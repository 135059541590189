import React from "react";
import { Link } from 'react-router-dom';

const NoMatch = () => (
    <>
            <nav className="p-2 bg-light">
            <div className="container">
                <div className='row justify-content-center'>
                    <div className="col-md-10">
                        <div className="log_nav">
                            <Link to={{ pathname: "/" }} className="navbar-brand">

                                <img src="Assets/image/_Akunah_Med_TM-Orange.png" alt="Akunah" />
                            </Link>
                            <Link className="sign_" to='/'>Sign In</Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <main className="mt-4">
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                                <div className="page_">
                                    <h2 className="404error">404</h2>
                                    <img className="img-fluid" width={'500'} src="Assets/image/error.svg"></img>
                                    <h4 className="mt-5">Oops! The Data You Requested Was Not Found.</h4>
                                    <Link className="outline-btn" to={'/'}>Go back to Home</Link>
                                </div>
                            
                            <div className="line_"> </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
)
export default NoMatch