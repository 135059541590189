import React, { Suspense, useState, useEffect } from "react";
import Modal from "react-modal";
import Header from "../Component/Header";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../Component/Sidebar";
import appStyle from "../config/appStyle";
// import SearchList from "../Component/SearchList";
import useTranslation from "../Component/customHooks/translations";
import OwlCarousel from "react-owl-carousel";
import Footer from "../Component/Footer";
// import UserService from "../services/UserService";
import { useNavigate, Link } from "react-router-dom";
// import AkunahPublic from "../Component/channel/AkunahPublic";
import OrgPublic from "../Component/channel/OrgPublic";
// import HttpService from "../services/HttpService";
import { setArr } from "../actions/profileAction";
// import { setArrHomeChannelArr } from "../actions";
import { setCatagoryArr } from "../actions/catagoryAction";
import Select from "react-select";
// import NewUserPopup from "../Component/NewUserPopup";
import { ToastContainer, toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
// import UploadVideoPopup from "../Component/UploadVideoPopup";
import CreateOrganizationReq from "../Component/CreateOrganizationReq";
import { ReactSearchAutocomplete ,Icon} from "react-search-autocomplete";

import SmallSkeleton from "../Component/smallSkeleton";
import useAxios from "../axiosinstance";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';



const Channels = () => {
  const axiosinstance = useAxios();
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const [role, setRole] = useState("");
  const [AK_PUB, setAK_PUB] = useState([]);
  const [AK_EXC, setAK_EXC] = useState([]);
  const [ORG_PUB, setORG_PUB] = useState([]);
  const [ORG_EXC, setORG_EXC] = useState([]);
  const [HCP_PUB, setHCP_PUB] = useState([]);
  const [HCP_EXC, setHCP_EXC] = useState([]);
  const [statics, setStatics] = useState("");
  const [postId, setPostId] = useState("");
  const [indexIds, setIndexIds] = useState(0);
  const [listOfCat, setListOfcat] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState({});

  let akToken = localStorage.getItem("akTokenMed");
  localStorage.removeItem("tagdata")
  //  let channelType = {
  //   0 :'akunah-public',  1:'org-public', 4:'hcp-public', 2:'akunah-exclusive', 3:'org-exclusive', 5:"hcp-exclusiv"
  //  }
  let { arr } = useSelector((state) => state.mainReducer.catagoryData);

  useEffect(() => {
    if (arr.length < 1) {
      axiosinstance.current.get("categories?status=1").then((response) => {
        if (response) {
          dispatch(setCatagoryArr(response.data));
        }
      });
    }
  }, []);

  // const GetResult = async() => {
  //   if (profile.length > 0) {
  //     await axiosinstance.current
  //       .get(`getUser?ak_id=${akToken}`)
  //       .then((response) => {
  //         if (response) {
  //           dispatch(setArr(response.data));
  //         }
  //       });
  //   }
  // };
  // useEffect(() => {
  //   GetResult();
  // }, []);


  const navigate = useNavigate();

  const handleProfileRedirect = () => {
    navigate(`/profile`);
  };

  const handleStatic = () => {
    arr &&
      arr.map((ele) => {
        if (ele.title === "Akunah How To") {
          setStatics(ele.title);
          setPostId(ele.id);
        } else {
          return;
        }
      });
  };

  const [showmodel, setShowModel] = useState(false);
  const onCancel = (x) => {
    setShowModel(x);
  };
  const handleRedirect = () => {
    navigate(`/search?cat_id=${btoa(postId)}`);
  };
  const handleCreateOrgRequest = () => {
    setShowModel(true);
  };

  useEffect(() => {
    if (profile.length !== 0) {
      setRole(profile[0].role_value);
    }
    handleStatic();
  }, [profile]);
  const { show } = useSelector((state) => state.mainReducer.navData);
  document.title = "Akunah Med || Channels";
  const [selectedOption, setSelectedOption] = useState("");
  const [show1, setShow1] = useState(false);
  const Showsearch = () => {
    if (show1 == false) {
      setShow1(true);
    } else {
      setShow1(false);
    }
  };
  function handleChange(e) {
    setIndexIds(e.key)
    navigate(`/search?cat_id=${btoa(e.value.id)}&index=${e.key}&tagdata=true`);
    setSelectedOption(e.value.id);
  }
  // const handleChange = (event, value) => {
  //   if (value) {
  //     const index = arr.findIndex((item) => item.title === value.title);
  //     setIndexIds(index)
  //     navigate(`/search?cat_id=${btoa(value.id)}&index=${index}`);
  //     setSelectedOption(value.id);
  //     setShow1(false);
  //   }
  // };


  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      zIndex: 2,
    }),
    // control: (styles) => ({
    //   ...styles,
    //   cursor: 'pointer',
    // }),
  };
  const getData = async () => {
    if (profile.length !== 0) {
      const response = await axiosinstance.current.get(
        `channelData?limit=8&offset=0&ak_id=${profile[0]?.id}`
      );
      setAK_PUB(response.data.AK_PUB);
      setAK_EXC(response.data.AK_EXC);
      setORG_PUB(response.data.ORG_PUB);
      setORG_EXC(response.data.ORG_EXC);
      setHCP_PUB(response.data.HCP_PUB);
      setHCP_EXC(response.data.HCP_EXC);
    }
  };

  useEffect(() => {
    getData();
    // getData();
    // if (postId)
    //   // setTimeout(() => {
    //     getData();
    //   // });
  }, [profile]);

  const options = {
    items: 3,
    nav: true,
    navText: [
      '<div class="nextsss"><span><</span></div>',
      '<div class="nextsss"><span >></span></div>',
    ], // custom next and previous button text
  };

 
  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };


  const handleChanged = (e) => {
    setIndexIds(e.item.index);
  };



  const handleOnSearchorg = (string, results) => {
    setInputValue(string)
    if (string.length === 2) {
      axiosinstance.current.get(`categories?status=1&title=${string}`).then((res) => {
        setListOfcat(res.data)
      })
    }
  };


  const handleOnHoverorg = (result) => {

  };

  const handleOnSelectorg = (item) => {
    // console.log(item);
    if (item) {
      setSelected(item);
      navigate(`/search?cat_id=${btoa(item.id)}&index=${item.order-1}`);
      setInputValue("")
    }
  };

  const handleOnFocusorg = () => {

  };

  const handleOnClear = () => {
    setSelected({})
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} />
      <Suspense
        fallback={<div className="text-center">{translation.loading}</div>}
      >
        <Header></Header>
      </Suspense>
      <div className="" style={{ backgroundColor: "red" }}></div>

      <main
        className="mt-4"
        dir={profile[0]?.language === "ara" ? "rtl" : "ltr"}
      >
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
              <Suspense
                fallback={
                  <div className="text-center">{translation.loading}</div>
                }
              >
                <SideBar role={role} />
              </Suspense>
            </div>
            <Suspense
              fallback={
                <div className="text-center">{translation.loading}</div>
              }
            >
              <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
                {/* <AkunahPublic  /> */}
                {/* {AK_PUB.length !== 0 && ( */}
                <>
                  <div className="">
                    <div className="categories">
                      <div className="upcoming-head">
                        <h4>{getTranslation("view_by_catagories")}</h4>
                      </div>
                      <div
                        className="row home_caraousel"
                        dir={profile[0]?.language === "ara" ? "ltr" : "ltr"}
                      >
                        <div className="col-md-12 col-sm-12 col-12 d-flex sidebar-margin ">
                          {/* <div className="width_top1">
                            <div>
                              <button className="btn admin_bt btn-secondary btns"
                                type="submit"
                                onClick={handleRedirect}
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"><span className="statics">{statics}</span></button>

                            </div>
                          </div> */}
                          <div className="width_top2 ">
                            <div className="categories-title ">
                              <OwlCarousel
                                {...options}
                                className="owl-theme fs-1"
                                loop={false}
                                onChanged={handleChanged}
                                startPosition={indexIds}
                                margin={5}
                                dots={false}
                                autoplayTimeout={5000}
                                autoplay={false}
                                // stagePadding={20}
                                // mergeFit={true}
                                autoWidth={true}
                                responsive={{
                                  0: { items: 1 },
                                  360: { items: 2 },
                                  400: { items: 2 },
                                  599: { items: 3 },
                                  1024: { items: 4 },
                                  1300: { items: 5 },
                                  1600: { items: 6 },
                                }}
                              >


                                {arr.map((post) => (
                                  <div className="item" key={post.id}>
                                    <div className="categories-list ">
                                      <h6>
                                        {" "}
                                        <Link
                                          to={`/search?cat_id=${btoa(post.id)}&index=${indexIds}&tagdata=true`}
                                          state={{ from: post.id }}
                                        >
                                          {post.title}
                                        </Link>
                                      </h6>
                                    </div>
                                  </div>
                                ))}
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="clearfix">
                    <div className="width_top3 d-none d-md-block d-lg-block">
                      <div className="dropdown spe">
                        <button
                          className="btn admin_bt dropdown-toggle text-center mt-0 specilaity_btn all-spe-mob"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => Showsearch()}
                        >
                          <span className="all-specilaity">{getTranslation('all_speciality')}</span>
                        </button>
                        {show1 && (
                          <Select
                            placeholder={getTranslation('search_by_speciality')}
                            defaultValue={selectedOption}
                            onChange={handleChange}
                            // keepOpen={true}
                            // dropdownHandle={true}
                            styles={customStyles}
                            menuIsOpen={true}
                            options={arr.map((guest, index) => {
                              return {
                                label: guest.title,
                                value: guest,
                                key: index,
                              };
                            })}
                          />
                          // <Stack spacing={2} sx={{ width: 300 }}>

                          //   <Autocomplete
                          //     freeSolo
                          //     id="free-solo-2-demo"
                          //     disableClearable
                          //     options={arr}
                          //     getOptionLabel={(option) => option.title}
                          //     onChange={handleChange}
                          //     renderInput={(params) => (
                          //       <TextField
                          //         {...params}
                          //         placeholder={getTranslation('search_by_speciality')}
                          //         InputProps={{
                          //           ...params.InputProps,
                          //           type: 'search',
                          //           style: {
                          //             outline: 'none', // Remove the blue border here
                          //           },
                          //         }}
                          //       />
                          //     )}
                          //   />
                          // </Stack>
                        )}
                      </div>
                    </div>
                    <div className="width_top3 bg-danger d-block d-sm-none" style={{borderRadius:"5px"}}>

                      <ReactSearchAutocomplete
                        items={listOfCat}
                        fuseOptions={{
                          shouldSort: true,
                          threshold: 0.6,
                          location: 0,
                          distance: 100,
                          maxPatternLength: 32,
                          minMatchCharLength: 3,
                          keys: ["title", "id"],
                        }} // Search on both fields
                        resultStringKeyName="title" // String to display in the results
                        onSearch={handleOnSearchorg}
                        onHover={handleOnHoverorg}
                        inputSearchString={selected.title}
                        onSelect={handleOnSelectorg}
                        placeholder={getTranslation('search_by_speciality')}
                        onFocus={handleOnFocusorg}
                        onClear={handleOnClear}
                        showIcon={false}
                        styling={{
                          height: "34px",
                          border: "1px solid #c4c9cd;",
                          borderRadius: "4px",
                          backgroundColor: "white",
                          boxShadow: "none",
                          hoverBackgroundColor: "#EA702B",
                          color: "black",
                          fontSize: "12px",
                          fontFamily: "Courier",
                          iconColor: "black",
                          lineColor: "#EA702B",
                          placeholderColor: "black",
                          clearIconMargin: "3px 8px 0 0",
                          zIndex: 1,
                          // Add the search image background to the input
                          // backgroundImage: `https://t3.ftcdn.net/jpg/04/99/34/78/360_F_499347841_IXq0bLOPN4MkKAa71nP3WMQq6LVlgeTO.jpg`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "8px center",
                          paddingLeft: "32px", // Adjust this value to position the text correctly
                        }}
                    
                      />

                    </div>

                  </div>




                  {AK_PUB.length !== 0 ? (
                    <OrgPublic
                      postId={postId}
                      arr={AK_PUB}
                      title={
                        role < 39
                          ? getTranslation("akunahPublicP")
                          : getTranslation("akunahPublic")
                      }
                      type={0}
                    />
                  ) : (
                    <SmallSkeleton></SmallSkeleton>
                    // <div className="text-center">{getTranslation("no_request_data")}</div>
                  )}
                </>
                {/* )} */}
                {AK_EXC.length !== 0 ? (
                  <>
                    {role > 39 && (
                      <>
                        <div className="clearfix"></div>
                        <OrgPublic
                          postId={postId}
                          arr={AK_EXC}
                          title={getTranslation("akunahExc")}
                          type={1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}

                <div className="">
                  <div className="row">
                    <div className="text-end d-flex">
                      {role === 0 || role === 40 ? (
                        <button
                          className="btn admin_bt d-inline-block text-center mt-0 ms-auto w-auto mob-btn"
                          onClick={handleProfileRedirect}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-people-fill me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            <path
                              fillRule="evenodd"
                              d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                            />
                            <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                          </svg>
                          {/* {role === 40 && <>{translation.request_to_join_p}</>} */}

                          {getTranslation('request_to_join_h')}
                        </button>
                      ) : (
                        <></>
                      )}
{/* 
                      {role === 40 ? (
                        <button
                          className="btn admin_bt d-inline-block text-center mt-0 ms-3 w-auto mob-btn"
                          onClick={handleCreateOrgRequest}
                        // onClick={() => setIsOpenorg(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-plus-circle-fill me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                          </svg>
                          {getTranslation('create_org')}
                        </button>
                      ) : null} */}
                    </div>
                  </div>
                </div>

                {ORG_PUB.length !== 0 ? (
                  <>
                    <div className="clearfix"></div>
                    <OrgPublic
                      postId={postId}
                      arr={ORG_PUB}
                      title={
                        role === 0
                          ? translation.OrgPublicPatient
                          : role === 70
                            ? getTranslation("myOrgPublic")
                            : getTranslation("OrgPublic")
                      }
                      type={2}
                    />
                  </>
                ) : (
                  ""
                )}
                {ORG_EXC.length !== 0 ? (
                  <>
                    {role > 39 && (
                      <>
                        <div className="clearfix"></div>
                        <OrgPublic
                          postId={postId}
                          arr={ORG_EXC}
                          title={
                            role === 70
                              ? getTranslation("myOrgExc")
                              : getTranslation("OrgExc")
                          }
                          type={3}
                        />
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
                {HCP_PUB.length !== 0 ? (
                  <>
                    <div className="clearfix"></div>
                    <OrgPublic
                      postId={postId}
                      arr={HCP_PUB}
                      title={
                        role === 40
                          ? translation.mypublic
                          : role === 0
                            ? translation.HCPPublicPatient
                            : translation.myhcppublic
                      }
                      type={4}
                    />
                  </>
                ) : (
                  ""
                )}
                {HCP_EXC.length !== 0 ? (
                  <>
                    <div className="clearfix"></div>
                    <OrgPublic
                      postId={postId}
                      arr={HCP_EXC}
                      title={
                        role === 40
                          ? translation.myprivate
                          : role === 0
                            ? translation.HCPPrivatePatient
                            : translation.myhcpprivate
                      }
                      type={5}
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="clearfix"></div>
                <Footer></Footer>
              </div>
            </Suspense>
          </div>
        </div>
        <div>
        </div>
        <Modal isOpen={showmodel} className="create-modal">
          <CreateOrganizationReq showmodel={showmodel} onCancel={onCancel} />
        </Modal>
      </main>

    </>
  );
};

export default Channels;
