import React, { useState } from 'react'
import Modal from "react-modal";
// import HttpService from "../services/HttpService";
import useAxios from '../axiosinstance';
import { ToastContainer, toast } from "react-toastify";
const NewUserPopup = ({ isOpen }) => {
    const axiosinstance = useAxios()
    const [isOpens, setIsOpens] = useState(true);
    const[disble,setDisble]=useState(false)
    
    // const toggleModal = () => {
    //     setIsOpens(false);
    // }



    const yesHcp = (e) => {
        setDisble(true)
        axiosinstance.current
            .post(`user?type=${e}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                //   toast.success("You are Connected as HCP");
                if (res.status === 200)
                    setIsOpens(false);
                window.location.reload(true);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                setIsOpens(false);
            });

    }




    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} />
            <div className="">
                <Modal
                    isOpen={isOpens}
                    contentLabel="My dialog"
                    className="mymodal helpdesk-modal"
                    overlayClassName="myoverlay"
                >
                    <div>

                        {/* <div className="modal-header">
                            <h5 className="modal-title">Akunah help</h5>
                        </div> */}
                        <div className="modal-body">
                            <div className="container heldesk-body">
                                <div className="row justifyContentCenter">
                                    <div className="row g-3 mt-2 text-center">
                                        <h5>Are you a Health Care Professional (HCP) ?</h5>
                                    </div>
                                    <div className='pop-up text-center'>
                                        <span>
                                            <button
                                                className="btn premium-btn "
                                                onClick={() => yesHcp(1)}
                                                disabled={disble}
                                            >
                                                <span aria-hidden="true">Yes</span>
                                            </button>
                                        </span>
                                        <span style={{ marginLeft: "29px" }}>
                                            <button
                                                className="btn premium-btn "
                                                onClick={() => yesHcp(0)}
                                            >
                                                <span aria-hidden="true">No</span>
                                            </button>
                                        </span>


                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Modal >


            </div >




        </>
    )
}

export default NewUserPopup
