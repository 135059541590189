import React, { useState, useEffect, useRef } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Modal from "react-modal";
import HttpService from "../services/HttpService";
import { ToastContainer, toast } from "react-toastify";
import { Validators } from "../utils/Validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxios from "../axiosinstance";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';








const CreateOrganizationReq = ({ showmodel, onCancel }) => {
  const axiosinstance = useAxios();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTile] = useState("");
  const [subj, setSubj] = useState("");
  const [errortitle, setFormerrortitle] = useState({});
  const [errorsubj, setFormerrorsubj] = useState({});
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const [listorg, setListOrg] = useState([]);
  const [selected, setSelected] = useState({});
  const [enable, setEnable] = useState(true)
  const [mailbutton, setMailButton] = useState(false)
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (showmodel) {
      setIsOpen(showmodel);
    }
  }, [showmodel]);
  const toggleModal = () => {
    onCancel(false);
    setIsOpen(false);
    setSubj("");
    setTile("");
    setFormerrortitle([]);
    setFormerrorsubj([]);
    navigate("/channels");
  };

  // useEffect(() => {
  //   if (title.length > 2) {
  //     setFormerrortitle([]);
  //   }
  // }, [title]);

  // useEffect(() => {
  //   if (subj.length > 2) {
  //     setFormerrorsubj([]);
  //   }
  // }, [subj]);

  // const validateForm = () => {
  //   let err = {};
  //   let flag = false;
  //   // if (title.length == 0) {
  //   //   errortitle.title = "Organization Name is missing";
  //   // }
  //   if (subj.length == 0) {
  //     errorsubj.subj = "Message is missing";
  //   }

  //   setFormerrortitle({ ...errortitle, errortitle });
  //   setFormerrorsubj({ ...errorsubj, errorsubj });

  //   console.log("err");
  //   if (
  //     Object.keys(errortitle).length == 0 ||
  //     Object.keys(errorsubj).length == 0
  //   ) {
  //     flag = true;
  //   } else {
  //     flag = false;
  //   }

  //   return flag;
  // };

  const sendMail = () => {
    let name;
    if (inputValue) {
      name = inputValue;
    }
    else {
      name = selected.org_name.split("(")[0].trim()
    }
    const json = {
      content: `Organization Name :- ${name}, HCP Name :- ${profile[0]?.first_name} ${profile[0]?.last_name} , HCP email :- ${profile[0]?.email}`,
      subject: "Request for Create Organization",
      org_name: name,
      email: profile[0]?.email
      // from: "noreply@akunah.com",
    };

    axiosinstance.current
      .post("request_to_create_organization", json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        onCancel(false);
        setSubj("");
        setTile("");
      })
      .catch((error) => {
        toast.error(error.response.data.data);
      });
  };

  const joinReqMail = () => {
    let name;
    if (inputValue) {
      name = inputValue;
    }
    else {
      name = selected.org_name.split("(")[0].trim()
    }
    const json = {
      content: `Organization Name :- ${name}, HCP Name :- ${profile[0]?.first_name} ${profile[0]?.last_name} , HCP email :- ${profile[0]?.email}`,
      subject: "Request for Join Organization",
      org_name: name,
      akou_id: selected.org_id,
      ak_id: profile[0]?.id,
      email: profile[0]?.email
      // from: "noreply@akunah.com",
    };

    
    axiosinstance.current
      .post("sendJoinRequestOrg", json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        onCancel(false);
        setSubj("");
        setTile("");
      })
      .catch((error) => {
        toast.error(error.response.data.data);
      });
  };




  const handleOnHoverorg = (result) => {

  };


  const handleOnSelectorg = (item) => {
  
    if (item) {
      setSelected(item);
      setInputValue("")
      setMailButton(true)
      setEnable(false)
    }

  };
  const handleOnFocusorg = (e) => {
  
    // axiosinstance.current.get(`orglist?hcp_id=`).then((res) => {
    //   setListOrg(res.data.result);
    // });
    // console.log("Focused");
  };



  const handleOnClear = () => {
    setEnable(true)
    setSelected({})
    setMailButton(false)
  };


  const handleOnSearchorg = (string, results) => {
    setInputValue(string)
    if (string.length === 2) {
      setTimeout(async () => {
        const orgdata = await axiosinstance.current.get(`orglist?keyword=${string}`)
        setListOrg(orgdata.data.result)
      }, 1000);
    }
    // setSelected({})
    setMailButton(false)

  };

  const handlePress = (e) => {
    
  }



  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };



  return (
    <>
      <div className="lang_ d-block hellppp">
        <img
          src="/Assets/image/callingimage.png"
          className="help-border image-sizeee"
          onClick={() => setIsOpen(true)}
          data-bs-placement="bottom"
          title="Akunah Helpdesk"
          alt=""
        />

        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          className="mymodal helpdesk-modal"
          overlayClassName="myoverlay"
        >
          <div>
            <button
              className="close"
              style={{ borderWidth: 0 }}
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggleModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-header">
              <h5 className="modal-title fw-600">
                {getTranslation('req_for_create_org')}
              </h5>
            </div>
            <div className="modal-body">
              <div className="row g-3  mt-2 justify-content-center">
                <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 for-input">
                  <h5 className="fs-12 ms-5" >{getTranslation('org_name')}*</h5>
                  {/* <input
                    type="text"
                    id="name"
                    name="first_name"
                    className="form-control "
                    onChange={(e) => setTile(e.target.value)}
                    value={title}
                  /> */}
                  <div className="row justifyContentCenter">
                    <div className="col-xl-10 col-md-10 col-sm-12 col-12">
                      <ReactSearchAutocomplete
                        items={listorg}
                        fuseOptions={{
                          shouldSort: true,
                          threshold: 0.6,
                          location: 0,
                          distance: 100,
                          maxPatternLength: 32,
                          minMatchCharLength: 3,
                          keys: ["org_name", "org_id"],
                        }} // Search on both fields
                        resultStringKeyName="org_name" // String to display in the results
                        inputSearchString={selected.lavel}
                        onSearch={handleOnSearchorg}
                        onHover={handleOnHoverorg}
                        onSelect={handleOnSelectorg}
                        placeholder={getTranslation('search_by_org_name')}
                        onFocus={handleOnFocusorg}
                        onClear={handleOnClear}
                        showIcon={false}
                        styling={{
                          height: "34px",
                          border: "1px solid black",
                          borderRadius: "4px",
                          backgroundColor: "white",
                          boxShadow: "none",
                          hoverBackgroundColor: "#EA702B",
                          color: "black",
                          fontSize: "12px",
                          fontFamily: "Courier",
                          iconColor: "green",
                          lineColor: "#EA702B",
                          placeholderColor: "black",
                          clearIconMargin: "3px 8px 0 0",
                          zIndex: 1,
                        }}
                      />
                    </div>
                  </div>


                  <span className="error-color">{errortitle.title}</span>
                </div>
              </div>
            </div>
            <div className="modal-footer footers">
              <div>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="btn premium-btn btn_org_upload new-btn"
                >
                  {getTranslation('cancel')}
                </button>
              </div>
              {
                mailbutton ? (
                  <div>
                    <button
                      type="button"
                      onClick={joinReqMail}
                      className="btn premium-btn btn_org_upload new-btn1"
                    >
                      {getTranslation('request_to_join_h')}
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={sendMail}
                      className="btn premium-btn btn_org_upload new-btn"
                    >
                      {getTranslation('send')}
                    </button>
                  </div>
                )
              }

            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CreateOrganizationReq;
