import React from "react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

// import { setSearch } from "../actions/searchAction";
import SmallSkeleton from "./smallSkeleton";
// import instance from "../axiosinstance";
import PostItemSmall from "./PostItemSmall";
// import { useLocation } from "react-router-dom";
import HttpService from "../services/HttpService";
// import { useNavigate } from "react-router-dom";
import PostItemSmalllink from "./PostItemSmalllink";
import useAxios from "../axiosinstance";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';





export default function SearchLists() {
  const [offset, setOffset] = useState(16);
  const axiosinstance =useAxios()
  // const location = useLocation();
  const [searchData, setSearch] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get("search");
  const search = queryParams.get("q");
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  // console.log("this is");
  // const post_id = atob(q)
  const limit = 16;

  // const { arr } = useSelector((state) => state.mainReducer.searchData);
  const [check, setcheck] = useState();

  const [loop, setLoop] = useState(false);
  const [show, setShow] = useState(false);

  // useEffect(() => {
  //   if(globalSearch.length===0){
  //   axiosinstance.current.post(`globalsearch`, { keyword: searchInput  }).then((response)=>{

  //       if (response) {
  //       //   setShow(true);
  //       dispatch(setGlobalArr(response.data.data));
  //       } else {
  //       //   setShow(false);
  //       }
  //     });
  //   }

  // }, []);
  useEffect(() => {
    if(profile.length>0){
      axiosinstance.current
      .post(`global_search?ak_id=${profile[0]?.id}&keyword=${search}&page=0&limit=16`)
      .then((response) => {
        if (response.data.data.length > 0) {
  
          setShow(true);

          // setPost(response.data.results);
          setSearch(response.data.data);
          setcheck(response.data.length);
        } else {
          setShow(true);
          setSearch([]);
          setcheck(0);
        }
      }).catch((err)=>{
  
        setSearch([]);
        setShow(true);
        setcheck(0);
      })
    }
  
  }, [profile,search]);

  const fetchNextUsers = () => {
    setOffset(offset + 16);
    if (loop === false) {
      axiosinstance.current
        .post(`global_search?ak_id=${profile[0]?.id}&keyword=${search}&page=${offset}&limit=16`)
        .then((response) => {
          let res = response.data;
          if (res.length === 0) {
            setLoop(true);
           
          } else {
            // console.log('after scroll',response.data.data)
            setSearch(searchData.concat(response.data.data));
          }
        });
    }
  };



  const getTranslation = (key) => {
		const translations =
		  profile[0]?.language === "en"
			? en
			: profile[0]?.language === "ger"
			  ? ger
			  : profile[0]?.language === "ita"
				? ita
				: profile[0]?.language === "ara"
				  ? ara
				  : profile[0]?.language === "spa"
					? spa
					: profile[0]?.language === "tha" ? tha
					  : profile[0]?.language === "kor" ? kor
						: fr;
		return translations[key] || key;
	  };



  return (
    <>
      <div className=""></div>
      <div className="trending">
        <div className="upcoming-head filter">
          <h4>{getTranslation('search')}</h4>
        </div>
        <div className="trendig-box">
          <InfiniteScroll
            dataLength={offset}
            next={fetchNextUsers}
            hasMore={true}
            loader={
              ""
              //   <div className="load_next text-center " style={{ 'textTransform': 'uppercase'}}>
              //   <span>No more Data</span>
              // </div>
            }
          >
            <div className="row">
              {!show && <SmallSkeleton></SmallSkeleton>}
              {show &&
                searchData.map((post) => (
                  <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {post.link === null || post.link === "" ? (
                      <PostItemSmall
                        id={post.id}
                        title={post.title}
                        posted_by={post.posted_by}
                        videoKey={post.video_key}
                        duration={post.duration}
                        large={post.large}
                        medium={post.medium}
                        views={post.views}
                        description={post.description}
                        data={post.date}
                        category={post.category_id}
                        featured={post.featured}
                        ap={post.akunah_public}
                        ae={post.akunah_exc}
                        op={post.org_public}
                        oe={post.org_exc}
                        hp={post.hcp_public}
                        he={post.hcp_exc}
                        sessionId={post.sessionId}
                        profile={post.profile}
                        type={post.typeId}
                        link={post.link}
                      />
                    ) : (
                      <PostItemSmalllink
                        id={post.id}
                        title={post.title}
                        posted_by={post.posted_by}
                        videoKey={post.video_key}
                        duration={post.duration}
                        link={post.link}
                        large={post.large}
                        medium={post.medium}
                        views={post.views}
                        description={post.description}
                        data={post.date}
                        category={post.category_id}
                        featured={post.featured}
                        ap={post.akunah_public}
                        ae={post.akunah_exc}
                        op={post.org_public}
                        oe={post.org_exc}
                        hp={post.hcp_public}
                        he={post.hcp_exc}
                        type={post.typeId}
                        sessionId={post.sessionId}
                        profile={post.profile}
                       
                      />
                    )}
                  </div>
                ))}
            </div>
          </InfiniteScroll>
          {/* <div className="load_next text-center " style={{ 'text-transform': 'uppercase'}}>
            <span>No more Data</span>
          </div> */}
          {check === 0 && (
            <div className="no_data">
              <h2>{getTranslation('no_videos_found')}</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
