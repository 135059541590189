import React, { useEffect } from "react";
import Header from "../Component/Header";
import SideBar from "../Component/Sidebar";
import { useSelector } from "react-redux";
import SearchList from "../Component/SearchList";
import appStyle from "../config/appStyle";
import Footer from "../Component/Footer";
// import UserService from "../services/UserService";
// import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import SearchLists from "../Component/SearchLists";
// import authConfig from "../config/authConfig";
export default function SearchPage() {
  // login check
  const [searchParams, setSearchParams] = useSearchParams();
  let searchData = searchParams.get("q");
  let searchType = searchParams.get("search");

  // const check = function loginCheck() {
  //   return UserService.isLoggedIn();
  // };
  // const abc = check();

  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (!abc) {
  //     navigate("/loader");
  //   }
  // }, []);
  // login check end
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  document.title = "Akunah Med || Search";
  return (
    <>
      <Header></Header>
      <main className="mt-4" dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
              <SideBar></SideBar>
            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
              {searchType === "Y" ? <SearchLists></SearchLists> : <SearchList />}

              <Footer></Footer>
              {/* Trending end */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
