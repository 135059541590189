// import { useEffect, useRef } from 'react';
// import axios from 'axios';
// import { useKeycloak } from '@react-keycloak/web';
// const useAxios = (baseURL) => {
//   const axiosInstance = useRef();
//   const { keycloak, initialized } = useKeycloak();
//   const kcToken = keycloak.token;
//   useEffect(() => {
//     axiosInstance.current = axios.create({
//       baseURL: process.env.REACT_APP_APIURL,
//       headers: {
//         Authorization: initialized ? `Bearer ${kcToken}` : undefined,
//       },
//     });
//     return () => {
//       axiosInstance.current = undefined;
//     };
//   }, [baseURL, initialized, keycloak]);
//   useEffect(() => {
//     const refreshToken = async () => {
//       if (initialized && keycloak.authenticated) {
//         const refreshed = await keycloak.updateToken(-1);
//         if (refreshed) {
//           console.log('Token has been refreshed');
//         } else {
//           console.log('Token refresh failed');
//         }
//       }
//     };
//     refreshToken();
//   }, [initialized, keycloak]);
//   return axiosInstance;
// };
// export default useAxios;
import { useEffect, useRef } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
const useAxios = (baseURL) => {
  const interval = setInterval(5, 60 * 1000);
  const axiosInstance = useRef();
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
//  setInterval(() => {
    // const refresh = async () => {
    //   if (initialized && keycloak.authenticated) {
    //     const refreshed = await keycloak.updateToken(-1);
    //     if (refreshed) {
    //       console.log("Token has been refreshed");
    //     } else {
    //       console.log("Token refresh failed");
    //     }
    //   }
    // };
    const createAxiosInstance = () => {
      axiosInstance.current = axios.create({
        baseURL: process.env.REACT_APP_APIURL,
        headers: {
          Authorization:
            initialized && keycloak.token
              ? `Bearer ${keycloak.token}`
              : undefined,
        },
      });
      // axiosInstance.current.interceptors.request.use(async (config) => {
      //   if (initialized && keycloak.authenticated) {
      //     const refreshed = await keycloak.updateToken(-1);
      //     if (refreshed) {
      //       console.log("Token has been refreshed");
      //       config.headers.Authorization = `Bearer ${keycloak.token}`;
      //     } else {
      //       console.log("Token refresh failed");
      //     }
      //   }
      //   return config;
      // });
    };
    createAxiosInstance();
    // const interval = setInterval(refresh, 60 * 1000);
    // return () => {
    //   clearInterval(interval);
    //   axiosInstance.current = null;
    // };
  // }, 30000); // Adjust the delay (in milliseconds) as needed
    
 
},[interval]);
  return axiosInstance;
};
export default useAxios;
