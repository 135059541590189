import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import { validateDropdown } from "../../../utils/Validator";

const Dropdown = forwardRef(
  (
    {
      value,
      label,
      data,
      placeholder,
      styleClass,
      validators,
      onChange,
      disabled,
      className,
    },
    ref
  ) => {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
      const { value } = event.target;
      setError(validateDropdown(validators, value));
      onChange(value);
    };
    useImperativeHandle(ref, () => ({
      errUpdate(e) {
        setError(validateDropdown(validators, ""));
        console.log("err", validators, value);
      },
    }));
    return (
      <div className="row">
        <div className={`form-group ${styleClass}`}>
          {label && <label htmlFor="app-input-field">{label}</label>}
          <select
            value={value}
            className={`form-control form-select form-select-sm p-2  ${className} `}
            onChange={handleChange}
            onBlur={handleChange}
            disabled={disabled}
            readOnly={disabled}
           
            
          >
            <option value="" className="">
              {placeholder}
            </option>
            {data.map((item, key) => (
              <option key={key} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {error && <span className="text-danger">{error.message}</span>}
        </div>
      </div>
    );
  }
);

Dropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array.isRequired,
  validators: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  type: "text",
  validators: [],
  rows: "",
  cols: "",
  readOnly: "",
  errMsg: "",
};

export default Dropdown;
