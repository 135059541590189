import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HttpService from "../services/HttpService";
import useTranslation from "./customHooks/translations";
import Avatar from "react-avatar";

const Notification = () => {
  const translation = useTranslation();
  const [notifiction, setNotifiction] = useState([]);
  const [sub, setSub] = useState([]);

  const [show, setShow] = useState(false);



  


  // const [noti, setnoti] = useState(' ');

  let notie = sub.concat(notifiction);

 

  return (
    <div className="me-2" style={{ margin: "auto" }}>
      <div className="dropdown dropstart text-end" data-bs-toggle="tooltip" data-bs-placement="left" title="Notification">
        {/* <i className=" " data-bs-toggle="dropdown">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width='20' height='20'>
            <path d="M256 32V49.88C328.5 61.39 384 124.2 384 200V233.4C384 278.8 399.5 322.9 427.8 358.4L442.7 377C448.5 384.2 449.6 394.1 445.6 402.4C441.6 410.7 433.2 416 424 416H24C14.77 416 6.365 410.7 2.369 402.4C-1.628 394.1-.504 384.2 5.26 377L20.17 358.4C48.54 322.9 64 278.8 64 233.4V200C64 124.2 119.5 61.39 192 49.88V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32V32zM216 96C158.6 96 112 142.6 112 200V233.4C112 281.3 98.12 328 72.31 368H375.7C349.9 328 336 281.3 336 233.4V200C336 142.6 289.4 96 232 96H216zM288 448C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288z" />
          </svg>
        </i> */}

        <ul className="dropdown-menu notif p-3 overflow-auto ">
          <li>
            <h5 className="dropdown-header1">{translation.notifications}</h5>
            <hr></hr>
          </li>

          {/* {sub.map((post) => (
            <li
              className="notifiction-list alert  alert-dismissible fade show"
              role="alert"
              key={post.notify_id}

            >
              <div
                className="notif-box"
                onClick={() => handlechange(post.notify_id)}
              >
                <div className="notif-title">
                  <p className="title">{post.message}</p>
                  {/* <p className="sub-title">
                    
                    </p> */}
                  {/* <span>{post.time}.</span>
                </div>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => handlechange(post.notify_id)}
              ></button>
            </li>
          ))} */} 

          {/* {notifiction.map((post) => (
            <li
              className="notifiction-list alert  alert-dismissible fade show"
              role="alert"
              key={post.notify_id}
            >
              {post.link === null && (
                <Link to="/details" state={{ id: post.post_id }}>
                  <div
                    className="notif-box"
                    onClick={() => handlechange(post.notify_id)}
                  >

                    <div className="notif-img">
                      {/* <img src={post.profile_pic} alt=""></img> */}
                      {/* {post.profile_pic !== "" && (
                        <img src={post.profile_pic} alt="" className="img-fluid"></img>
                      )}
                      {post.profile_pic === "" && (
                        <Avatar
                          className="me-2"
                          name={post.name}
                          size="26"
                          maxInitials="2"
                          round={true}
                          fgColor="#EA702B"
                          color="#F3CDB8"
                        />
                      )}
                    </div>
                    <div className="notif-title">
                      <p className="title">{post.name}</p>
                      <p className="sub-title">{post.title.slice(0, 32)}...</p>
                      <span>{post.time}.</span>
                    </div>
                  </div>
                </Link>
              )}
              {post.link && (
                <a href={post.link}>
                  <div */}

                    {/* className="notif-box"
                    onClick={() => handlechange(post.notify_id)}
                  >
                    <div className="notif-img">



                      {post.profile_pic !== "" && (
                        <img src={post.profile_pic} alt="" className="img-fluid"></img>
                      )}
                      {post.profile_pic === "" && (
                        <Avatar
                          className="me-2"
                          name={post.name}
                          size="26"
                          maxInitials="2"
                          round={true}
                          fgColor="#EA702B"
                          color="#F3CDB8"
                        />
                      )}

                    </div>
                    <div className="notif-title">
                      <p className="title">{post.name}</p>
                      <p className="sub-title">{post.title.slice(0, 32)}...</p>
                      <span>{post.time}.</span>
                    </div>
                  </div>
                </a>
              )}

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => handlechange(post.notify_id)}
              ></button>
            </li>
          ))} */}
          {/* {notie.length === 0 && ( */} 

            <div className="text-center">
              <p>All caught up!</p>
            </div>
          {/* )} */}

        </ul>
      </div>
    </div>
  );
};

export default Notification;
