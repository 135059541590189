import React, { useEffect, useState } from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import { useSelector,useDispatch } from "react-redux/es/exports";
import { setArr } from "../actions/profileAction";

import useAxios from "../axiosinstance";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


export default function LanguageHandler() {
  const dispatch = useDispatch();
  const { language, changeLanguage } = useLanguageContext();
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  let akToken = localStorage.getItem("akTokenMed");
  const axiosinstance = useAxios();
  const [languages, setLanguages] = useState([])
  const [lang, setLang] = useState("")
  const GetLanguages = () => {
    axiosinstance.current.get(`getLanguages`).then((response) => {
      if (response) {
        setLanguages(response.data.data)
      }
    });
  };










  useEffect(() => {
    GetLanguages();
  }, [])


  const handleChangeLanguage = (selectedLanguage) => {
    setLang(selectedLanguage);
    sessionStorage.setItem("langg", selectedLanguage)

    axiosinstance.current
      .patch(`update_language?ak_id=${profile[0]?.id}&id=${selectedLanguage}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        GetResult()
      })
      .catch(function (error) {
        console.log("Language Not Update")
      });
  };

  const GetResult = () => {
      axiosinstance.current.get(`getUser?ak_id=${akToken}`).then((response) => {
        if (response) {
          dispatch(setArr(response.data));
        }
      });
  };
  useEffect(() => {
      GetResult();
  }, []);
  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };



  return (
    <>
      <div className="lang_ d-none d-lg-block " >
        <select onChange={(e) => handleChangeLanguage(e.target.value)} value={profile[0]?.language_id} className="language" style={{ WebkitAppearance: "none" }}>
          {
            languages.map((ele) => (
              <option value={ele.id}>{ele.label}</option>
            ))
          }
        </select>
      </div>



      {/* <div className="lang_ d-block d-lg-none ">
       <select value={language} className="language" onChange={(e) => changeLanguage(e.target.value)}>
         <option value="en">En </option>
         <option value="fr">Fr </option>
       </select>
       
     </div> */}

    </>
  );
}
