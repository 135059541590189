// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: 'Tutti i video',
  upcoming: 'In arrivo',
  trending: 'In tendenza',
  recently_added: 'Aggiunti di recente',
  live: 'Eventi in diretta',
  library: 'I miei preferiti',
  view_by_catagories: 'Visualizza per specialità',
  loading: 'Caricamento...',
  filter: 'Filtro"',
  profile: 'Profilo',
  profile_photo: 'Foto del profilo',
  first_name: 'Nome',
  last_name: 'Cognome',
  update_profile: 'Aggiorna profilo',
  Update_Profiless: "AGGIORNA PROFILO",

  edit_profile: 'Modifica profilo',
  date_of_birth: 'Data di nascita',
  address: 'Indirizzo',
  registered_email: 'Email registrata',
  registered_phone: 'Telefono registrato',
  subscription_key: 'Chiave di abbonamento',
  renew_key: 'RINNOVA CHIAVE',
  start_date: 'Data di inizio',
  end_date: 'Data di fine',
  no_more_data: 'Nessun altro dato',
  search: 'Ricerca',
  logout: 'Esci',
  view_profile: 'Visualizza profilo',
  notifications: 'Notifiche',
  Get_Premium: 'Ottieni Premium',
  App_Version: "Versione dell'app",
  Get_3_months_of_premium_for_free: 'Ottieni 3 mesi di premium gratuitamente.',
  Click_below_to_sign_up_for_premium_Akunah_MEd: 'Clicca qui sotto per iscriverti ad Akunah Med Premium.',
  request_to_join: 'Unisciti come paziente a HCP',
  request_to_join_p: "Unisciti come paziente all'organizzazione",
  request_to_join_h: "Unisciti all'organizzazione",
  lives: 'In diretta',
  plan: 'Tutti i piani',
  channels: 'Home',

  akunahPublicP: "I miei video sulla salute", //Not Done
  OrgPublicPatient: 'I miei video di salute del provider',
  HCPPublicPatient: 'I miei video del medico',
  HCPPrivatePatient: 'I miei video esclusivi',
  akunahExc: 'Akunah esclusivo',
  akunahPublic: 'Akunah Global Public',
  OrgPublic: 'My Organization Public',
  myOrgPublic: 'Mio pubblico',
  myOrgExc: 'Mia esclusiva',
  OrgExc: 'Esclusivi della mia organizzazione',
  mypublic: 'my public',
  myprivate: 'miei esclusivi',
  myhcppublic: 'my hcp public',
  myhcpprivate: 'miei hcp esclusivi',

  akunah_how_tos: "Akunah Come fare",
  Upload_a_Video: "Carica un video",
  administration: "Amministrazione",
  view_all: "Visualizza tutto",



  Channelsdata: "Canali",
  login_by: "Accedi tramite",
  upload_video: "F-Upload Video",
  type_of_content: "Tipo di contenuto",
  title: "Titolo",
  thumb_imag1: "Immagine in miniature (dimensioni 720 x 405)",
  thumb_imag2: "Immagine in miniature (dimensioni 480 x 270)",
  video: "Video",
  tags: "Tag",
  link: "F-Streaming Link",
  organization: "Organizzazione",
  tags_channels: "Tag Canali",
  description: "Descrizione",
  publish: "Pubblica",
  submit: "Invia",


  manage_my_videos: "Gestisci i miei video",
  manage_my_organizations: "Gestisci le mie organizzazioni",
  manage_my_hcps: "Gestisci i miei HCP",
  manage_org_patient: "Gestisci i pazienti dell'organizzazione",
  manage_my_patients: "Gestisci i miei pazienti",
  manage_tags: "Gestisci i tag",
  manage_my_plans: "Gestisci i miei piani",











  video_list: "Elenco video",
  upload_videos: "Carica video ",//Not Done
  limit: "Limite",
  type: "Tipo",
  posted_by: "Pubblicato da",
  organization: "Organizzazione",
  s_no: "S No.", //Not get anything from excel
  video_id: "ID video",
  duration: "Durata",
  video_size: "Dimensione del video",
  actions: "Azioni",
  add_a_organization: "Aggiungi un'organizzazione",
  org_name: "Nome dell'organizzazione",
  size: "Dimensione",
  watch_time: "Tempo di visione",
  sort: "Ordina",
  status: "Stato",
  organization_Id: "ID dell'organizzazione",
  total_post_size: "Dimensione totale degli invii",
  total_watch_time: "Tempo di visione totale",
  add_tags: "Aggiungi tag",
  order: "Ordine",
  add: "Aggiungi",
  edit: "Edit", //Not Done
  tags_list: "Elenco tag",
  add_plan: "Aggiungi piano",
  plan_name: "Nome del piano",
  number_of_hcp: "Numero di HCP",
  number_of_patient: "Numero di Pazienti",
  Limit: "Limite (GB)",
  plan_list: "Elenco piani",
  noofhcp1: "Numero di HCP",
  nopatient1: "n",
  save: "Salva",
  add_channels: "Aggiungi canali",
  name: "Nome",
  role: "Visibile per ruolo(i)", 
  all: "Richiedi pubblicazione",
  users: "Utenti",
  user_type: "Tipo utente",
  list_of_channels: "Elenco canali",
  event_Link: "Collegamento all'evento", 
  visible_till: "* Visibile fino a", 
  tags_channels: "Tag Canali", 
  manage_hcps: "Gestisci Operatori Sanitari", 
  pending_hcp_req: "Richiesta Operatore Sanitario in sospeso", 
  invite_hcp: "Invita Operatore Sanitario",
  email: "E-mail",
  delete_hcp: "Sei sicuro di volere eliminare l'operatore sanitario?",
  no: "No",
  yes: "Si",
  add_email: "Aggiungi e-mail",
  view_plans: "Visualizza piani",
  activate_date: "Data attiva",
  expiry_date: "Data di scadenza",
  hcp: "Operatore Sanitario",
  patient: "Paziente",
  total_hcp: "Totale Operatori Sanitari",
  total_patient: "Totale Pazienti",
  invite_admin: "Invita amministratore",
  autoid: "ID automatico",
  delete_admin: "Sei sicuro di voler eliminare come amministratore?",
  admin_user_email: "Email utente amministratore",
  view_admins: "Visuallizza amministratori",
  view_HCPs: "Visuallizza HCP's",
  patient_list: "Elenco dei pazienti",
  pending_patient_req: "Richiesta pazienti in sospeso", 
  invite_patient: "Invita paziente",
  patient_name: "Nome del paziente",
  delete_patient: "Sei sicuro di volere eliminare il paziente?",
  no_videos_found: "Nessun video trovato",
  not_found_trending: "Nessuno video in tendenza trovato",
  upload_video_popup: "Devi creare la tua organizzazione per caricare video", 
  create_org: "Crea organizzazione",
  list_of_user_profiles: "Elenco dei profili utenti",
  all_speciality: "Tutte le specialità",
  channel_delete: "Sei sicuro di voler eliminare questo elemento?", 
  req_for_create_org: "Richiesta di creazione organizzazione", 
  cancel: "Annulla",
  send: "Invia",
  akunahhandbook: "Akunah HandBook",
  help: "Akunah Help",
  subject: "Oggetto",
  message: "Messaggio",
  no_live_video: "Nessun video in diretta trovato",
  hcp_name: "Nome Operatore Sanitario",
  expiry_days: "Giorni alla scadenza",
  send_req: "Invia richiesta",
  search_by_speciality: "Cerca per specialità",
  no_request_data: "Nessun dato richiesto",
  no_data_found: "Nessun dato trovato", 
  approve_patient_list: "Approva elenco pazienti",
  create_event: "Crea un evento",
  live_event: "Evento in diretta",
  switch_profile: "Cambia profilo",
  event_link: "Collegamento all'evento", 
  search_by_patient_email_id: "Cera per paziente e indirizzo e-mail",
  searchbyb_title: "Cerca per titolo",
  search_by_org_name: "Cerca per nome dell'organizzazione",
  enter_email_address: "Inserisci l'indirizzo e-mail",
  no_of_days: "Numero di giorni",



  //12/7/2023// need to translate

  acceopt: "Accetta",
  reject: "Rifiuta",
  approved: "Approvato",
  select: "Seleziona",
  active: "Attivo",
  deactive: "Disattivo",
  select_plan: "Seleziona piano",
  views: "Visualizzazioni",
  view_patients: "Visualizza pazienti",
  valid_email: "Inserisci un'email valida !!!",
  is_already_created: "è già stata creata",
  req_to_hcps: "Richiesta agli Operatori Sanitari",
  approve: "Approva",
  upload: "CARICA",
  search_by_name: "Cerca per nome",
  global_channel: "Canale globale",
  all_users: "Tutti gli utenti",
  patients: "Pazienti",
  HCPs: "Operatore Sanitario",
  selected_users: "Utenti selezionati",
  post_updated: "Post aggiornato",
  video_deleted: "Video eliminato con successo",
  ascending: "Crescente",
  descending: "Decrescente",
  all_drop: "Tutti",
  akunah: "Akunah",
  channel_added: "Canale aggiunto",
  plan_status: "Stato del piano modificato",
  tag_added: "Tag aggiunto",
  tag_updated: "Tag aggiornato",
  hcp_deleted: "Operatore Sanitario eliminato con successo",
  pending: "In sospeso",
  patient_deleted: "Paziente eliminato con successo",
  is_new_patient: "è stato aggiunto un nuovo paziente",
  new_plan_added: "Nuovo piano aggiunto",
  org_added: "Organizzazione aggiunta",
  date: "Data",
  missing_subj: "Manca il soggetto",
  missing_message: "Manca il messaggio",
  select_user: "Seleziona utente",
  field_req: "Questo campo è obbligatorio",
  select_org: "Seleziona organizzazione",
  is_already_org: "è già presente nell'organizzazione",
  org_error: "Questa organizzazione è già stata creata. Unisciti invece di crearne una nuova.",
  email_sent: "Email inviata con successo",
  content_body: "Il corpo del contenuto è mancante",
  not_sent_email: "email non inviata",
  no_user_found: "Nessun utente trovato",
  profile_update: "Aggiornamento del profilo",
  req_sent: "Richiesta inviata",
  req_to_publish: "Richiesta di pubblicazione",
  no_req: "Nessuna richiesta",
  akunah_global_public: "Akunah Global Public",
  akunah_global_patient: "Akunah Global Patient",
  enter_org_name: "inserisci il nome dell'organizzazione",
  select_a_plan: "Seleziona un piano",
  Plan: "Piano",
  admin_deleted: "Amministratore eliminato",
  email_added: "Email aggiunta",
  email_not_added: "Email non aggiunta",
  plan_not_added: "Piano non aggiunto",
  hcp_not_delete: "Operatore Sanitario non eliminato",
  hcp_approved: "Operatore Sanitario approvato con successo",
  hcp_reject: "Operatore Sanitario rifiutato",
  channel_updated: "Canale aggiornato",
  channel_deleted: "Canale eliminato con successo",
  limit_error: "Il limite deve essere maggiore di 0",
  days: "Giorni",
  gb: "GB",
  plan_created: "Piano creato",
  rejected: "Respinto",
  expired: "Scaduto",
  search_by_hcp_name: "Cerca per nome dell'operatore sanitario",



  name_already_present: "Il nome è già presente",
  already_req_hcp: "Hai già richiesto a questo Operatore Sanitario",
  already_req_org: "Hai già richiesto a questa organizzazione",

  video_not_delete: "Video non eliminato",
  video_updated_success: "Video aggiornato con successo",
  Doctor: "Medico",
  Patient: "Paziente",
  favorite: "preferito",
  unfavourite: "Non preferito",


  admin: "Amministratore",
  org_admin: "Amministratore dell'organizzazione",

  video_upload_success: "Video Uploaded Successfully",//Not Done
  live_event_create_success: "Live Event Create Successfully", //Not Done



  privacy: "La tua privacy Facendo clic su 'Accetta' tutti i cookie, accetti che akunah Med possa memorizzare i cookie sul tuo dispositivo e divulgare informazioni in conformità con il nostro",
  cookie: "Gestione dei Cookie",
  my_profiles:"I miei profili",

  not_found_recent:"Nessuno video recente trovaton"


}