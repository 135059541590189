const initialValues = {
    channels: [],
    org_channel: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialValues, action) => {
    switch (action.type) {

        case 'SET_CHANNEL_LIST':
            return {
                channels: action.payload
            };
        case 'SET_CHANNEL_ORG_LIST':
            return {
                ...state,
                org_channel: action.payload
            };

        default:
            return state;
    }
}
