import React, { useEffect } from "react";
import SideBar from "../Component/Sidebar";
import Header from "../Component/Header";
import axios from "axios";
import instance from "../axiosinstance";
import ProfileData from "../Component/profileData";
import UserService from "../services/UserService";
import { useSelector } from "react-redux";
import appStyle from "../config/appStyle";
import Footer from "../Component/Footer";
// import RenderOnAnonymous from "../Component/RenderOnAnonymous";
// import RenderOnAuthenticated from "../Component/RenderOnAuthenticated.jsx";
import { useNavigate } from "react-router-dom";
// import authConfig from '../config/authConfig';
import Login from "./Login"
document.title = "Akunah Med || Profile";
export default function Profile() {
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  // login check
  //  useEffect(() => {
  //   if (authConfig.token === "undefined") {
  //       window.location.pathname = '/Login'
  //   }
  // }, [])
 // login check
//  const check = function loginCheck() {
//   return UserService.isLoggedIn();
// };
// const abc = check();

// const navigate = useNavigate();
//      useEffect(() => {
//   if (!abc) {
//     navigate("/loader");
//   }
// },[])
// login check end

  useEffect(() => {
    axios.get(instance.heartBeatChecker + "api/v1/ping").then((res) => {
      // console.log(res.data);
    });
  }, []);

  //   console.log(arr);
  return (
    <>
        <Header></Header>

        <main className="mt-4" dir={profile[0]?.language === 'ara' ? "rtl" : 'ltr'}>
          <div className="container-fluid">
            <div className="row">
              <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
                <SideBar></SideBar>
              </div>
              <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
                <ProfileData></ProfileData>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </main>
    
    </>
  );
}
