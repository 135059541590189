import React, { Suspense } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import appStyle from "../config/appStyle";
import { useState, useEffect } from "react";
import { setCatagoryArr } from "../actions/catagoryAction";
import useTranslation from "../Component/customHooks/translations";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import HttpService from "../services/HttpService";
import UserService from "../services/UserService";
import Select from "react-select";
import { setArr } from "../actions/profileAction";
import { useNavigate } from "react-router-dom";
import useAxios from "../axiosinstance";
const Header = React.lazy(() => import("../Component/Header"));
const Upcoming = React.lazy(() => import("../Component/Upcoming"));
const SideBar = React.lazy(() => import("../Component/Sidebar"));
// const Categories = React.lazy(() => import("../Component/Categories"));
const Trending = React.lazy(() => import("../Component/Trending"));
const Recently = React.lazy(() => import("../Component/Recently"));
const Footer = React.lazy(() => import("../Component/Footer"));
// const NewCategories =React.lazy(() => import("../Component/NewCategories.js"));
const Main = () => {
  const navigate = useNavigate();
  const axiosinstance =useAxios()
  // console.log("main page")
  const translation = useTranslation();
  // const log=UserService.isLoggedIn()
  // const check = function loginCheck() {
  //   return UserService.isLoggedIn();
  // };
  // const abc = check();
  // useEffect(() => {
  //   if (!abc) {
  //     navigate("/loader");
  //   }
  // }, []);
  const { show } = useSelector((state) => state.mainReducer.navData);
  document.title = "Akunah Med || Home";
  const [shows, setShows] = useState(false);
  // const [flag, setflag] = useState(false);
  const [role, setRole] = useState(20);
  // setflag(true)
  const dispatch = useDispatch();
  const { arr } = useSelector((state) => state.mainReducer.catagoryData);
  useEffect(() => {
    if (arr.length < 1) {
      axiosinstance.current.get("categories?status=1").then((response) => {
        if (response) {
          setShows(true);
          dispatch(setCatagoryArr(response.data));
        } else {
          setShows(false);
        }
      });
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(false);
    sessionStorage.setItem("model", false);
  }
  // getuser ----details----
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const GetResult = () => {
    axiosinstance.current.get("getUser").then((response) => {
      if (response) {
        dispatch(setArr(response.data));
      }
    });
  };
  useEffect(() => {
    if (profile.length === 0) {
      GetResult();
    }
  }, []);
  // end get user =
  useEffect(() => {
    if (profile.length != 0) {
      const timer = setTimeout(() => {
        setRole(profile[0].role_value);
        let v = profile[0].bool;
        if (v === false) {
          if (sessionStorage.getItem("model")) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        } else {
          setIsOpen(false);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
    }),
    // control: (styles) => ({
    //   ...styles,
    //   cursor: 'pointer',
    // }),
  };
  const [selectedOption, setSelectedOption] = useState("");
  const [show1, setShow1] = useState(false);
  const Showsearch = () => {
    if (show1 == false) {
      setShow1(true);
    } else {
      setShow1(false);
    }
  };
  function handleChange(e) {
    navigate(`/search?cat_id=${btoa(e.value.id)}`);
    setSelectedOption(e.value.id);
  }

  return (
    <>
      <Suspense
        fallback={<div className="text-center">{translation.loading}</div>}
      >
        <Header></Header>
      </Suspense>
      <main className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
              <Suspense
                fallback={
                  <div className="text-center">{translation.loading}</div>
                }
              >
                <SideBar role={role} />
              </Suspense>
              {/* {noRender} */}
            </div>
            <Suspense
              fallback={
                <div className="text-center">{translation.loading}</div>
              }
            >
              <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
                {/* categories   */}

                <div className="categories">
                  <div className="upcoming-head">
                    <h4>{translation.view_by_catagories}</h4>
                  </div>
                  <div className="row">
                    <div className="col-sm-10 col-6">
                      <div className="categories-title">
                        <OwlCarousel
                          className="owl-theme fs-1"
                          loop={false}
                          margin={5}
                          dots={false}
                          nav={true}
                          autoplayTimeout={5000}
                          autoplay={false}
                          // stagePadding={20}
                          // mergeFit={true}
                          autoWidth={true}
                          responsive={{
                            0: { items: 1 },
                            360: { items: 2 },
                            400: { items: 2 },
                            599: { items: 3 },
                            1024: { items: 4 },
                            1300: { items: 5 },
                            1600: { items: 6 },
                          }}
                        >
                          {/* {!show && <div>{translation.loading}</div>}
                      {show && */}
                          {arr.map((post) => (
                            <div className="item" key={post.id}>
                              <div className="categories-list ">
                                <h6>
                                  {" "}
                                  <Link
                                    to={`/search?cat_id=${btoa(post.id)}`}
                                    state={{ from: post.id }}
                                  >
                                    {post.title}
                                  </Link>
                                </h6>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                    </div>
                    <div className="col-sm-2 col-6 p-0 ">
                      <div className="dropdown ">
                        <button
                          className="btn admin_bt dropdown-toggle text-center mt-0 w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => Showsearch()}
                        >
                          All speciality
                        </button>
                        {show1 && (
                          <Select
                            placeholder="Search by speciality"
                            defaultValue={selectedOption}
                            onChange={handleChange}
                            // keepOpen={true}
                            // dropdownHandle={true}
                            styles={customStyles}
                            menuIsOpen={true}
                            options={arr.map((guest, index) => {
                              return {
                                label: guest.title,
                                value: guest,
                                key: index,
                              };
                            })}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                {/* categories end */}
                {/* upcoming start */}
                <Upcoming></Upcoming>
                {/* upcoming End */}
                {/* View by categories start */}
             
                {/* <NewCategories onSubmit={getData} /> */}
               
                {/* Trending end */}
                {/* <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModal}
                  contentLabel="My dialog"
                  className="mymodal d-none"
                  overlayClassName="myoverlay"
                  // closeTimeoutMS={5}
                >
                  <div>
                    <button
                      className="close"
                      style={{ borderWidth: 0 }}
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={toggleModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        You don't have any subscription. Please subscribe to
                        view all videos.
                      </h5>
                    </div>
                  </div>
                </Modal> */}
                {/*Model end*/}
                <div className="clearfix"></div>
                <Trending></Trending>
                {/* Recently added */}
                <div className="clearfix"></div>
                <Recently></Recently>
                <Footer></Footer>
              </div>
            </Suspense>
          </div>
        </div>
      </main>
    </>
  );
};
export default Main;
