import React, { useState,useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "react-modal";
// import HttpService from "../services/HttpService";
import useAxios from "../axiosinstance";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn';


import {useSelector } from "react-redux";

export default function HandBook({type}) {
const axiosinstance =useAxios()
// let bookLink = localStorage.getItem("akunahhandbook")
// console.log("bookLink",bookLink)
  const [isOpen, setIsOpen] = useState(false);
  const [link,setLink] = useState("")
  const { profile } = useSelector((state) => state.mainReducer.profileData);

  const toggleModal = () => {
    setIsOpen(false);
  }

const getLink = ()=>{
  axiosinstance.current
  .get("get_user_manual")
  .then((res) => {
  setLink(res.data.link)
  })
  .catch((error) => {
    console.log(error)
  });
}


  useEffect(() => {
    getLink()
   }, [])
   
// console.log("link",link)


  const docs = [

    { uri: link ,fileType: "pdf" },
  ];

  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };

  return (
    <>
    {
      type==="1" && (
        <div className="lang_ d-block bookk">
        <img src="/Assets/image/OP_Notes.svg" alt="" onClick={() => setIsOpen(true)} data-bs-toggle="tooltip" data-bs-placement="bottom" title={getTranslation("akunahhandbook")} />
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          className="mymodal model_org mt-4"
          overlayClassName="myoverlay"
          ariaHideApp={false}
        >
          <div>
            <button
              className="close mt-3  mob_close_btn"
              style={{ borderWidth: 0 }}
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggleModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{getTranslation('akunahhandbook')}</h5>
            </div>
            <div className="modal-body doc-size">
              <div className="container pdf_view" style={{marginLeft:"-3%"}}>
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                    documents={docs}
                    initialActiveDocument={docs[0]}
                    prefetchMethod="GET"
                    config={{
                      header: {
                        disableHeader: false,
                        disableFileName: true,
                        retainURLParams: true,
                      },
                    }}

                    
                    className="mob_doc_view"
                    style={{ height: "75vh", }}

                  />
                </div>
             
            </div>
          
          </div>
        </Modal>
      </div>
      )
    }

    {
      type==="2" && (
        <div className="bookk">
        <img src="/Assets/image/hand.svg" alt="" onClick={() => setIsOpen(true)} data-bs-toggle="tooltip" data-bs-placement="bottom" title={getTranslation("akunahhandbook")} />
        Hand Book
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          className="mymodal model_org mt-4"
          overlayClassName="myoverlay"
          ariaHideApp={false}
        >
          <div>
            <button
              className="close mt-3  mob_close_btn"
              style={{ borderWidth: 0 }}
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggleModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{getTranslation('akunahhandbook')}</h5>
            </div>
            <div className="modal-body doc-size">
              <div className="container pdf_view" style={{marginLeft:"-3%"}}>
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                    documents={docs}
                    initialActiveDocument={docs[0]}
                    prefetchMethod="GET"
                    config={{
                      header: {
                        disableHeader: false,
                        disableFileName: true,
                        retainURLParams: true,
                      },
                    }}

                    
                    className="mob_doc_view"
                    style={{ height: "75vh", }}

                  />
                </div>
             
            </div>
          
          </div>
        </Modal>
      </div>
      )
    }
    </>
  );
}
