
const initialValues = { 
    globalSearch : []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialValues, action) => {
    switch (action.type){
   
        case 'SET_ARR_GLOBAL':
            return{
                ...state,
                globalSearch: action.payload
            };
      
        default:
            return state;
    }
}
