import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
//  url: "http://localhost:8080/auth",
//  realm: "master",
//  clientId: "akunah-react",
 url: "https://accounts.akunah.com/auth",
 realm: "akunah",
 clientId: "akunah-med-web",
});

export default keycloak;
