import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from "../services/UserService";
import { useState, useEffect } from "react";

const Loader = () => {
    document.title = 'Akunah Med || loader';
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const log = UserService.isLoggedIn()
    const [check, setcheck] = useState(false)

    setTimeout(() => {
        if (log === false) {
            navigate('/loader')
        } else {
            navigate("/channels")
        }
    }, 1000)
      

    useEffect(() => {
        const timer = setTimeout(() => {

            // console.log("Hello, World!");
            window.location.pathname = "/"
        }
            , 15000);
        return () => clearTimeout(timer);
    }, []);
    
    useEffect(() => {
        const time1 = setTimeout(() => {
            setcheck(true)
        }
            , 6000);
        return () => clearTimeout(time1);
    }, []);
 
    return (


        <div className='container-fluid bg-light'>
            <div className='row'>
                <div className='col-12 p-0'>
                    <div className="spinner_img">
                        <img src='Assets/image/akunah.gif'></img><br></br>
                        {
                            check===true && (

                        <a  class="outline-btn ms-3 mt-5 text-center" style={{ 'height': '42px', 'lineHeight': '37px' }} href="/">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house me-2" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"></path><path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"></path></svg>
                            Go Back To Home</a>
                            )
                        }
                    </div>


                </div>
            </div>
        </div>

    );
}

export default Loader;