import React, { Suspense } from "react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShow } from "../actions/navAction";
import useTranslation from "./customHooks/translations";
import { setArr } from "../actions/profileAction";
import useAxios from "../axiosinstance";
import en from "../localization/en";
import fr from "../localization/fr";
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from "../localization/spa";
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn'




// import HttpService from "../services/HttpService"
export default React.memo(function SideBar(props) {
  const axiosinstance = useAxios();
  const translation = useTranslation();
  const { show } = useSelector((state) => state.mainReducer.navData);
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const listofchannels = useSelector((state) => state.mainReducer.channelList.channels);
  let { arr } = useSelector((state) => state.mainReducer.catagoryData);
  const [showss, setShows] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setActive] = useState("");
  const [isMenu, setMenu] = useState(true);
  const [role, setRole] = useState(20);
  const [statics, setStatics] = useState("");
  const [postId, setPostId] = useState("");
  const [channelData, setChannelData] = useState([]);
  const [userChannelData, setUserChannelData] = useState([]);

  let akToken = localStorage.getItem("akTokenMed");






  function bigMenu() {
    setMenu(!isMenu);
    dispatch(setShow(isMenu));
  }
  let big;
  let sm;
  if (show === false) {
    big = {
      display: "none",
    };
    sm = {
      display: "block",
    };
  }
  if (show === true) {
    big = {
      display: "block",
    };
    sm = {
      display: "none",
    };
  }

  const handleClick = () => {
    navigate(`/manage-patient-list/${profile[0].id}`);
  };

  const handleClick2 = () => {
    navigate(`/post-list`);
  };

  const handleStatic = () => {
    arr &&
      arr.map((ele) => {
        if (ele.title.replace(/[^\w\s]/gi, "").toLowerCase().replace(/\s/g, "") === "akunahhowtos") {
          setStatics(ele.title);
          setPostId(ele.id);
        } else {
          return;
        }
      });
  };



  const handleRedirect = () => {
    navigate(`/search?cat_id=${btoa(postId)}`);
  };

  const handleShow = () => {
    setShows("active");
  };
  let lang = "";
  useEffect(() => {
    if (profile.length != 0) {
      setRole(profile[0].role_value);

    }

    handleStatic();
  }, [profile, arr, lang]);

  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };



  return (
    <>
      <div className="sticky ">
        {isMenu}
        <div className="sidebar sideee" style={big}>
          <div
            className="hide_side"
            dir={profile[0]?.language === "ara" ? "rtl" : "ltr"}
          >
            <i className=" ms-3" onClick={bigMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-chevron-double-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </i>
          </div>
          <NavLink
            className={`${isActive === "channels" ? "active" : ""}`}
            onClick={() => setActive("channels")}
            to={{ pathname: "/channels" }}
          >
            {/* <i className="me-2">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-house"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                />
              </svg>
            </i>{" "} */}
            <img className="me-2" src="/Assets/image/home.svg" alt="" />
            {getTranslation("channels")}
          </NavLink>

          {/* <NavLink
            className={`${isActive === "Home" ? "active" : ""}`}
            onClick={() => setActive("Home")}
            to={{ pathname: "/home" }}
          >
            <i className="me-2">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-play-btn"
                viewBox="0 0 16 16"
              >
                <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
              </svg>
            </i>{" "}
            {translation.home}
          </NavLink> */}
          {/* <NavLink
            className={`${isActive === "upcoming" ? "active" : ""}`}
            onClick={() => setActive("upcoming")}
            to={{ pathname: "/upcoming" }}
          >
            
            <img className="me-2" src="/Assets/image/Calendar.svg" alt="" />
            {translation.upcoming}
          </NavLink> */}
          <NavLink
            className={`${isActive === "trending" ? "active" : ""}`}
            onClick={() => setActive("trending")}
            to={{ pathname: "/trending" }}
          >
            {/* <i className=" me-2">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="trending-up">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(-90 12 12)"
                      opacity="0"
                    />
                    <path d="M21 7a.78.78 0 0 0 0-.21.64.64 0 0 0-.05-.17 1.1 1.1 0 0 0-.09-.14.75.75 0 0 0-.14-.17l-.12-.07a.69.69 0 0 0-.19-.1h-.2A.7.7 0 0 0 20 6h-5a1 1 0 0 0 0 2h2.83l-4 4.71-4.32-2.57a1 1 0 0 0-1.28.22l-5 6a1 1 0 0 0 .13 1.41A1 1 0 0 0 4 18a1 1 0 0 0 .77-.36l4.45-5.34 4.27 2.56a1 1 0 0 0 1.27-.21L19 9.7V12a1 1 0 0 0 2 0V7z" />
                  </g>
                </g>
              </svg>

            </i>{" "} */}
            <img className="me-2" src="/Assets/image/Frame.svg" alt="" />
            {getTranslation("trending")}
          </NavLink>
          <NavLink
            className={`${isActive === "recently" ? "active" : ""}`}
            onClick={() => setActive("recently")}
            to={{ pathname: "/recently" }}
          >
            {/* <i className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-clock"
                viewBox="0 0 16 16"
              >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
              </svg>
            </i>{" "} */}
            <img className="me-2" src="/Assets/image/Timeline.svg" alt="" />
            <span>{getTranslation("recently_added")}</span>
          </NavLink>
          <NavLink
            className={`${isActive === "live" ? "active" : ""}`}
            onClick={() => setActive("live")}
            to={{ pathname: "/live" }}
          >
            {/* <i className=" me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-camera-video"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                />
              </svg>
            </i>{" "} */}
            <img className="me-2" src="/Assets/image/videoIcon.svg" alt="" />
            {getTranslation("live")}
          </NavLink>

          <NavLink
            className={`${isActive === "Tos" ? "active" : ""}`}
            onClick={() => setActive("Tos")}
            to={{ pathname: `/search?cat_id=${window.btoa(postId)}&ak=true` }}
          >
            {/* <i className=" me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-camera-video"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.98/3 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                />
              </svg>
            </i>{" "} */}
            <img
              className="me-2 bok_img"
              src="/Assets/image/handbook1.png"
              alt=""
            />
            {getTranslation("akunah_how_tos")}
          </NavLink>

          <NavLink
            className={`${isActive === "library" ? "active" : ""}`}
            onClick={() => setActive("library")}
            to={{ pathname: "/library" }}
          >
            <i className="ms-2 me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                fill="currentColor"
                className="bi bi-collection-play"
                viewBox="0 0 16 16"
              >
                <path d="M2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1zm2.765 5.576A.5.5 0 0 0 6 7v5a.5.5 0 0 0 .765.424l4-2.5a.5.5 0 0 0 0-.848l-4-2.5z" />
                <path d="M1.5 14.5A1.5 1.5 0 0 1 0 13V6a1.5 1.5 0 0 1 1.5-1.5h13A1.5 1.5 0 0 1 16 6v7a1.5 1.5 0 0 1-1.5 1.5h-13zm13-1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5h-13A.5.5 0 0 0 1 6v7a.5.5 0 0 0 .5.5h13z" />
              </svg>
            </i>{" "}
            {getTranslation("library")}
          </NavLink>

          {profile[0]?.is_org_member == true ? (
            <NavLink
              className={`${isActive === "live" ? "active" : ""
                } sidebar-upload-video`}
              style={{ border: "1px solid" }}
              onClick={() => setActive("upload-video")}
              to={{ pathname: "/upload-post" }}
            >
              {/* <i className=" me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-camera-video"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                  />
                </svg>
              </i>{" "} */}
              <img
                className="me-2 video-img"
                src="/Assets/image/upload-video1.svg"
                alt=""
              />
              {getTranslation("Upload_a_Video")}
            </NavLink>
          ) : null}

          {/* {role === 100 || role === 70 ? (
            <>
              <NavLink
                className={`${isActive === "live" ? "active" : ""
                  } sidebar-upload-video`}
                onClick={() => setActive("upload-video")}
                to={{ pathname: `/upload-post?event=2` }}
              >
                <img
                  className="me-2 video-img"
                  src="/Assets/image/event.png"
                  alt=""
                />
                {getTranslation('create_event')}
              </NavLink>


              <NavLink
                className={`${isActive === "live" ? "active" : ""
                  } sidebar-upload-video`}
                onClick={() => setActive("upload-video")}
                to={{ pathname: `/upload-post?event=1` }}
              >
                <img
                  className="me-2 video-img"
                  src="/Assets/image/upload-video1.svg"
                  alt=""
                />
                {getTranslation("Upload_a_Video")}
              </NavLink>
            </>
          ) : null} */}

          {role === 40 || role === 0 ? (
            <>
              {
                listofchannels.length > 0 ? (
                  <div class="dropdown drop1">
                    <button
                      class="btn btnnnns btn-lg dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >

                      <span className={profile[0]?.language === "ara" ? "channel-name1" : "channel-name"}>
                        <img
                          className={profile[0]?.language === "ara" ? "me-2 channel-image1" : "me-2 channel-image"}
                          src="/Assets/image/channels.png"
                          alt=""
                        />
                        {getTranslation("Channelsdata")}</span>
                    </button>

                    <ul class="dropdown-menu channl_scroll1 parent-class">
                      {listofchannels &&
                        listofchannels.map((ele) => (
                          <li>
                            <NavLink
                              className={`${isActive === "live" ? "active" : ""}`}
                              onClick={() => setActive("upload-video")}
                              to={{
                                pathname: `/specificchannel/${ele.label}/${ele.value.replace(/\s/g, '-').toLowerCase()}`,
                              }}
                            >
                              {/* <img className="me-2 video-img" src="/Assets/image/upload-video1.svg" alt="" /> */}
                              {ele.value}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                ) : (null)
              }

            </>
          ) : (null)}

          {role === 70 || role === 100 ? (
            <>
              {
                listofchannels.length > 0 ? (
                  <div class="dropdown drop1">
                    <button
                      class="btn btnnnns btn-lg dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >

                      <span className={profile[0]?.language === "ara" ? "channel-name1" : "channel-name"}>
                        <img
                          className={profile[0]?.language === "ara" ? "me-2 channel-image1" : "me-2 channel-image"}
                          src="/Assets/image/channels.png"
                          alt=""
                          width={"25px"}
                        />
                        {getTranslation("Channelsdata")}</span>
                    </button>

                    <ul class="dropdown-menu channl_scroll1 parent-classn">
                      {listofchannels &&
                        listofchannels.map((ele) => (
                          <li>
                            <Link
                              className={`${isActive === "live" ? "active" : ""}`}
                              onClick={() => setActive("upload-video")}
                              to={{
                                // pathname: `/specificchannel/${ele.id}/${ele.value.replace(/\s/g, '-').toLowerCase()}`,
                                // pathname: `/specificchannel/${ele.id}/${(`${ele.name} (${getTranslation(ele.user_type)})`).replace(/\s/g, '-').toLowerCase()}`,
                                pathname: `/specificchannel/${ele?.id}/${(`${ele?.user_type === undefined ? (ele?.name?.replace(/\s/g, '-').toLowerCase()) : (`${ele?.name} (${getTranslation(ele?.user_type)})`).replace(/\s/g, '-').toLowerCase()}`)}`,


                              }}
                            >
                              {/* <img className="me-2 video-img" src="/Assets/image/upload-video1.svg" alt="" /> */}
                              {
                                ele.user_type === undefined ? (
                                  `${ele?.name}`
                                ) : (
                                  `${ele?.name} (${getTranslation(ele?.user_type)})`
                                )
                              }

                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                ) : (null)
              }

            </>
          ) : null}

        </div>

        <div className="sidebar_small" style={sm}>
          <div className="show_side">
            <i className=" ms-3" onClick={bigMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-chevron-double-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </i>
          </div>

          <NavLink
            className={`${isActive === "channels" ? "active" : ""}`}
            onClick={() => setActive("channels")}
            to={{ pathname: "/channels" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={getTranslation("channels")}
          >
            <i className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-house"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                />
              </svg>
            </i>
          </NavLink>

          {/* <NavLink
            className={`${isActive === "Home" ? "active" : ""}`}
            onClick={() => setActive("Home")}
            to={{ pathname: "/home" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="All videos"
          >
            <i className="me-2">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-play-btn"
                viewBox="0 0 16 16"
              >
                <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
              </svg>
            </i>{" "}
          </NavLink> */}
          {/* <NavLink
            className={`${isActive === "upcoming" ? "active" : ""}`}
            onClick={() => setActive("upcoming")}
            to={{ pathname: "/upcoming" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Upcoming"
          >
            <i className=" me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-calendar-check"
                viewBox="0 0 16 16"
              >
                <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
              </svg>
            </i>{" "}
          </NavLink> */}
          <NavLink
            className={`${isActive === "trending" ? "active" : ""}`}
            onClick={() => setActive("trending")}
            to={{ pathname: "/trending" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={getTranslation("trending")}
          >
            <i className=" me-2">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="trending-up">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(-90 12 12)"
                      opacity="0"
                    />
                    <path d="M21 7a.78.78 0 0 0 0-.21.64.64 0 0 0-.05-.17 1.1 1.1 0 0 0-.09-.14.75.75 0 0 0-.14-.17l-.12-.07a.69.69 0 0 0-.19-.1h-.2A.7.7 0 0 0 20 6h-5a1 1 0 0 0 0 2h2.83l-4 4.71-4.32-2.57a1 1 0 0 0-1.28.22l-5 6a1 1 0 0 0 .13 1.41A1 1 0 0 0 4 18a1 1 0 0 0 .77-.36l4.45-5.34 4.27 2.56a1 1 0 0 0 1.27-.21L19 9.7V12a1 1 0 0 0 2 0V7z" />
                  </g>
                </g>
              </svg>
            </i>{" "}
          </NavLink>
          <NavLink
            className={`${isActive === "recently" ? "active" : ""}`}
            onClick={() => setActive("recently")}
            to={{ pathname: "/recently" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={getTranslation("recently_added")}
          >
            <i className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-clock"
                viewBox="0 0 16 16"
              >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
              </svg>
            </i>{" "}
          </NavLink>
          <NavLink
            className={`${isActive === "live" ? "active" : ""}`}
            onClick={() => setActive("live")}
            to={{ pathname: "/live" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={getTranslation("live")}
          >
            <i className=" me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-camera-video"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                />
              </svg>
            </i>{" "}
          </NavLink>
          <NavLink
            className={`${isActive === "Tos" ? "active" : ""}`}
            onClick={() => setActive("Tos")}
            to={{ pathname: `/search?cat_id=${window.btoa(postId)}&ak=true` }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={getTranslation("akunah_how_tos")}
          >
            {/* <i className=" me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-camera-video"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.98/3 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                />
              </svg>
            </i>{" "} */}
            <img
              className="me-1 bok_img"
              src="/Assets/image/handbook1.png"
              alt=""
            />
          </NavLink>
          {profile[0]?.is_org_member == true ? (
            <NavLink
              className={`${isActive === "live" ? "active" : ""}`}
              onClick={() => setActive("upload-video")}
              to={{ pathname: "/upload-post" }}
            >
              {/* <i className=" me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-camera-video"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                  />
                </svg>
              </i>{" "} */}
              <img
                className="me-2 video-img"
                src="/Assets/image/upload-video1.svg"
                alt=""
              />
            </NavLink>
          ) : null}
          <NavLink
            className={`${isActive === "library" ? "active" : ""}`}
            onClick={() => setActive("library")}
            to={{ pathname: "/library" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={getTranslation("library")}
          >
            <i className=" me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                fill="currentColor"
                className="bi bi-collection-play"
                viewBox="0 0 16 16"
              >
                <path d="M2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1zm2.765 5.576A.5.5 0 0 0 6 7v5a.5.5 0 0 0 .765.424l4-2.5a.5.5 0 0 0 0-.848l-4-2.5z" />
                <path d="M1.5 14.5A1.5 1.5 0 0 1 0 13V6a1.5 1.5 0 0 1 1.5-1.5h13A1.5 1.5 0 0 1 16 6v7a1.5 1.5 0 0 1-1.5 1.5h-13zm13-1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5h-13A.5.5 0 0 0 1 6v7a.5.5 0 0 0 .5.5h13z" />
              </svg>
            </i>{" "}
          </NavLink>
          {/* {role === 100 || role === 70 ? (
            <>
              <NavLink
                className={`${isActive === "live" ? "active" : ""}`}
                onClick={() => setActive("upload-video")}
                to={{ pathname: `/upload-post?event=2` }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={getTranslation("create_event")}
              >
                <img
                  className="me-2 video-img"
                  src="/Assets/image/event.png"
                  alt=""
                />
              </NavLink>



              <NavLink
                className={`${isActive === "live" ? "active" : ""}`}
                onClick={() => setActive("upload-video")}
                to={{ pathname: `/upload-post?event=1` }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={getTranslation("upload_video")}
              >
                <img
                  className="me-2 video-img"
                  src="/Assets/image/upload-video1.svg"
                  alt=""
                />
              </NavLink>
            </>
          ) : null} */}


















          {/* //For Channels// */}
          {role === 100 || role === 70 ? (
            <>
              {
                listofchannels.length > 0 ? (
                  <div class="dropdown drop1">
                    <button
                      class="btn dropdown-toggle"
                      style={{ backgroundColor: "#EA702B" }}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        className="me-2 "
                        src="/Assets/image/channels.png"
                        alt=""
                        width={"20px"}
                      />
                    </button>

                    <ul class="dropdown-menu channl_scroll parent-class">
                      {listofchannels &&
                        listofchannels.map((ele) => (
                          <li>
                            <NavLink
                              className={`${isActive === "live" ? "active" : ""}`}
                              onClick={() => setActive("upload-video")}
                              to={{
                                // pathname: `/specificchannel/${ele.id}/${ele.value}`,
                                pathname: `/specificchannel/${ele?.id}/${(`${ele?.user_type === undefined ? (ele?.name?.replace(/\s/g, '-').toLowerCase()) : (`${ele?.name} (${getTranslation(ele?.user_type)})`).replace(/\s/g, '-').toLowerCase()}`)}`,

                              }}
                            >
                              {/* <img className="me-2 video-img" src="/Assets/image/upload-video1.svg" alt="" /> */}
                              {ele.value}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                ) : (null)
              }
            </>
          ) : null}

          {role === 40 || role === 0 ? (
            <>
              {
                listofchannels.length > 0 ? (
                  <div class="dropdown drop1">
                    <button
                      class="btn dropdown-toggle"
                      style={{ backgroundColor: "#EA702B" }}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        className="me-2 "
                        src="/Assets/image/channels.png"
                        alt=""
                        width={"20px"}
                      />
                    </button>

                    <ul class="dropdown-menu channl_scroll parent-class">
                      {listofchannels &&
                        listofchannels.map((ele) => (
                          <li>
                            <NavLink
                              className={`${isActive === "live" ? "active" : ""}`}
                              onClick={() => setActive("upload-video")}
                              to={{
                                // pathname: `/specificchannel/${ele.label}/${ele.value}`,
                                pathname: `/specificchannel/${ele.label}/${ele.value.replace(/\s/g, '-').toLowerCase()}`,

                              }}
                            >
                              {/* <img className="me-2 video-img" src="/Assets/image/upload-video1.svg" alt="" /> */}
                              {ele.value}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                ) : (null)
              }

            </>
          ) : null}













          {/* {role === 100 ? (

            <button className="btn" onClick={() => handleClick2()} style={{ backgroundColor: "#EA702B", margin: '5px', width: '66px' }}
              data-bs-toggle="dropdown tooltip"
              aria-expanded="false"
              data-bs-placement="top"
              title={getTranslation("administration")}

            >

              <img
                className="me-5"
                src="/Assets/image/administration.png"
                alt=""

                width={"20px"}
              />
            </button>

          ) : null}

          {role === 40 ? (

            <button className="btn" onClick={() => handleClick()} style={{ backgroundColor: "#EA702B", margin: '5px', width: '66px' }}
            data-bs-toggle="dropdown tooltip"
            aria-expanded="false"
            data-bs-placement="top"
            title={getTranslation("administration")}
            >

              <img
                className="me-5"
                src="/Assets/image/administration.png"
                alt=""

                width={"20px"}
              />
            </button>


          ) : null}
          {role === 70 ? (
            <button className="btn" onClick={() => handleClick2()} style={{ backgroundColor: "#EA702B", margin: '5px', width: '66px' }}
            data-bs-toggle="dropdown tooltip"
            aria-expanded="false"
            data-bs-placement="top"
            title={getTranslation("administration")}
            >

              <img
                className="me-5"
                src="/Assets/image/administration.png"
                alt=""

                width={"20px"}
              />
            </button>
            // <NavLink
            //   className={`${isActive === "live" ? "active" : ""}`}
            //   onClick={() => setActive("upload-video")}
            //   to={{ pathname: `/post-list` }}
            //   data-bs-toggle="tooltip"
            //   data-bs-placement="top"
            //   title="Adminstration"
            // >

            //   <img
            //     className="me-2"
            //     src="/Assets/image/administration.png"
            //     alt=""
            //   />
            // </NavLink>
          ) : null} */}
        </div>
        <div
          className=""
          style={big}
          dir={profile[0]?.language === "ara" ? "ltr" : "ltr"}
        >
          <div
            className={profile[0]?.language === "ara" ? "versionss" : "version"}
          >
            <p className="text-start">
              <span className="fw-bold" style={{ fontSize: "10px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  fill="currentColor"
                  className="bi bi-person-circle me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
                {getTranslation("login_by")} :
                {profile &&
                  profile[0]?.userRoles.map((ele) => (
                    <span>
                      {""}
                      {""} {getTranslation(ele)}
                    </span>
                  ))}
                {/* Login by :- {role >= 100 && <span>Akunah</span>}
                {role === 70 && <span>Organization </span>}
                {role === 40 && <span>HCP</span>}
                {role < 39 && <span>Patients</span>} */}
              </span>
              <div className="pb-1"></div>
              <br />
              {translation.App_Version} : {process.env.REACT_APP_VIRSION}
            </p>
          </div>
        </div>
      </div>
    </>
  );
});
