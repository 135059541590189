const initialValues = { 
    profile : []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialValues, action) => {
    switch (action.type){
   
        case 'SET_ARR':
            return{
                profile: [action.payload]
            };
      
        default:
            return state;
    }
}
