import React from 'react'

export default function SsoLogin() {

    const selectClick = (e) => {
          alert(e.target.value)

    }

    return (
        <>
            {/* <div className="container-fluid">
                <div className="row ">
                    <img className="img-fluid keclock_img d-md-block d-none p-0" src="Assets/image/bgsso.jpg " />
                </div>
            </div> */}
            <main className="sso_login">
                {/* {} */}

                {/* {} */}

                <section className="" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-6 col-12">
                                <div className="gap_">
                                    <div className=" text-center bg-white login_bx shadow p-3 mb-5  rounded  ">
                                        <div className="m-5">
                                            <img
                                                width={280}
                                                className="img-fluid"
                                                src="Assets/image/Akunah_Secondary.png"
                                                alt="Akunah"
                                            />
                                        </div>
                                        <div >
                                            <p className="">

                                                <label className="form-check-label" htmlFor="check2">
                                                    Do you want to login in

                                                </label>
                                                <select className="form-select mt-4 m-auto w-auto" onChange={(e)=>selectClick(e)}>
                                                    <option selected="">Select</option>
                                                    <option  value='1'>Akunah</option>
                                                    <option  value='2'>Organization</option>
                                                    <option  value='3'>Healthcare professional (HCP) </option>
                                                    <option  value='4'>Patient</option>

                                                </select>

                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            id="click_spinner"
                                            className="login-btn  btn  btn-lg m-5"
                                            style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}

                                        >

                                            Continue 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}
