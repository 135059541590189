const initialValues = { 
    arr : []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialValues, action) => {
    switch (action.type){
   
        case 'SET_ARR_TRE':
            return{
                arr: action.payload
            };
      
        default:
            return state;
    }
}
