import React,{useEffect} from "react";
import Header from "../../Component/Header";
import Sidebar from "../../Component/Sidebar";
import Tab from "../../Component/admin/Tab";
import Footer from "../../Component/Footer";
import { useSelector } from "react-redux";
import appStyle from "../../config/appStyle";
import UserService from "../../services/UserService";
import SubscriptionList from "../../Component/admin/SubscriptionList";
import { useNavigate } from "react-router-dom";
const Subscription = () => {
    // const navigate = useNavigate();
    // const check = function loginCheck() {
    //     return UserService.isLoggedIn()
    // }
    // const abc = check()
    
    // useEffect(() => {
    //     if (!abc) {
    //       navigate("/loader");
    //     }
    // }, [])
  const { show } = useSelector((state) => state.mainReducer.navData);
  document.title = "Akunah Med || Subscription";

  return (
    <>
      <Header />
      <main className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className={show ? appStyle.navClass1 : appStyle.navClass2}>
              <Sidebar></Sidebar>
            </div>
            <div className={show ? appStyle.navClass3 : appStyle.navClass4}>
              <Tab />
              
              <SubscriptionList></SubscriptionList>
              <Footer></Footer>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Subscription;
