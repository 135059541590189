
// eslint-disable-next-line import/no-anonymous-default-export
export default {


  home: "所有视频",
  upcoming: "即将到来的",
  trending: "热门",
  recently_added: "最近添加",
  live: "现场直播",
  library: "我的最爱",
  view_by_catagories: "通过专科查看",
  loading: "加载...",
  filter: "过滤",
  profile: "简介",
  profile_photo: "简介照片",
  first_name: "名",
  last_name: "姓",
  update_profile: "更新照片",
  Update_Profiless: "更新简介",



  edit_profile: "编辑简介",
  date_of_birth: "出生日期",
  address: "地址",
  registered_email: "登记邮箱",
  registered_phone: "登记电话",
  subscription_key: "订阅密钥",
  renew_key: "更新密钥",
  start_date: "开始日期",
  end_date: "结束日期",
  no_more_data: "没有更多数据",
  search: "搜索",
  logout: "登出 ",
  view_profile: "查看简介",
  notifications: "通知",
  Get_Premium: "获取升级版",
  App_Version: "应用版本",
  Get_3_months_of_premium_for_free: "",
  Click_below_to_sign_up_for_premium_Akunah_MEd:
    "点击以下注册升级版Akunah Med",
  request_to_join: "作为病人加入HCP",
  request_to_join_p: "作为病人加入机构",
  request_to_join_h: "加入机构",
  lives: "现场直播",
  plan: "所有计划",
  channels: "主页",


  akunahPublicP: "我的健康视频",
  OrgPublicPatient: "我的供应方健康视频",
  HCPPublicPatient: "我的医生视频",
  HCPPrivatePatient: "我的专用视频",
  akunahExc: "Akunah全球HCP",
  akunahPublic: "Akunah全球公用",
  OrgPublic: "我的机构共用",
  myOrgPublic: "我的公用",
  myOrgExc: "我的专用",
  OrgExc: "我的机构HCP",
  mypublic: "我的公用",
  myprivate: "我的专用",
  // myhcppublic: "my hcp public",
  // myhcpprivate: "my hcp Exclusive",



  akunah_how_tos: "Akunah操作指南",
  Upload_a_Video: "上传视频",
  administration: "管理",
  view_all: "查看所有",
  Channelsdata: "频道",
  login_by: "登陆",
  upload_video: "上传视频",
  type_of_content: "内容类型",
  title: "名称",
  thumb_imag1: "缩略图 （图片大小 720x405）",
  thumb_imag2: "缩略图 （图片大小 480x270）",
  video: "视频",
  link: "在线收看链接",
  tags: "标签",
  organization: "机构",
  description: "描述",
  publish: "发表",
  submit: "提交",

  manage_my_videos: "管理我的视频",
  manage_my_organizations: " 管理我的机构",
  manage_my_hcps: "管理我的HCP",
  manage_org_patient: "管理机构病人",
  manage_my_patients: "管理我的病人",
  manage_tags: "管理标签",
  manage_my_plans: "管理我的计划",








  video_list: "视频列表",
  upload_videos: "上传视频",
  limit: "限制",
  type: "类型",
  posted_by: "发布通过",
  organization: "机构",
  s_no: "S 号",
  video_id: "视频ID",
  duration: "持续时间",
  video_size: "视频大小",
  actions: "操作",
  add_a_organization: "添加一个机构",
  org_name: "机构名",
  size: "大小",
  watch_time: "观看时间",
  sort: "分类",
  status: "状态",
  organization_Id: "机构ID",
  total_post_size: "全部帖子大小",
  total_watch_time: "全部观看时间",
  add_tags: "添加标签",
  order: "订购",
  add: "添加",
  edit: "编辑",
  tags_list: "标签列表",
  add_plan: "添加计划",
  plan_name: "计划名",
  number_of_hcp: "HCP数量",
  number_of_patient: "病人数量",
  Limit: "大小限制（GB）",
  plan_list: "播放列表",
  noofhcp1: "HCP数量",
  nopatient1: "病人数量",
  save: "保存",
  add_channels: "添加频道",
  name: "名",
  role: "职位",
  all: "全部",
  users: "用户",
  user_type: "用户类型",
  list_of_channels: "频道列表",
  event_Link: "活动链接",
  visible_till: "* 可见直到",
  tags_channels: "标签频道",
  manage_hcps: "管理HCP",
  pending_hcp_req: "待处理的HCP请求",
  invite_hcp: "邀请HCP",
  email: "电邮",
  delete_hcp: "您确定您想要删除这位医疗专家吗？",
  no: "不是",
  yes: "是的",
  add_email: "添加电邮",
  view_plans: "查看计划",
  activate_date: "激活日期",
  expiry_date: "到期日期",
  hcp: "HCP",
  patient: "病人",
  total_hcp: "全部HCP",
  total_patient: "全部病人",
  invite_admin: "邀请管理员",
  autoid: "自动ID",
  delete_admin: "您确定您想要作为管理员删除吗？",
  admin_user_email: "管理员用户电邮",
  view_admins: "查看管理",
  view_HCPs: "查看HCP",
  patient_list: "病人列表",
  pending_patient_req: "待处理的病人请求",
  invite_patient: "邀请病人",
  patient_name: "病人名",
  delete_patient: "您确定您想要删除这位病人吗？",
  no_videos_found: "没有找到视频",
  not_found_trending: "没有找到热门视频",
  upload_video_popup: "您需要创建您自己的机构来上传视频",
  create_org: "创建机构",
  list_of_user_profiles: "用户简介列表",
  all_speciality: "所有专科",
  channel_delete: "您确定您想要删除此项吗？",
  req_for_create_org: "创建机构的请求",
  cancel: "取消",
  send: "发送",
  akunahhandbook: "Akunah手册",
  help: "Akunah帮助文档",
  subject: "主题",
  message: "信息",
  no_live_video: "没有找到直播视频",
  hcp_name: "HCP名",
  expiry_days: "到期日",
  send_req: "发送请求",
  search_by_speciality: "通过专科搜索",
  no_request_data: "没有请求的数据",
  no_data_found: "没有找到数据",
  approve_patient_list: "批准病人列表",
  create_event: "创建活动",
  live_event: "直播活动",
  switch_profile: "调换简介",
  event_link: "活动链接",
  search_by_patient_email_id: "通过病人和电邮ID搜索",
  searchbyb_title: "通过名称搜索",
  search_by_org_name: "通过机构名搜索",
  enter_email_address: "输入电邮地址",
  no_of_days: "天数",

  //12/7/2023//


  acceopt: "接受",
  reject: "拒绝",
  approved: "批准",
  select: "选择",
  active: "激活",
  deactive: "吊销",
  select_plan: "选择计划",
  views: "观看",
  view_patients: "查看病人",
  valid_email: "请输入有效电邮！！！",
  is_already_created: "已经被创建",
  req_to_hcps: "向HCP提交请求",
  approve: "批准",
  upload: "上传",
  search_by_name: "通过名字搜索",
  global_channel: "全球频道",
  all_users: "全部用户",
  patients: "病人",
  HCPs: "医疗专家",
  selected_users: "选择用户",
  post_updated: "帖子已更新",
  video_deleted: "视频成功被删除",
  ascending: "上升",
  descending: "下降",
  all_drop: "全部",
  akunah: "Akunah",
  channel_added: "频道已添加",
  plan_status: "计划状态已更改",
  tag_added: "标签已添加",
  tag_updated: "标签已更新",
  hcp_deleted: "HCP成功被删除",
  pending: "待处理的",
  patient_deleted: "病人成功被删除",
  is_new_patient: "新病人已添加了吗",
  new_plan_added: "新计划已添加",
  org_added: "机构已添加",
  date: "日期",
  missing_subj: "主题缺失",
  missing_message: "信息缺失",
  select_user: "选择用户",
  field_req: "此项目是必填的",
  select_org: "选择机构",
  is_already_org: "已经在该机构中存在",
  org_error: "该机构已被创建。请加入机构而不是创建新机构。",
  email_sent: "电邮成功发送",
  content_body: "内容主体缺失",
  not_sent_email: "电邮未发送",
  no_user_found: "没有找到用户",
  profile_update: "简介更新",
  req_sent: "请求已发送",
  req_to_publish: "请求公开",
  no_req: "无请求",
  akunah_global_public: "Akunah全球公开",
  akunah_global_patient: "Akunah全球病人",
  enter_org_name: "请输入机构名", //Not Done
  select_a_plan: "请选择一项计划",//Not Done
  Plan: "计划",
  admin_deleted: "已删除的管理员",//Not Done
  email_added: "已添加的电邮",//Not Done
  email_not_added: "未添加的电邮",//Not Done
  plan_not_added: "未添加的计划",//Not Added
  hcp_not_delete: "未删除的HCP",//Not Done
  hcp_approved: "成功批准的HCP",//Not Done
  hcp_reject: "已拒绝的HCP",//Not Done
  channel_updated: "频道已更新",//Not Done
  channel_deleted: "频道成功删除",//Not Done
  limit_error: "大小限制应该大于0",//Not Done
  days: "天数",//Not Done
  gb: "GB",//Not Done
  plan_created: "计划已创建",//Not Done
  rejected: "拒绝",
  expired: "已到期",
  search_by_hcp_name: "通过HCP名搜索",


  name_already_present: "此名字已存在",//Not Done
  already_req_hcp: "您已经向该HCP提交请求",//Not Done
  already_req_org: "您已经向该机构提交请求",//Not Done



  video_not_delete: "视频未删除",
  video_updated_success: "视频更新成功",
  Doctor: "医生",
  Patient: "病人 ",
  favorite: "最喜欢的 ",
  unfavourite: "不喜欢的",



  Admin: "行政",
  org_admin: "组织管理员",
  video_upload_success: "视频上传成功",
  live_event_create_success: "直播活动创建成功",



  privacy: "您的隐私 点击“接受”所有 cookie，即表示您同意 akunah Med 可以在您的设备上存储 cookie 并根据我们的规定披露信息",
  cookie: "cookie 政策",

  my_profiles: "我的个人资料",

  not_found_recent:"没有找到最近的视频"



};
