import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import instance from '../axiosinstance';
import { useEffect, useState } from "react";
import HttpService from "../services/HttpService";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import useTranslation from "./customHooks/translations";
import { useSelector, useDispatch } from "react-redux";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { setCatagoryArr } from "../actions/catagoryAction";
import useAxios from "../axiosinstance";
import en from '../localization/en'
import fr from '../localization/fr'
import ger from "../localization/ger";
import ita from "../localization/ita";
import ara from "../localization/ara";
import spa from '../localization/spa'
import tha from '../localization/tha';
import kor from '../localization/kor';
import chn from '../localization/chn'


// import axios from 'axios';
export default React.memo(function Categories(props) {
  const navigate = useNavigate();
  const axiosinstance = useAxios()
  const dispatch = useDispatch()
  const translation = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const cat_id = queryParams.get("cat_id");
  const ind = queryParams.get("index");
  const { arr } = useSelector((state) => state.mainReducer.catagoryData);
  // const [show, setShow] = useState(false);
  // const [post, setPost] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [indexId, setIndexId] = useState(ind);
  const { profile } = useSelector((state) => state.mainReducer.profileData);
  const [listOfCat, setListOfcat] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState({});

  // const [addClass, setaddClass] = useState('');

  const DataId = (id) => {
    props.onSubmit(id);
    // setaddClass(id)
    navigate(`/search?cat_id=${btoa(id)}&index=${indexId}`);
    // console.log(id)
  };
  const getResult1 = () => {

    if (arr.length < 1) {
      axiosinstance.current.get("categories?status=1").then((response) => {
        if (response) {
          dispatch(setCatagoryArr(response.data));
        }
      });
    }
  }




  useEffect(() => {
    getResult1()
  }, [])

  const options = {
    items: 3,
    nav: true,
    navText: [
      '<div class="nextsss"><span><</span></div>',
      '<div class="nextsss"><span >></span></div>',
    ], // custom next and previous button text
  };

  const handleChanged = (e) => {
    setIndexId(e.item.index);
  };
  const [show1, setShow1] = useState(false);
  const Showsearch = () => {
    if (show1 == false) {
      setShow1(true);
    } else {
      setShow1(false);
    }
  };
  function handleChange(e) {
    setIndexId(e.key);
    navigate(`/search?cat_id=${btoa(e.value.id)}&index=${e.key}&tagdata=true`);
    setSelectedOption(e.value.id);
    setShow1(false);
  }

  // const handleChange = (event, value) => {
  //   if (value) {
  //     console.log('Selected Title:', value.id, index);
  //     setIndexId(value.key);
  //     navigate(`/search?cat_id=${btoa(value.id)}&index=${value.key}`);
  //     setSelectedOption(value.id);
  //     setShow1(false);
  //     console.log('Selected Year:', value.year);
  //   }
  // };
  // const handleChange = (event, value) => {
  //   if (value) {
  //     const index = arr.findIndex((item) => item?.title === value?.title);
  //     setIndexId(index)
  //     navigate(`/search?cat_id=${btoa(value.id)}&index=${index}`);
  //     setSelectedOption(value.id);
  //     setShow1(false)
  //   }
  // };


  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      zIndex: 2,
    }),
    // control: (styles) => ({
    //   ...styles,
    //   cursor: 'pointer',
    // }),
  };





  const getTranslation = (key) => {
    const translations =
      profile[0]?.language === "en"
        ? en
        : profile[0]?.language === "ger"
          ? ger
          : profile[0]?.language === "ita"
            ? ita
            : profile[0]?.language === "ara"
              ? ara
              : profile[0]?.language === "spa"
                ? spa
                : profile[0]?.language === "tha" ? tha
                  : profile[0]?.language === "kor" ? kor
                    : profile[0]?.language === "ch" ? chn : fr
    return translations[key] || key;
  };





  const handleOnSearchorg = (string, results) => {
    setInputValue(string)
    if (string.length === 2) {
      axiosinstance.current.get(`categories?status=1&title=${string}`).then((res) => {
        setListOfcat(res.data)
      })
    }
  };


  const handleOnHoverorg = (result) => {

  };

  const handleOnSelectorg = (item) => {
    // console.log(item);
    if (item) {
      setSelected(item);
      navigate(`/search?cat_id=${btoa(item.id)}&index=${item.order - 1}`);
      setInputValue("")
    }
  };

  const handleOnFocusorg = () => {

  };

  const handleOnClear = () => {
    setSelected({})
  };


  return (
    <>
      <div className="categories">
        <div className="upcoming-head">
          <h4>{getTranslation("view_by_catagories")}</h4>
        </div>
        <div className="categories-title sidebarrrr upcoming_caraousel">
          <OwlCarousel
            {...options}
            className="owl-theme fs-1"
            loop={false}
            onChanged={handleChanged}
            startPosition={indexId}
            margin={5}
            dots={false}
            nav={true}
            autoplayTimeout={5000}
            autoplay={false}
            // stagePadding={20}
            // mergeFit={true}
            autoWidth={true}
            responsive={{
              0: { items: 1 },
              360: { items: 2 },
              400: { items: 2 },
              599: { items: 3 },
              1024: { items: 4 },
              1300: { items: 5 },
              1600: { items: 6 },
            }}
          >
            {/* 
            {!show && (
              <div>
                {translation.loading}
              </div>
            )}
            {show && ( */}
            {arr.map((post) => (
              <div className="item" key={post.id}>
                <div
                  className="categories-list "
                  onClick={() => DataId(post.id)}
                >
                  <h6
                    className={
                      Number(window.atob(cat_id)) === post.id
                        ? "category_active"
                        : ""
                    }
                  >
                    {" "}
                    {post.title}{" "}
                  </h6>
                </div>
              </div>
            ))}
            {/* )} */}
          </OwlCarousel>
        </div>
      </div>

      <div className="clearfix">
        <div className="width_top3  d-none d-md-block d-lg-block">
          <div className="dropdown spe">
            <button
              className="btn admin_bt dropdown-toggle  mt-0 specilaity_btn spe_buuton all-spe-mob"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => Showsearch()}
            >
              {getTranslation("all_speciality")}
            </button>
            {show1 && (
              <Select
                placeholder={getTranslation('search_by_speciality')}
                defaultValue={selectedOption}
                onChange={handleChange}
                // keepOpen={true}
                // dropdownHandle={true}
                styles={customStyles}
                menuIsOpen={true}
                options={arr.map((guest, index) => {
                  return {
                    label: guest.title,
                    value: guest,
                    key: index,
                  };
                })}
              />

              // <Stack spacing={2} sx={{ width: 300 }}>

              //   <Autocomplete
              //     freeSolo
              //     id="free-solo-2-demo"
              //     disableClearable
              //     options={arr}
              //     getOptionLabel={(option) => option.title}
              //     onChange={handleChange}
              //     renderInput={(params) => (
              //       <TextField
              //         {...params}
              //         placeholder={getTranslation('search_by_speciality')}
              //         InputProps={{
              //           ...params.InputProps,
              //           type: 'search',

              //         }}
              //       />
              //     )}
              //   />
              // </Stack>

            )}
          </div>
        </div>
      </div>
      <div className="width_top3 bg-danger d-block d-sm-none " style={{ borderRadius: "5px" }}>

        <ReactSearchAutocomplete
          items={listOfCat}
          fuseOptions={{
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 3,
            keys: ["title", "id"],
          }} // Search on both fields
          resultStringKeyName="title" // String to display in the results
          onSearch={handleOnSearchorg}
          onHover={handleOnHoverorg}
          inputSearchString={selected.title}
          onSelect={handleOnSelectorg}
          placeholder={getTranslation('search_by_speciality')}
          onFocus={handleOnFocusorg}
          onClear={handleOnClear}
          showIcon={false}
          styling={{
            height: "34px",
            border: "1px solid #c4c9cd;",
            borderRadius: "4px",
            backgroundColor: "white",
            boxShadow: "none",
            hoverBackgroundColor: "#EA702B",
            color: "black",
            fontSize: "12px",
            fontFamily: "Courier",
            iconColor: "black",
            lineColor: "#EA702B",
            placeholderColor: "black",
            clearIconMargin: "3px 8px 0 0",
            zIndex: 1,
          }}
        />


      </div>
    </>
  );
});
